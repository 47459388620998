import * as THREE from "three";
import { AbstractPlayer, ICrowdPlayer } from "./AbstractPlayer";
import { IKeyFrame, IStateChangeKeyFrame, KeyFrameTool } from "./KeyFrame";

export type ColorStateMap = { [state: number]: THREE.Color };
export type Road = { points: PointList; id: string };

interface ISuperPlayerMessage {
  shouldUpdate?: boolean;
}

type PointList = THREE.Vector3[];

export interface IRoadMapReader {
  next(): Promise<Road | null>;
}

class RoadInstanceBuilder {
  id: string;
  points: PointList;
  length: number;
  indexes: number[];
  matrices: THREE.Matrix4[];

  constructor(
    road: Road,
    width: number,
    index: number,
    meshPromise: Promise<THREE.InstancedMesh>
  ) {
    this.id = road.id;
    this.points = road.points;
    this.length = Math.max(this.points.length - 1, 0);
    this.matrices = this.constructMatrices(width, this.points);

    // JS version of Python Range(index, index + length)
    this.indexes = Array.from(Array(this.length).keys()).map((i) => i + index);
    meshPromise.then((mesh) => {
      this.indexes.forEach((index, i) => {
        mesh.setMatrixAt(index, this.matrices[i]);
      });
    });
  }

  constructMatrices(width: number, points: PointList): THREE.Matrix4[] {
    const matrices: THREE.Matrix4[] = [];

    points.slice(1).forEach((pe, i) => {
      const st = points[i];
      const v = pe.clone().sub(st);
      const length = v.length();
      const theta = new THREE.Vector2(v.x, v.y).angle();
      v.divideScalar(2).add(st);
      const matrix = new THREE.Matrix4()
        .makeTranslation(v.x, v.y, v.z)
        .multiply(new THREE.Matrix4().makeRotationZ(theta))
        .scale(new THREE.Vector3(length, width, 1));
      matrices.push(matrix);
    });

    return matrices;
  }
}

interface IRoadStateSetter {
  set(state: number): void;
  clear(): void;
}

class RoadPlayer extends AbstractPlayer {
  id: string;
  lastState: number | undefined;
  stateSetter: IRoadStateSetter;

  constructor(roadId: string, stateSetter: IRoadStateSetter) {
    super();
    this.id = roadId;
    this.stateSetter = stateSetter;
  }

  play(timing: number): ISuperPlayerMessage {
    this.maybeRollback(timing);

    const currentFrame = this.getCurrentKeyFrame(timing);
    if (currentFrame) {
      if (KeyFrameTool.isMovementFrame(currentFrame)) {
        const frame = currentFrame as IStateChangeKeyFrame;
        if (frame.state !== this.lastState) {
          this.stateSetter.set(frame.state);
          return { shouldUpdate: true };
        } else {
          // 状态相同，无需更新
          return {};
        }
      } else {
        console.error("未能识别帧类型", currentFrame);
        throw Error("未能识别帧类型");
      }
    } else {
      return {};
    }
  }

  clear() {
    super.clear();
    this.stateSetter.clear();
  }
}

class RoadStateSetter implements IRoadStateSetter {
  private mesh?: THREE.InstancedMesh;
  private meshPromise: Promise<THREE.InstancedMesh>;
  private readonly indexes: number[];
  private readonly colorStateMap: ColorStateMap;
  private readonly defaultState?: number;

  constructor(
    indexes: number[],
    colorStateMap: ColorStateMap,
    meshPromise: Promise<THREE.InstancedMesh>,
    defaultState?: number
  ) {
    this.indexes = indexes;
    this.colorStateMap = colorStateMap;
    this.meshPromise = meshPromise;
    if (defaultState !== undefined) {
      this.defaultState = defaultState;
      this.meshPromise.then((mesh) => {
        this.realSet(defaultState, mesh);
        mesh.instanceColor!.needsUpdate = true;
      });
    }
  }

  realSet(state: number, mesh: THREE.InstancedMesh) {
    if (this.colorStateMap[state])
      this.indexes.forEach((index) =>
        mesh.setColorAt(index, this.colorStateMap[state])
      );
    else console.warn(`路况: ${state} 无效：不在colorStateMap的Key中`);
  }

  set(state: number) {
    if (this.mesh) {
      this.realSet(state, this.mesh);
    } else {
      this.meshPromise.then((mesh) => {
        this.mesh = mesh;
        this.realSet(state, mesh);
      });
    }
  }

  clear() {
    if (this.mesh) {
      if (this.defaultState) this.realSet(this.defaultState, this.mesh);
    }
  }
}

export interface IRoadConfig {
  colorStateMap: ColorStateMap;
  roadMapReader: IRoadMapReader;
  width: number;
  basicGeometry: THREE.BufferGeometry;
  material: THREE.Material;
  defaultState?: number;
}

export class AllRoadPlayer implements ICrowdPlayer {
  private readonly superScene: THREE.Scene;
  roads: {
    [roadId: string]: { player: RoadPlayer; road: RoadInstanceBuilder };
  } = {};
  private roadConfig: IRoadConfig;

  isInitialized: boolean = false;
  private initPromiseResolver?: () => void;
  initPromise = new Promise<void>(
    (resolve) => (this.initPromiseResolver = resolve)
  );

  private mesh?: THREE.InstancedMesh;
  minTimingInterval: number;
  private lastTiming = -Infinity;

  constructor(
    superScene: THREE.Scene,
    roadConfig: IRoadConfig,
    minTimingInterval = 1
  ) {
    this.superScene = superScene;
    this.roadConfig = roadConfig;
    this.minTimingInterval = minTimingInterval;
  }

  async init() {
    if (this.isInitialized) {
      console.warn("Duplicate call of init takes no effect.");
      return;
    }
    let index: number = 0;
    let meshResolver: ((mesh: THREE.InstancedMesh) => void) | undefined;
    const meshPromise = new Promise<THREE.InstancedMesh>((resolve) => {
      meshResolver = resolve;
    });
    while (true) {
      const road = await this.roadConfig.roadMapReader.next(); // 逐条读路网
      if (road) {
        const roadInstance = new RoadInstanceBuilder(
          road,
          this.roadConfig.width,
          index,
          meshPromise
        );
        const indexes = roadInstance.indexes;
        const roadPlayer = new RoadPlayer(
          road.id,
          new RoadStateSetter(
            indexes,
            this.roadConfig.colorStateMap,
            meshPromise,
            this.roadConfig.defaultState
          )
        );
        this.roads[road.id] = { player: roadPlayer, road: roadInstance };
        index += roadInstance.length;
      } else break;
    }

    this.mesh = new THREE.InstancedMesh(
      this.roadConfig.basicGeometry,
      this.roadConfig.material,
      index
    );
    if (meshResolver) meshResolver(this.mesh);
    this.isInitialized = true;
    this.initPromiseResolver!();
    this.superScene.add(this.mesh);
  }

  public appendKeyFrames(keyFrames: { [roadId: string]: IKeyFrame }) {
    if (!this.isInitialized) {
      throw Error("Call init first before append key frames.");
    }
    Object.entries(keyFrames).forEach(([roadId, keyFrame]) => {
      if (this.roads.hasOwnProperty(roadId)) {
        this.roads[roadId].player.enqueueKeyFrame(keyFrame);
      }
    });
  }

  play(timing: number) {
    if (!this.isInitialized) {
      throw Error(
        "Make sure init is called and finished before calling play()"
      );
    }
    if (timing - this.lastTiming >= this.minTimingInterval) {
      this.lastTiming = timing;
      let shouldUpdate = false;
      Object.values(this.roads).forEach(({ player }) => {
        const message = player.play(timing);
        if (message.shouldUpdate) {
          shouldUpdate = true;
        }
      });

      if (shouldUpdate && this.mesh && this.mesh.instanceColor) {
        this.mesh.instanceColor.needsUpdate = true;
      }
    }
  }

  clear() {
    this.lastTiming = -Infinity;
    if (!this.isInitialized) {
      throw Error(
        "Make sure init is called and finished before calling clear()"
      );
    }
    Object.values(this.roads).forEach(({ player }) => {
      player.clear();
    });
  }
}
