import {
  IFunctionalKeyFrame,
  IKeyFrame,
  KeyFrameTool,
  KeyFrameType,
} from "./KeyFrame";
import { IDeque, Deque } from "../utils/deque";

export interface IPlayer {
  play(timing: number): any;
  clear(): void;
}

export interface ICrowdPlayer extends IPlayer {
  appendKeyFrames(keyFrames: { [id: string]: IKeyFrame }): any;
}

export class PlayBackCacheUnderflowError extends Error {}

export class AbstractPlayer implements IPlayer {
  keyFrames: IDeque<IKeyFrame> = new Deque<IKeyFrame>();
  startAt?: number;
  private readonly playedKeyFrameCacheSize = 50;
  playedKeyFrameCache: IDeque<IKeyFrame> = new Deque<IKeyFrame>(
    this.playedKeyFrameCacheSize,
    true
  );
  latestEnqueuedKeyFrame: IKeyFrame | undefined;
  currentKeyFrame: IKeyFrame | undefined;

  play(timing: number): any {
    throw Error("play not implemented.");
  }

  clear() {
    this.currentKeyFrame = undefined;
    this.latestEnqueuedKeyFrame = undefined;
    this.keyFrames.clear();
    this.playedKeyFrameCache.clear();
    // this.startAt = undefined;
  }

  prependKeyFrame(keyFrame: IKeyFrame) {
    if (this.currentKeyFrame) {
      this.keyFrames.enqueueFront(this.currentKeyFrame);
      this.currentKeyFrame = undefined;
    }
    const earliestKeyFrame = this.keyFrames.peekFirst();
    if (earliestKeyFrame) {
      if (
        KeyFrameTool.isKeyFrameAfterKeyFrame(
          keyFrame,
          earliestKeyFrame,
          true,
          false
        )
      ) {
        // 新关键帧比下一关键帧位置靠后，报错
      } else if (
        KeyFrameTool.isKeyFrameOverlapped(earliestKeyFrame, keyFrame)
      ) {
        // 新关键帧与下一关键帧有重叠部分，修剪本关键帧

        KeyFrameTool.setLengthByEndTiming(keyFrame, earliestKeyFrame.timing);
      } else if (
        KeyFrameTool.isKeyFrameBeforeKeyFrame(
          keyFrame,
          earliestKeyFrame,
          false,
          true
        )
      ) {
        // 新关键帧与上一关键帧中间有空隙，插入空白关键帧
        // console.warn("KeyFrame not continuous. Blank frame automatically added.");
        // const endTime = KeyFrameTool.endTiming(keyFrame);
        // const emptyFrame: IFunctionalKeyFrame = {
        //     frameType: KeyFrameType.functional,
        //     timing: endTime,
        //     length: earliestKeyFrame.timing - endTime,
        //     hide: true
        // }
        // this.keyFrames.enqueueFront(emptyFrame);
      }
      this.keyFrames.enqueueFront(keyFrame);
    } else {
      this.latestEnqueuedKeyFrame = undefined;
      this.enqueueKeyFrame(keyFrame);
    }
  }

  insertKeyFrame(keyFrame: IKeyFrame) {}

  enqueueKeyFrame(keyFrame: IKeyFrame) {
    if (this.latestEnqueuedKeyFrame) {
      if (
        KeyFrameTool.isKeyFrameBeforeKeyFrame(
          keyFrame,
          this.latestEnqueuedKeyFrame,
          true,
          false
        )
      ) {
        // 新关键帧比上一关键帧位置靠前，报错
        this.keyFrames.clear();
      } else if (
        KeyFrameTool.isKeyFrameOverlapped(this.latestEnqueuedKeyFrame, keyFrame)
      ) {
        // 新关键帧与上一关键帧有重叠部分，修剪上一关键帧

        KeyFrameTool.setLengthByEndTiming(
          this.latestEnqueuedKeyFrame,
          keyFrame.timing
        );
      } else if (
        KeyFrameTool.isKeyFrameAfterKeyFrame(
          keyFrame,
          this.latestEnqueuedKeyFrame,
          false,
          true
        )
      ) {
        // 新关键帧与上一关键帧中间有空隙，插入空白关键帧
        // console.warn(
        //   "KeyFrame not continuous. Blank frame automatically added."
        // );
        // const endTime = KeyFrameTool.endTiming(this.latestEnqueuedKeyFrame);
        // const emptyFrame: IFunctionalKeyFrame = {
        //     frameType: KeyFrameType.functional,
        //     timing: endTime,
        //     length: keyFrame.timing - endTime,
        //     hide: true
        // }
        // this.keyFrames.enqueueEnd(emptyFrame);
      }
    } else {
      if (this.startAt === undefined) this.startAt = keyFrame.timing;
    }
    this.keyFrames.enqueueEnd(keyFrame);
    this.latestEnqueuedKeyFrame = keyFrame;
  }

  dequeueAndCacheKeyFrame() {
    const kf = this.keyFrames.dequeueFront();
    if (kf) this.playedKeyFrameCache.enqueueEnd(kf);
    return kf;
  }

  getCurrentKeyFrame(timing: number): IKeyFrame | undefined {
    if (
      this.currentKeyFrame &&
      KeyFrameTool.isTimingInKeyFrame(timing, this.currentKeyFrame)
    )
      return this.currentKeyFrame;

    let head = this.keyFrames.peekFirst();
    while (head && KeyFrameTool.isTimingAfterKeyFrame(timing, head)) {
      this.dequeueAndCacheKeyFrame();
      head = this.keyFrames.peekFirst();
    }

    if (head && KeyFrameTool.isTimingInKeyFrame(timing, head)) {
      this.currentKeyFrame = this.dequeueAndCacheKeyFrame();
    }

    return this.currentKeyFrame;
  }

  getNextKeyFrame(): IKeyFrame | undefined {
    if (this.currentKeyFrame) {
      const nextFrame = this.keyFrames.peekFirst();
      if (
        nextFrame &&
        nextFrame.timing === KeyFrameTool.endTiming(this.currentKeyFrame)
      )
        return nextFrame;
    }
  }

  maybeRollback(timing: number) {
    if (this.currentKeyFrame && timing < this.currentKeyFrame.timing) {
      const cacheFirstFrame = this.playedKeyFrameCache.peekFirst();

      const mayRollbackTo = Math.max(timing, this.startAt!);
      if (
        cacheFirstFrame === undefined ||
        KeyFrameTool.isTimingBeforeKeyFrame(
          mayRollbackTo,
          cacheFirstFrame,
          true
        )
      ) {
        // 如果cacheFirstFrame为空，属于非预期情况，报错；
        // 如果播放完的帧缓存中第一帧的时间比timing大，说明缓存内没有足够的帧，报错。
        throw new PlayBackCacheUnderflowError();
      }

      while (true) {
        const kf = this.rollback(1);
        if (!KeyFrameTool.isTimingBeforeKeyFrame(mayRollbackTo, kf!, true)) {
          break;
        }
      }
    }
  }

  rollback(count: number): IKeyFrame | undefined {
    if (count > this.playedKeyFrameCache.size()) {
      throw Error("Exceed cache size");
    }
    this.currentKeyFrame = undefined;
    let lastDequeued: IKeyFrame | undefined;

    for (let i = 0; i < count; i++) {
      lastDequeued = this.playedKeyFrameCache.dequeueEnd()!;
      this.prependKeyFrame(lastDequeued);
    }

    return lastDequeued;
  }
}
