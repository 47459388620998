import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";

export function loadModel(path) {
    const loader = new GLTFLoader();
    return new Promise((resolve, reject) => {
        loader.load(
            path,
            (gltf) => {
                resolve(gltf.scene)
            },
            undefined,
            (e) => {
                console.log("GLTF load failed", e.message);
                reject(e);
            }
        )
    })
}