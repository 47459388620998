import { ICrowdPlayer, PlayBackCacheUnderflowError } from "./AbstractPlayer";
import { IKeyFrame } from "./KeyFrame";
import { IDisplayLayer } from "../../Pages/WolongVis";

export class FrameFetcher {
  private caching: boolean = false;
  private finalized: boolean = false;

  constructor(
    readonly framesReceived: (
      offset: number,
      frame: { [playerName: string]: { [id: string]: IKeyFrame } }
    ) => void
  ) {}

  isFinalized(): boolean {
    return this.finalized;
  }

  isCaching(): boolean {
    return this.caching;
  }

  start() {
    if (this.caching) {
      throw Error("正在由其他过程缓冲。");
    } else if (this.finalized) {
      throw Error("无法在已结束的Fetcher中调用start");
    }
    this.caching = true;
  }

  write(
    offset: number,
    frame: { [playerName: string]: { [id: string]: IKeyFrame } }
  ) {
    if (this.caching) {
      this.framesReceived(offset, frame);
    } else {
      throw Error("必须先调用start，再调用write");
    }
  }

  end() {
    if (!this.caching) throw Error("在未缓冲时调用end()");
    this.caching = false;
  }

  finalize() {
    this.caching = false;
    this.finalized = true;
  }
}

export class PlayerManager {
  private lastFrameDate?: Date;
  private timing: number;
  private playing: boolean = false;
  private ensureStop: boolean = false;
  private ended: boolean = false;
  private waitingForFrame: boolean = false;
  private lastReceivedFrameIndex: number = 0;
  private aniHandler?: number;
  private startAt: number;
  private displayLayers: IDisplayLayer;

  // 在还剩多少秒剩余时开始缓冲
  public startPrefetchBefore: number;

  // 因帧数不够导致播放停止后，需要缓冲多少秒才恢复播放；
  public resumeTimeThreshold: number;
  private frameFetcher: FrameFetcher;

  // Resume检查计时器
  private resumeCheckTimer?: NodeJS.Timer;

  // 事件
  public onPlayFrame?: (frame: number) => void;
  public onReceiveFrame?: (frame: number) => void;

  constructor(
    // protected player: ICrowdPlayer,
    protected players: { [playerName: string]: ICrowdPlayer },
    private onFetchRequest: (
      offset: number,
      num: number,
      fetcher: FrameFetcher
    ) => void,
    public prefetchFrames: number, // 一次缓冲多少帧
    start: number = 0,
    public frameLength: number = 1, // 一帧时长
    public speed: number = 1 // 播放速率（frames/sec）
  ) {
    this.timing = this.startAt = start;
    this.startPrefetchBefore = 2.0;
    this.resumeTimeThreshold =
      this.frameLength * Math.min(2, this.prefetchFrames);
    this.frameFetcher = new FrameFetcher((offset, frame) =>
      this.onFrame(offset, frame)
    );
    this.displayLayers = {
      car: false,
      road: false,
      stoppedRoad: false,
      elec: false,
      drainage: false,
      supply: false,
      flooded: false,
      comm: false,
      aoi_heatmap: false,
      population_heatmap: false,
      elec_demand: false,
      comm_demand: false,
      water_demand: false,
      comm_heatmap: false,
      comm_heatmap_micro: false,
      specialCar:false,
    };
  }

  public set_displayLayers(displayLayers: IDisplayLayer) {
    this.displayLayers = displayLayers;
  }

  public displayLayerSet(layerSet: Set<string>) {
    Object.keys(this.displayLayers).forEach(layer => {
      // @ts-ignore
      this.displayLayers[layer] = layerSet.has(layer);
    })
  }

  public set_start(n: number) {
    this.startAt = n;
    this.timing = n;
    this.lastReceivedFrameIndex = n - 1;
  }

  public attachSubCrowdPlayer(
    playerName: string,
    player: ICrowdPlayer,
    update: boolean = false
  ) {
    if (this.players.hasOwnProperty(playerName)) {
      if (update) {
        this.players[playerName] = player;
      } else {
        console.error(`已经存在名为"${playerName}"的播放器。`);
        throw Error(`已经存在名为"${playerName}"的播放器。`);
      }
    } else {
      this.players[playerName] = player;
    }
  }

  public detachSubCrowdPlayer(playerName: string) {
    if (this.players.hasOwnProperty(playerName)) {
      delete this.players[playerName];
    } else {
      console.warn(`尝试detach名为"${playerName}"的播放器，但其并不存在`);
    }
  }

  /**
   * 收到关键帧。
   */
  private onFrame(
    offset: number,
    frame: { [playerName: string]: { [id: string]: IKeyFrame } }
  ) {
    if (offset + 1 > this.lastReceivedFrameIndex) {
      this.lastReceivedFrameIndex = offset + 1;
      Object.entries(frame).forEach(([playerName, frame]) => {
        if (this.players.hasOwnProperty(playerName))
          this.players[playerName].appendKeyFrames(frame);
      });
      if (this.onReceiveFrame) this.onReceiveFrame(offset + 1);
      if (!this.playing && this.getPlayingOffset() === offset) {
        console.log("force play update");
        this.doPlay(true);
      }
    } else {
      console.log("关键帧乱序");
    }
  }

  framesLeft() {
    return Math.max(
      this.lastReceivedFrameIndex - this.timing / this.frameLength,
      0
    );
  }

  timeCanPlay() {
    return (this.framesLeft() * this.frameLength) / this.speed;
  }

  getPlayingOffset() {
    return Math.floor(this.timing / this.frameLength);
  }

  /**
   * 请求关键帧
   * @param from 第几帧
   * @private
   */
  private requestFetchFrame(from?: number) {
    console.debug("开始缓冲");
    if (!this.resumeCheckTimer) {
      this.resumeCheckTimer = setInterval(() => this.checkShouldResume(), 200);
    }
    this.onFetchRequest(
      from ? from : this.lastReceivedFrameIndex,
      this.prefetchFrames * this.speed,
      this.frameFetcher
    );
  }

  private clearResumeTimer() {
    if (this.resumeCheckTimer) {
      clearInterval(this.resumeCheckTimer);
      this.resumeCheckTimer = undefined;
    }
  }

  private resume() {
    console.debug("Resume");
    this.clearResumeTimer();
    this.lastFrameDate = undefined;
    this.waitingForFrame = false;
    this.doPlay();
  }

  private checkShouldResume() {
    console.debug("checking should resume.");
    if (this.playing && this.waitingForFrame) {
      if (this.timeCanPlay() >= this.resumeTimeThreshold) {
        this.resume();
      } else if (!this.frameFetcher.isCaching()) {
        if (this.frameFetcher.isFinalized()) {
          this.resume();
        } else this.requestFetchFrame();
      }
    }
  }

  private checkFramePool(): boolean {
    // 查看缓冲的帧是否足够播放
    if (this.timeCanPlay() <= this.startPrefetchBefore) {
      // 达到开始缓冲阈值，发起缓冲请求；
      console.debug("caching state:", this.frameFetcher.isCaching());
      if (!this.frameFetcher.isCaching() && !this.frameFetcher.isFinalized()) {
        this.requestFetchFrame();
      }
    }

    if (this.framesLeft() <= 0) {
      if (this.frameFetcher.isFinalized()) {
        // 已结束；
        this.ended = true;
        return false;
      } else {
        // 缓冲区空，暂停播放；
        this.waitingForFrame = true;
        return false;
      }
    }

    return true;
  }

  play() {
    if (this.playing) {
      console.warn("正在播放，重复play()无效");
    } else if (this.ended) {
      console.warn("已结束，无法继续播放");
    } else {
      this.ensureStop = false;
      this.playing = true;
      this.lastFrameDate = undefined;
      this.doPlay();
    }
  }

  /**
   * 播放一帧
   * @param frameLength 帧长度，若不指定则获取构造函数内frameLength。
   */
  playOneFrame(frameLength?: number) {
    if (this.playing) {
      this.pause();
      this.lastFrameDate = undefined;
    }
    if (frameLength === undefined) {
      frameLength = this.frameLength;
    }
    if (this.framesLeft() < 1) {
      if (this.ended) return;
      else {
        this.checkFramePool();
      }
    } else {
      this.lastFrameDate = undefined;
      this.timing += frameLength;
      this.doPlay();
    }
  }

  private reversePrefetch() {
    const timing = this.timing;
    console.log("reverse prefetch");
    this.playFromTime(
      this.timing - this.frameLength * (this.prefetchFrames - 1),
      false
    );
    this.timing = timing;
  }

  playbackOneFrame(frameLength?: number) {
    if (this.playing) {
      this.pause();
      this.lastFrameDate = undefined;
    }
    if (frameLength === undefined) {
      frameLength = this.frameLength;
    }
    try {
      this.lastFrameDate = undefined;
      this.timing -= frameLength;
      console.log(this.timing);
      if (this.timing < this.startAt) this.reversePrefetch();
      else this.doPlay();
    } catch (err) {
      if (err instanceof PlayBackCacheUnderflowError) {
        this.reversePrefetch();
      }
    }
  }

  playFromTime(timing: number, play: boolean) {
    this.clear();
    this.lastReceivedFrameIndex = Math.floor(timing / this.frameLength);
    this.timing = timing;
    this.startAt = timing;
    if (play) this.play();
    else {
      this.ensureStop = false;
      this.doPlay();
    }
  }

  flush() {
    Object.values(this.players).forEach((player) => player.play(this.timing));
  }

  private doPlay(force: boolean = false) {
    if (this.ensureStop) {
      this.ensureStop = false;
      return;
    }
    if (!this.checkFramePool() && !force) {
      console.debug("暂停播放，因为checkFramePool要求");
      return;
    }

    const now = new Date();
    if (this.lastFrameDate) {
      this.timing +=
        ((now.getTime() - this.lastFrameDate.getTime()) / 1000) * this.speed;
    }
    this.lastFrameDate = now;

    Object.entries(this.players).forEach(([id, player]) => {
      if (
        (id.startsWith("car") && this.displayLayers.car) ||
        (id.startsWith("people") && this.displayLayers.car) ||
        (id.startsWith("trafficLight") && this.displayLayers.car) ||
        (id.startsWith("elec") && this.displayLayers.elec) ||
        (id.startsWith("road") && this.displayLayers.road) ||
        (id.startsWith("stopped_road") && this.displayLayers.stoppedRoad) ||
        (id.startsWith("special_car") && this.displayLayers.specialCar) ||
        (id.startsWith("flooded") && this.displayLayers.flooded) ||
        (id.startsWith("drainage") && this.displayLayers.drainage) ||
        (id.startsWith("supply") && this.displayLayers.supply) ||
        (id.startsWith("aoi") && this.displayLayers.aoi_heatmap) ||
        (id.startsWith("heatmap") && this.displayLayers.population_heatmap) ||
        (id.startsWith("comm") && this.displayLayers.comm) ||
        (id.startsWith("commDemand_") && this.displayLayers.comm_heatmap) ||
        (id.startsWith("commDemandMicro_") && this.displayLayers.comm_heatmap_micro) ||
        (id.startsWith("elec_demand") && this.displayLayers.elec_demand) ||
        (id.startsWith("comm_demand") && this.displayLayers.comm_demand) ||
        (id.startsWith("water_demand") && this.displayLayers.water_demand) ||
        id.startsWith("roadMetric") ||
        id.startsWith("waterRoadMetric") ||
        id.startsWith("commDemandMetric") ||
        id.startsWith("waterDrainageLinkMetric") ||
        id.startsWith("waterSupplyLinkMetric") ||
        id.startsWith("trafficMetric") ||
        id.startsWith("elecNodeMetric") ||
        id.startsWith("cityMetric") ||
        id.startsWith("waterState") ||
        id.startsWith("trafficState") ||
        id.startsWith("commState") ||
        id.startsWith("elecState") ||
        id.startsWith("cityOverview") ||
        id.startsWith("cityRuntime") ||
        id.startsWith("demandMetric") ||
        id.startsWith("elecDemandMetric") ||
        id.startsWith("commNodeMetric") ||
        id.startsWith("commMetric") ||
        id.startsWith("event")||
        id.startsWith("road_stat_")||
        id.startsWith("aoi_stat_")
      ) {
        player.play(this.timing);
      }
    });
    if (this.onPlayFrame) this.onPlayFrame(this.getPlayingOffset());

    if (this.playing)
      this.aniHandler = requestAnimationFrame(() => this.doPlay());
  }

  pause() {
    this.playing = false;
    if (this.aniHandler) cancelAnimationFrame(this.aniHandler);
    this.clearResumeTimer();
  }

  clear_queue() {
    Object.values(this.players).forEach((player) => player.clear());
  }

  clear() {
    this.pause();
    this.ensureStop = true;
    this.timing = 0;
    this.lastFrameDate = undefined;
    this.ended = false;
    this.waitingForFrame = false;
    this.lastReceivedFrameIndex = 0;
    this.frameFetcher = new FrameFetcher((offset, frame) =>
      this.onFrame(offset, frame)
    );
    Object.values(this.players).forEach((player) => player.clear());
  }
}
