export var Jobs = [
    { label: "普通信控（上周）", value: "hnu_normal" },
    { label: "智能信控（上周）", value: "hnu_maxpressure" },
    { label: "普通信控-无降雨", value: "hnu_normal_norain_80" },
    { label: "普通信控-暴雨", value: "hnu_normal_rain_80" },
    { label: "普通信控-无降雨-双倍车流", value: "hnu_normal_norain_160" },
    { label: "智能信控-暴雨", value: "hnu_maxpressure_rain_80" },
]

var jobRainBase = new Map<string, string>([
    ["hnu_normal", "baoyu_60"],
    ["hnu_maxpressure", "baoyu_60"],
    ["hnu_normal_norain_80", "js_big"],
    ["hnu_normal_rain_80", "baoyu_60"],
    ["hnu_normal_norain_160", "js_big"],
    ["hnu_maxpressure_rain_80", "baoyu_60"],
])

export function GetLabelByJob(job: string) {
    for (var i = 0; i < Jobs.length; i++) {
        if (Jobs[i].value == job) {
            return Jobs[i].label
        }
    }
    return ""
}

export function GetRainBaseByJob(job: string) {
    return jobRainBase.get(job) ?? job
}
