import React, { Component } from "react";
import { CoordinateTransformer } from "../core/utils/coordutils";
import CarLayer, { CarInfo } from "./mapbox/CarLayer";
import { RoadLayer } from "./mapbox/RoadLayer";
import { CommHeatmapLayer } from "./mapbox/CommHeatmapLayer";
import { FloodedLayer } from "./mapbox/FloodedLayer";
import { WaterLayer } from "./mapbox/WaterLayer";
import { SupplyLayer } from "./mapbox/SupplyLayer";
import { AoiLayer } from "./mapbox/AoiLayer";
import { DemandLayer } from "./mapbox/DemandLayer";
import {
  RoadReader,
  WaterReader,
  AoiReader,
  JsonReader,
  RoadCenterReader,
} from "./mapbox/GeoJsonReader";
import Mapbox, { MarkInfo } from "./mapbox/MapBox";
import { PlayerManager } from "../core/player/PlayerManager";
import { DemandLogoLayer } from "./mapbox/DemandLogoLayer";
import { CommMicroHeatmapLayer } from "./mapbox/CommMicroHeatmapLayer";
import { StoppedRoadLayer } from "./mapbox/StoppedRoadLayer";
import { SpecialCarLayer } from "./mapbox/SpecialCarLayer"
import { MicroPopulationHeatmapLayer } from "./mapbox/MicroPopulationHeatmapLayer";
import { BaseStationLayer } from "./mapbox/Layers/BaseStationLayer";
import { ElecLinkLayer, ElecNodeLayer } from "./mapbox/Layers/ElecLayer";
import { AoiSelectorLayer } from './mapbox/Layers/AoiSelectorLayer'
import { RoadCanChoseLayer } from './mapbox/Layers/RoadCanChoseLayer'
import PubSub from 'pubsub-js';

type SimulateDemoPropsType = {
  allLayerPlayerManager: PlayerManager;
  index: number;
  onCarSelected?: (carInfo: CarInfo) => void;
  mapCenter: { lng: number; lat: number };
  transformer: CoordinateTransformer;
  markers?: MarkInfo[];
};

class SimuletDemo extends Component<SimulateDemoPropsType> {
  layers: {
    carLayer: CarLayer;
    roadLayer: RoadLayer;
    stoppedRoadLayer: StoppedRoadLayer;
    elecLayer: ElecNodeLayer;
    elecTopoLayer: ElecLinkLayer;
    waterLayer: WaterLayer;
    supplyLayer: SupplyLayer;
    floodedLayer: FloodedLayer;
    aoiLayer: AoiLayer;
    populationHeatmapLayer: MicroPopulationHeatmapLayer;
    commLayer: BaseStationLayer;
    elec_demandLayer: DemandLayer;
    comm_demandLayer: DemandLayer;
    water_demandLayer: DemandLayer;
    commHeatmapLayer: CommHeatmapLayer;
    commMicroHeatmapLayer: CommMicroHeatmapLayer;
    demandLogoLayer: DemandLogoLayer;
    specialCarLayer: SpecialCarLayer;
    roadCanChoseLayer: RoadCanChoseLayer;
    aoiSelectorLayer: AoiSelectorLayer;
  }
  aoiReader: AoiReader;
  mapbox = React.createRef<Mapbox>();

  constructor(props: SimulateDemoPropsType) {
    super(props);
    const idx = props.index;
    this.aoiReader = new AoiReader("/aoicenter_with_micro.json");

    this.layers = {
      carLayer: new CarLayer("car", idx, {
        transformer: this.props.transformer,
        playerManager: props.allLayerPlayerManager,
        onCarSelected: props.onCarSelected,
        crossLaneMapReader: new RoadReader("/junclane", this.props.transformer),
      }),
      roadLayer: new RoadLayer("road", idx, {
        width: 50,
        roadMapReader: new RoadReader("/road", this.props.transformer),
        playerManager: props.allLayerPlayerManager,
        transformer: this.props.transformer,
      }),
      stoppedRoadLayer: new StoppedRoadLayer("stopped_road", idx, {
        playerManager: props.allLayerPlayerManager,
        reader: new RoadCenterReader('/geojson_roadcenter.json'),
      }),
      elecLayer: new ElecNodeLayer('elec', idx, { playerManager: props.allLayerPlayerManager }),
      elecTopoLayer: new ElecLinkLayer('elec_topo', idx, { playerManager: props.allLayerPlayerManager }),
      waterLayer: new WaterLayer("water", idx, {
        waterReader: new WaterReader("/drainage.geojson"),
        playerManager: props.allLayerPlayerManager,
      }),
      supplyLayer: new SupplyLayer("supply", idx, {
        waterReader: new WaterReader("/supply.geojson"),
        playerManager: props.allLayerPlayerManager,
      }),
      floodedLayer: new FloodedLayer("flooded", idx, {
        width: 50,
        roadMapReader: new RoadReader("/road", this.props.transformer),
        playerManager: props.allLayerPlayerManager,
        transformer: this.props.transformer,
      }),
      aoiLayer: new AoiLayer("aoi", idx, {
        playerManager: props.allLayerPlayerManager,
        aoiReader: this.aoiReader,
      }),
      populationHeatmapLayer: new MicroPopulationHeatmapLayer('population_heatmap', idx, {
        playerManager: props.allLayerPlayerManager,
      }),
      commLayer: new BaseStationLayer("comm", idx, {
        playerManager: props.allLayerPlayerManager,
      }),
      elec_demandLayer: new DemandLayer("elec_demand", idx, "elec", {
        playerManager: props.allLayerPlayerManager,
        aoiReader: this.aoiReader,
      }),
      comm_demandLayer: new DemandLayer("comm_demand", idx, "comm", {
        playerManager: props.allLayerPlayerManager,
        aoiReader: this.aoiReader,
      }),
      water_demandLayer: new DemandLayer("water_demand", idx, "water", {
        playerManager: props.allLayerPlayerManager,
        aoiReader: this.aoiReader,
      }),
      demandLogoLayer: new DemandLogoLayer(
        'demand_logo',
        {
          typeDefs: [
            { layerName: "elec_demand_logo", demandType: "elec", icons: { imageSatisfied: "需求-用电-满足", imageUnsatisfied: "需求-用电-不满足" } },
            { layerName: "comm_demand_logo", demandType: "comm", icons: { imageSatisfied: "需求-通信-满足", imageUnsatisfied: "需求-通信-不满足" } },
            { layerName: "water_demand_logo", demandType: "water", icons: { imageSatisfied: "需求-用水-满足", imageUnsatisfied: "需求-用水-不满足" } },
          ]
        }
      ),
      commHeatmapLayer: new CommHeatmapLayer("commDemand", idx, {
        playerManager: props.allLayerPlayerManager,
        jsonReader: new JsonReader("/signal.json"),
      }),
      commMicroHeatmapLayer: new CommMicroHeatmapLayer("commDemandMicro", idx, {
        playerManager: props.allLayerPlayerManager,
        jsonReader: new JsonReader("/guomao_signal.json"),
      }),
      specialCarLayer: new SpecialCarLayer("specialCar", idx, {
        playerManager: props.allLayerPlayerManager,
      }),
      roadCanChoseLayer: new RoadCanChoseLayer("roadCanChose", idx, {
        playerManager: props.allLayerPlayerManager,
      }),
      aoiSelectorLayer: new AoiSelectorLayer("aoiSelector", idx, {
        playerManager: props.allLayerPlayerManager,
      })
    }
  }

  getMap() {
    return this.mapbox.current!.map;
  }

  render() {
    // console.log(this.props);
    return (
      <div className="App" style={{ margin: 10 }}>
        <Mapbox
          style={{ height: "77vh" }}
          lng={this.props.mapCenter.lng}
          lat={this.props.mapCenter.lat}
          zoom={10.5}
          pitch={0}
          buildingLayer={false}
          externalLayers={[this.layers.carLayer, this.layers.roadLayer, this.layers.floodedLayer]}
          otherLayers={[
            this.layers.stoppedRoadLayer,
            this.layers.elecLayer,
            this.layers.elecTopoLayer,
            this.layers.waterLayer,
            this.layers.supplyLayer,
            this.layers.commLayer,
            this.layers.aoiLayer,
            this.layers.populationHeatmapLayer,
            this.layers.elec_demandLayer,
            this.layers.comm_demandLayer,
            this.layers.water_demandLayer,
            this.layers.commHeatmapLayer,
            this.layers.commMicroHeatmapLayer,
            this.layers.demandLogoLayer,
            this.layers.specialCarLayer,
            this.layers.roadCanChoseLayer,
            this.layers.aoiSelectorLayer
          ]}
          ref={this.mapbox}
          markers={this.props.markers ?? []}
          images={[
            { name: "基站-正常", path: "/images/comm/基站-正常.png" },
            { name: "基站-直流", path: "/images/comm/基站-直流.png" },
            { name: "基站-不可工作", path: "/images/comm/基站-不可工作.png" },
            { name: "网关-正常", path: "/images/comm/网关-正常.png" },
            { name: "网关-不可工作", path: "/images/comm/网关-不可工作.png" },
            { name: "网关-直流", path: "/images/comm/网关-直流.png" },
            { name: "变压器-10kv-异常", path: "/images/elec/变压器-10kv-异常.png" },
            { name: "变压器-10kv-正常", path: "/images/elec/变压器-10kv-正常.png" },
            { name: "变压器-110kv-异常", path: "/images/elec/变压器-110kv-异常.png" },
            { name: "变压器-110kv-正常", path: "/images/elec/变压器-110kv-正常.png" },
            { name: "变压器-220kv-异常", path: "/images/elec/变压器-220kv-异常.png" },
            { name: "变压器-220kv-正常", path: "/images/elec/变压器-220kv-正常.png" },
            { name: "变压器-500kv-异常", path: "/images/elec/变压器-500kv-异常.png" },
            { name: "变压器-500kv-正常", path: "/images/elec/变压器-500kv-正常.png" },
            { name: "需求-用水-不满足", path: "/images/demand_logo/需求-用水-不满足.png" },
            { name: "需求-用水-满足", path: "/images/demand_logo/需求-用水-满足.png" },
            { name: "需求-用电-不满足", path: "/images/demand_logo/需求-用电-不满足.png" },
            { name: "需求-用电-满足", path: "/images/demand_logo/需求-用电-满足.png" },
            { name: "需求-通信-不满足", path: "/images/demand_logo/需求-通信-不满足.png" },
            { name: "需求-通信-满足", path: "/images/demand_logo/需求-通信-满足.png" },
            { name: "红绿灯停电", path: "/images/road/红绿灯停电.png" },
            { name: "道路中断", path: "/images/road/道路中断.png" },
          ]}
        />
      </div>
    );
  }
}

export default SimuletDemo;
