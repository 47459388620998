import mapboxgl, {GeoJSONSource} from "mapbox-gl";

export type Feature = any;
type IdType = number | string;
export type StatefulGeoSourceState<StateType extends {[stateName: string]: any}> = {id: IdType, states: StateType};

export interface IGeoSource {
    readonly sourceName: string;
    setFeatures(features: Promise<Feature[]>): Promise<void>;
    setFeaturesSync(features: Feature[]): void;
}

export interface IStatefulGeoSource<StateType> extends IGeoSource {
    setStates(states: StatefulGeoSourceState<StateType>[]): void;
    getState(id: IdType): StatefulGeoSourceState<StateType> | null;
    clearStates(ids: Set<IdType>, apply?: boolean): void;
    clearAllStates(apply?: boolean): void;
}

abstract class AbstractStatefulGeoSource<StateType extends {[stateName: string]: any}> {

    features: Feature[] = [];

    constructor(
        protected map: mapboxgl.Map,
        readonly sourceName: string,
        public readonly idField: string = 'id',
        protected readonly defaultStates: StateType
    ) {
    }

    abstract clear(): void;
    abstract doSetFeatures(features: Feature[]): void;

    applyFeatures() {
        const source = this.map.getSource(this.sourceName);
        if (source === undefined) {
            this.map.addSource(this.sourceName, {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: this.features,
                }
            })
        } else {
            (source as GeoJSONSource).setData({
                type: 'FeatureCollection',
                features: this.features,
            })
        }
    }

    setFeaturesSync(features: Feature[]) {
        this.clear();
        this.doSetFeatures(features);
        this.applyFeatures();
    };

    async setFeatures(features: Promise<Feature[]>) {
        const f = await features;
        this.setFeaturesSync(f);
    }
}

export class StatefulGeoSource<StateType extends {[stateName: string]: any}> extends AbstractStatefulGeoSource<StateType> implements IStatefulGeoSource<StateType> {

    changedStates = new Set<IdType>();
    mapIdToFeaturesIndex: {[uid: string]: number} = {};

    clear() {
        this.mapIdToFeaturesIndex = {};
        this.changedStates.clear();
        this.features = [];
    }

    doSetFeatures(features: Feature[]) {
        // 仅在有状态时处理状态相关量
        if (Object.keys(this.defaultStates).length > 0) {
            // Copy Features with default state properties.
            this.features = features.map(feature => ({
                id: feature.properties[this.idField],
                type: feature.type,
                geometry: feature.geometry,
                properties: {
                    ...this.defaultStates,
                    ...feature.properties,
                }
            }));

            // construct id - feature index mapping.
            this.features.forEach((feature, index) => {
                if (feature.properties.hasOwnProperty(this.idField)) {
                    const id = feature.properties[this.idField];
                    if (this.mapIdToFeaturesIndex[id]) {
                        console.warn(`重复的ID：${id} 在Source：${this.sourceName}`);
                    }
                    this.mapIdToFeaturesIndex[id] = index;
                }
            });
        } else {
            this.features = features.map(feature => ({
                id: feature.properties[this.idField],
                ...feature
            }));
        }
    }

    private isStateEqualToDefault(properties: any): boolean {
        return Object.entries(this.defaultStates).reduce<boolean>(
            (prev, [k, v]) => prev && properties[k] === v
            , true
        );
    }

    setStates(states: StatefulGeoSourceState<StateType>[]) {
        states.forEach(({id, states}) => {
            const feature = this.features[this.mapIdToFeaturesIndex[id]];
            if (feature) {
                Object.entries(states).forEach(([stateName, state]) => {
                    if (this.defaultStates.hasOwnProperty(stateName)) {
                        feature.properties[stateName] = state;
                    }
                });
                if (this.isStateEqualToDefault(feature.properties)) {
                    this.changedStates.delete(id);
                } else {
                    this.changedStates.add(id);
                }
            }
        });

        this.applyFeatures();
    }

    getState(id: IdType): StatefulGeoSourceState<StateType> | null {
        if (Object.keys(this.defaultStates).length > 0) {
            if (this.changedStates.has(id)) {
                const {properties} = this.features[this.mapIdToFeaturesIndex[id]];
                return {
                    id,
                    states: Object.fromEntries(
                        Object.keys(this.defaultStates).map(k => [k, properties[k]])
                    ) as StateType
                }
            }
        }

        if (this.mapIdToFeaturesIndex[id]) {
            return {id, states: Object.assign({}, this.defaultStates)}
        } else {
            return null;
        }

    }

    clearStates(ids: Set<IdType>, apply: boolean = true) {
        ids.forEach(id => {
            const feature = this.features[this.mapIdToFeaturesIndex[id]];
            Object.assign(feature.properties, this.defaultStates);
            this.changedStates.delete(id);
        });
    }

    clearAllStates(apply: boolean = true) {
        // TODO: 增加对某个state的Clear： clearAllStates(state: string)
        this.clearStates(new Set(this.changedStates), apply);
    }

    fitBounds() {
        if (this.features.length > 0) {
            const bounds = new mapboxgl.LngLatBounds();

            this.features.forEach(function(feature) {
                bounds.extend(feature.geometry.coordinates);
            });

            this.map.fitBounds(bounds, {padding: 50});
        }
    }
}

export class InternalStatefulGeoSource<StateType extends {[stateName: string]: any}> extends AbstractStatefulGeoSource<StateType> implements IStatefulGeoSource<StateType> {

    internalStates: {[key: string]: StateType} = {};
    stateChangedKeys = new Set<IdType>();
    keyToFeatureIndex: {[key: string]: number} = {};

    clear(): void {
        this.features = [];
        this.internalStates = {};
        this.stateChangedKeys.clear();
        this.keyToFeatureIndex = {};
    }

    doSetFeatures(features: Feature[]): void {
        this.features = features;
        this.features.forEach((feature, index) => {
            this.keyToFeatureIndex[feature.properties[this.idField]] = index;
        });
    }

    clearAllStates(): void {
        this.internalStates = {};
        this.stateChangedKeys.clear();
    }

    clearStates(ids: Set<IdType>): void {
        ids.forEach(id => {
            if (this.stateChangedKeys.has(id)) {
                this.stateChangedKeys.delete(id);
                delete this.internalStates[id];
            }
        });
    }

    getState(id: IdType): StatefulGeoSourceState<StateType> | null {
        if (this.stateChangedKeys.has(id)) {
            return {id, states: this.internalStates[id]};
        } else if (this.keyToFeatureIndex[id] !== undefined) {
            return {id, states: Object.assign({}, this.defaultStates)};
        } else {
            return null;
        }
    }

    setStates(states: StatefulGeoSourceState<StateType>[]): void {
        const stateCleared = new Set<IdType>();
        states.forEach(({id, states}) => {
            if (this.keyToFeatureIndex[id] !== undefined) {
                if (this.isStateEqualToDefault(states)) {
                    stateCleared.add(id);
                } else {
                    this.stateChangedKeys.add(id);
                    this.internalStates[id] = Object.fromEntries(
                        Object.entries(this.defaultStates).map(([k, v]) => [k, states[k] ?? v])
                    ) as StateType;
                }
            }
        });

        this.clearStates(stateCleared);
    }

    private isStateEqualToDefault(properties: any): boolean {
        return Object.entries(this.defaultStates).reduce<boolean>(
            (prev, [k, v]) => prev && (properties[k] === v || properties[k] === undefined),
            true
        );
    }

}