// 道路可选图层
import mapboxgl from "mapbox-gl";
import axios from "axios";


import { StatefulGeoLayer } from "./GeoLayer";
import {
    Feature,
    IStatefulGeoSource, StatefulGeoSource,
} from "../../../core/utils/StatefulGeoSource";


interface RoadChooseState {
    chosen: boolean
}

export class RoadCanChoseLayer extends StatefulGeoLayer<RoadChooseState> {

    canClick = true;
    lastSelected?: number;

    async init(map: mapboxgl.Map): Promise<void> {
        await super.init(map);
        await this.statefulSource!.setFeatures(
            // axios.get('geojson_roadcenter.json').then(resp => (resp.data as Feature[]))
            axios.get('geojson_roadcenter_beijing5ring_0424.json').then(resp => (resp.data as Feature[]))
        );
        map.loadImage("/images/comm/道路-未选中@2x.png", (err, image) => {
            if (err) throw err;
            this.map!.addImage("BaseStation", image!);
        });
        map.loadImage("/images/comm/道路-选中@2x.png", (err, image) => {
            if (err) throw err;
            this.map!.addImage("BaseStationChosen", image!);
        });

        PubSub.subscribe("choseType",(_,data)=>{
            if(data&&data.type=="aoi-detail"){
                if(this.lastSelected){
                  this.statefulSource?.setStates([{ id: this.lastSelected, states: { chosen: false } }])
                }
            }
        })
    }
    getLayerDef(): mapboxgl.AnyLayer {
        return {
            type: "symbol",
            layout: {
                "icon-allow-overlap": false,
                "icon-image": [
                    "case",
                    ["get", "chosen"],
                    "BaseStationChosen",
                    "BaseStation"
                ],
                "icon-size": 0.15,
            },
            minzoom:15
        } as any;
    }
    async getStatefulSource(): Promise<IStatefulGeoSource<RoadChooseState>> {
        return new StatefulGeoSource(
            this.map!, this.id, 'id', { chosen: false }
        );
    }

    on(type: "click", features: any[]) {
        PubSub.publish('choseType', {type: 'road-detail',currentID:features[0].properties.id,currentIndex:this.index,roadName:features[0].properties.name});
        this.lastSelected=features[0].properties.id
        this.statefulSource?.clearAllStates(false)
        this.statefulSource?.setStates([{ id: features[0].properties.id, states: { chosen: true } }])
    }
}