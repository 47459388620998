import mapboxgl from "mapbox-gl";

export class BaseCustomLayer extends Object {
  id: string;
  type: string;
  renderingMode: string;
  props: any;
  map?: mapboxgl.Map;
  subLayerIdToLocalVisibility: {[id: string]: boolean} = {}

  canClick: boolean = false;
  clickableLayers: string[] = [];

  constructor(id: string, renderingMode: string, props: any) {
    super();
    this.id = id;
    this.type = "custom";
    this.renderingMode = renderingMode;
    this.props = props;
  }

  onAdd(map: any, gl: any) {}

  onJobLoaded(jobName: string) {}

  render(gl: any, matrix: any) {}

  setLayerVisibility(visible: boolean) {
    if (this.map) {
      Object.entries(this.subLayerIdToLocalVisibility).forEach(([layer, localVisibility]) =>
          this.map?.setLayoutProperty(layer, 'visibility', visible && localVisibility ? 'visible': 'none'));
    }
  }

  init(map: mapboxgl.Map) {
    this.map = map;
  }

  on(type: "click", features: any[]) {

  }
}

export class FilterComponent {
  filters: {[name: string]: any[]};

  constructor(private map: mapboxgl.Map, readonly layerId: string, defaultFilters: {[name: string]: any[]}, public filterMode: string = 'all') {
    this.filters = defaultFilters;
  }

  applyFilters() {
    this.map.setFilter(this.layerId, this.getFilterExp());
  }

  getFilterExp() {
    const filters = Object.values(this.filters).filter(v => v.length > 0);
    const filterCount = filters.length;

    if (filterCount === 0) {
      return ['boolean', true];
    } else if (filterCount === 1) {
      return filters[0];
    } else {
      return [this.filterMode, ...Object.values(this.filters)];
    }
  }

  clear() {
    Object.keys(this.filters).forEach(k => this.filters[k] = ['boolean', true]);
  }
}