import { Vector3 } from "three";
import {
  CarLayerKeyFrameFactory,
  CopyDataFrameFactory,
  PedsimKeyFrameFactory,
  RoadKeyFrameFactory,
} from "../../Components/mapbox/FrameFactories";
import { IKeyFrame } from "../player/KeyFrame";

abstract class StatefulFrameConstructor {
  lastSeen = new Set<string>();
  extra: { kfFactory?: CarLayerKeyFrameFactory | undefined } | undefined;

  constructor(extra: { kfFactory?: CarLayerKeyFrameFactory } | undefined) {
    this.extra = extra;
  }

  clear() {
    this.lastSeen.clear();
  }

  abstract getEntryId(entry: any): string;

  abstract constructMovementFrame(entry: any, item: any, extra: any): IKeyFrame;

  abstract constructDisappearedFunctionalFrame(
    id: any,
    item: any,
    extra: any
  ): IKeyFrame;

  constructFrameFromItem(item: any, extra: any): { [k: string]: IKeyFrame } {
    Object.assign(extra, this.extra);
    const currentSeen = new Set<string>();
    const frames = Object.fromEntries(
      item.map((entry: any) => [
        this.getEntryId(entry),
        this.constructMovementFrame(entry, item, extra),
      ])
    );
    item.forEach((entry: any) => currentSeen.add(this.getEntryId(entry)));

    const disappeared = this.lastSeen;
    // 计算需要构造移出场景消息的关键帧所对应的物体id
    currentSeen.forEach((id) => disappeared.delete(id));

    disappeared.forEach((id: string) => {
      frames[id] = this.constructDisappearedFunctionalFrame(id, item, extra);
    });

    this.lastSeen = currentSeen;
    return frames;
  }
}

export class StatefulCarFrameConstructor extends StatefulFrameConstructor {
  getEntryId(entry: any) {
    return entry.carId;
  }

  constructMovementFrame(entry: any, item: any, extra: any) {
    return extra.kfFactory.newMovementFrame(
      [entry.lng, entry.lat],
      extra.offset * extra.frameLength,
      extra.frameLength,
      entry.direction,
      { laneId: entry.laneId, description: entry.description }
    );
  }

  constructDisappearedFunctionalFrame(id: any, item: any, extra: any) {
    return extra.kfFactory.newFunctionalFrame(
      item.offset * extra.frameLength,
      Infinity,
      true
    );
  }
}

export class StatefulPeopleFrameConstructor extends StatefulCarFrameConstructor {
  getEntryId(entry: any) {
    return entry.peopleId;
  }
}

export class StatefulPedsimFrameConstructor extends StatefulFrameConstructor {
  kfFactory = new PedsimKeyFrameFactory();

  getEntryId(entry: any) {
    return entry.id;
  }

  constructMovementFrame(entry: any, item: any, extra: any) {
    return this.kfFactory.newMovementFrame(
      new Vector3(entry.x, entry.y),
      extra.offset * extra.frameLength,
      extra.frameLength,
      entry.direction,
      { id: entry.id }
    );
  }

  constructDisappearedFunctionalFrame(id: any, item: any, extra: any) {
    return this.kfFactory.newFunctionalFrame(
      extra.offset * extra.frameLength,
      Infinity,
      false,
      true
    );
  }
}

export class TrafficLightFrameConstructor {
  kfFactory = new RoadKeyFrameFactory();

  constructFrameFromItem(item: any, extra: any) {
    return Object.fromEntries(
      item.map((frame: any) => {
        return [
          frame.laneId,
          this.kfFactory.newKeyFrame(
            extra.offset * extra.frameLength,
            Infinity,
            frame.state
          ),
        ];
      })
    );
  }
}

export class StatisticsFrameConstructor {
  kfFactory = new CopyDataFrameFactory();

  constructFrameFromItem(item: any, extra: any) {
    return {
      roadMetric: this.kfFactory.newCustomDataKeyFrame(
        extra.offset,
        Infinity,
        item.speed_distribution
      ),
      speedDiff: this.kfFactory.newCustomDataKeyFrame(
        extra.offset,
        Infinity,
        item.speed_diff
      ),
    };
  }
}

export class FrameInfoFrameConstructor {
  kfFactory = new CopyDataFrameFactory();

  constructFrameFromItem(item: any, extra: any) {
    return {
      info: this.kfFactory.newCustomDataKeyFrame(extra.offset, Infinity, item),
    };
  }
}
