export interface AllElecData {
  step: number;
  basicInfo: {
    stationCnt: number[];
    averagePower: number[];
  };
  demand: {
    userDemand: number;
    userSatisfiedCnt: number;
    userSatisfiedRatio: number;
    aoiDemand: number;
    aoiSatisfiedCnt: number;
    aoiSatisfiedRatio: number;
  };
  abnormalInfo: {
    chaoyang: number;
    dongcheng: number;
    fengtai: number;
    haidian: number;
    shijingshan: number;
    xicheng: number;
    trans500: number[];
    trans220: number[];
    trans110: number[];
    trans10: number[];
  };
}

export interface AllWaterData {
  step: number;
  drainageBasicInfo: {
    averagePower: number;
    undrainedVolume: number;
    drainedVolume: number;
    averageFlow: number;
    floodedVolume: number;
  };
  supplyBasicInfo: {
    averagePower: number;
    averageFlow: number;
  };
  demand: {
    userDemand: number;
    userSatisfiedCnt: number;
    userSatisfiedRatio: number;
    aoiDemand: number;
    aoiSatisfiedCnt: number;
    aoiSatisfiedRatio: number;
  };
  loadRatio: {
    drainage: number;
    supply: number;
  };
  abnormalInfo: {
    drainage: number[];
    supply: number[];
  };
}

export interface AllTrafficData {
  abnormalDetail: {
    overfloodedRoadNames: string[];
    overloadRoadNames: string[];
    regionCongestion: {
      chaoyang: number;
      dongcheng: number;
      fengtai: number;
      haidian: number;
      shijingshan: number;
      xicheng: number;
    };
    regionOverfloodedNum: {
      chaoyang: number;
      dongcheng: number;
      fengtai: number;
      haidian: number;
      shijingshan: number;
      xicheng: number;
    };
  };
  abnormalInfo: {
    congestionLevelCounts: number[];
    facility: {
      numBadTrafficLight: number;
      numInterruptedRoad: number;
      numRestrictedRoad: number;
      numRoadWithWater: number;
    };
    meanCongestionLevel: number;
    trafficLight: number[];
  };
  basicInfo: {
    numPersonInAoi: number;
    numPersonOutOfAoi: number;
    numVehicle: number;
    vehicleAverageSpeed: number;
  };
  step: number;
}

export interface AllCommData {
  basicInfo: {
    demandFlow: number;
    numActiveAgent: number;
    numOutageAgent: number;
    powerConsumption: number;
  };
  demand: {
    numActiveAgent: number;
    numUnsatisfiedAgent: number;
    satisfiedRatio: number;
    chaoyang: number;
    dongcheng: number;
    fengtai: number;
    haidian: number;
    shijingshan: number;
    xicheng: number;
  };
  runtime: {
    baseStationNetworkCount: number[];
    baseStationPowerCount: number[];
    gatewayNetworkCount: number[];
    gatewayPowerCount: number[];
    chaoyang: number;
    dongcheng: number;
    fengtai: number;
    haidian: number;
    shijingshan: number;
    xicheng: number;
  };
  step: number;
}

export interface CtiyOverviewData {
  chaoyang: number;
  dongcheng: number;
  fengtai: number;
  haidian: number;
  shijingshan: number;
  xicheng: number;
  step: number;
}

export interface CityRuntimeData {
  demand: {
    commPerson: number;
    elecAoi: number;
    elecPerson: number;
    trafficPerson: number;
    waterAoi: number;
    waterPerson: number;
  };
  pressureIndex: number;
  publicPanicIndex: number;
  repairResource: {
    comm: number[];
    elec: number[];
    traffic: number[];
    water: number[];
  };
  repairTime: {
    comm: number[];
    elec: number[];
    traffic: number[];
    water: number[];
  };
  step: number;
}
export interface RoadDetailStat{
  depth:number,
  depth_history:number[],
  num_pedestrians:number,
  num_vehicles:number,
  pedestrian_history:number[],
  step:number,
  vehicle_history:number[]
}

export const  RoadDetailStatInitial:RoadDetailStat={
  depth:0,
  depth_history:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
  num_pedestrians:0,
  num_vehicles:0,
  pedestrian_history:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
  step:1,
  vehicle_history:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
}
export interface AoiDetailStat{
  basic:{
    agent:number,
    comm:number,
    elec:number,
    water:number
  },
  demand:{
    comm:number,
    elec:number,
    water:number
  },
  history:{
    agent:number[],
    comm:{
      demand:number[],
      supply:number[]
    },
    elec:{
      demand:number[],
      supply:number[]
    },
    water:{
      demand:number[],
      supply:number[]
    }
  },
  id:number,
  step:number
}
export const AoiDetailStatInitial:AoiDetailStat={
  basic:{
    agent:0,
    comm:0,
    elec:0,
    water:0
  },
  demand:{
    comm:0,
    elec:0,
    water:0
  },
  history:{
    agent:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    comm:{
      demand:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      supply:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    },
    elec:{
      demand:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      supply:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    },
    water:{
      demand:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      supply:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    }
  },
  id:0,
  step:1
}