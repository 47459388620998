import * as THREE from "three";
import {
  IFunctionalKeyFrame,
  IKeyFrame,
  IMovementKeyFrame,
  KeyFrameTool,
} from "./KeyFrame";
import { AbstractPlayer, ICrowdPlayer } from "./AbstractPlayer";
import { normAngle } from "../utils/coordutils";

class PersonState {
  position: THREE.Vector3 | undefined;
  rotation: THREE.Euler | undefined;

  constructor(position: THREE.Vector3, rotation?: THREE.Euler) {
    this.position = position;
    this.rotation = rotation;
  }
}

interface ISuperPlayerMessage {
  shouldRemove?: boolean;
}

interface IPersonStateSetter {
  set(
    position?: THREE.Vector3,
    rotation?: THREE.Euler,
    visible?: boolean
  ): void;
}

class PeoplePlayer extends AbstractPlayer {
  id: string;
  enableFrameInterpolation: boolean = true;

  constructor(
    id: string,
    readonly stateSetter: IPersonStateSetter,
    readonly scale: number
  ) {
    super();
    this.id = id;
  }

  angleInterp(ang1: number, ang2: number, percentage: number): number {
    let delta = normAngle(ang2 - ang1);
    if (delta > Math.PI) delta = delta - Math.PI * 2;
    return ang1 + delta * percentage;
  }

  /**
   * 设置车辆插帧（位置、朝向等）。
   * 从lastKeyFrame向currentKeyFrame插帧：
   * 将车辆置于lastKeyFrame状态和currentKeyFrame
   *     状态的中间处（由参数percentage决定）的某个状态。
   * @param percentage    插帧百分比，0.0-1.0
   * @param kf1           第一帧
   * @param kf2           第二帧
   * @param interpPosition 是否进行位置插帧
   * @param interpRotation 是否进行转向插帧
   */
  setF2FPercentage(
    percentage: number,
    kf1: IMovementKeyFrame,
    kf2: IMovementKeyFrame,
    interpPosition: boolean = true,
    interpRotation: boolean = true
  ): PersonState {
    if (percentage < 0.0 || percentage > 1.0) {
      console.error("setF2FPercentage参数错误。");
      throw Error("setF2FPercentage参数错误。");
    }

    const positionCurrent = kf1.position;
    const positionNext = kf2.position;
    const rotationCurrent = kf1.direction;
    const rotationNext = kf2.direction;
    const delta = positionNext.clone().sub(positionCurrent);

    const angle = new THREE.Vector2(delta.x, delta.y).angle();
    let rotation: THREE.Euler;
    if (
      rotationCurrent &&
      rotationNext &&
      interpRotation &&
      delta.length() > 3 * this.scale
    ) {
      // 进行方向插帧
      const rz =
        percentage < 0.5
          ? this.angleInterp(rotationCurrent.z, angle, percentage * 2)
          : this.angleInterp(angle, rotationNext.z, (percentage - 0.5) * 2);
      rotation = new THREE.Euler(0, 0, rz);
    } else if (rotationCurrent) {
      // 不插帧，使用rotationCurrent
      rotation = rotationCurrent;
    } else {
      // 不插帧，计算rotation
      rotation = new THREE.Euler(0, 0, angle);
    }
    const position = interpPosition
      ? positionCurrent.clone().add(delta.multiplyScalar(percentage))
      : positionCurrent;
    return new PersonState(position, rotation);
  }

  play(timing: number): ISuperPlayerMessage {
    this.maybeRollback(timing);

    const currentFrame = this.getCurrentKeyFrame(timing);
    const nextFrame = this.getNextKeyFrame();

    if (currentFrame) {
      if (KeyFrameTool.isFunctionalFrame(currentFrame)) {
        // 处理功能帧

        const functionalFrame = currentFrame as IFunctionalKeyFrame;
        if (functionalFrame.hide) {
          this.stateSetter.set(undefined, undefined, false);
        } else if (functionalFrame.remove) {
          this.stateSetter.set(undefined, undefined, false);
          return { shouldRemove: true };
        }
      } else if (KeyFrameTool.isMovementFrame(currentFrame)) {
        if (nextFrame && KeyFrameTool.isMovementFrame(nextFrame)) {
          // 本帧及下一帧均为位置关键帧，且插帧开关打开，需要插帧
          const percentage =
            (timing - currentFrame.timing) / currentFrame.length;
          const newState = this.setF2FPercentage(
            percentage,
            currentFrame as IMovementKeyFrame,
            nextFrame as IMovementKeyFrame,
            this.enableFrameInterpolation,
            this.enableFrameInterpolation
          );
          this.stateSetter.set(newState.position, newState.rotation, true);
        } else {
          // 无需插帧
          this.stateSetter.set(
            (currentFrame as IMovementKeyFrame).position,
            (currentFrame as IMovementKeyFrame).direction,
            true
          );
        }
        // if (currentFrame.userData)
        //     Object.assign(this.model.model.userData, currentFrame.userData);
      } else {
        console.error("无法识别帧类型。", currentFrame);
        throw Error("无法识别帧类型。");
      }
    }

    return {};
  }
}

class PersonStateSetter implements IPersonStateSetter {
  constructor(readonly ism: THREE.InstancedMesh, readonly index: number) {}

  set(position?: THREE.Vector3, rotation?: THREE.Euler, visible?: boolean) {
    let mat = new THREE.Matrix4();
    if (visible) {
      if (position) {
        mat = mat.multiply(
          new THREE.Matrix4().makeTranslation(
            position.x,
            position.y,
            position.z
          )
        );
        if (rotation) {
          mat = mat.multiply(
            new THREE.Matrix4().makeRotationFromEuler(rotation)
          );
        }
      }
    } else {
      mat.makeScale(0, 0, 0);
    }
    this.ism.setMatrixAt(this.index, mat);
    // console.debug("Set People index", this.index, position, rotation, visible);
  }
}

export class PeopleCrowdPlayer implements ICrowdPlayer {
  private peoplePlayers: {
    [carId: string]: { player: PeoplePlayer; firstAppearTiming: number };
  } = {};
  private isInitialized: boolean = false;
  public readonly scene: THREE.Scene = new THREE.Scene();
  private ism: THREE.InstancedMesh;
  private superScene: THREE.Scene;
  private lastTiming: number = -Infinity;
  private enableFrameInterpolation: boolean = true;
  private nextIsmIndex = 0;

  constructor(
    superScene: THREE.Scene,
    private readonly scale: number,
    public readonly countMax = 50000
  ) {
    this.superScene = superScene;
    this.ism = this.getIsm(countMax);
  }

  private getIsm(countMax: number) {
    const geometry = new THREE.CircleBufferGeometry(0.5 * this.scale);
    const material = new THREE.MeshBasicMaterial({
      side: THREE.DoubleSide,
      color: new THREE.Color(0xff0000),
      transparent: true,
    });
    return new THREE.InstancedMesh(geometry, material, countMax);
  }

  public async init() {
    if (this.isInitialized) {
      console.warn(
        "Trying to re-initialize PeopleCrowdPlayer takes no effect."
      );
      return;
    }
    this.scene.add(this.ism);
    this.superScene.add(this.scene);
    this.isInitialized = true;
  }

  public appendKeyFrames(keyFrames: { [peopleId: string]: IKeyFrame }) {
    Object.entries(keyFrames).forEach(([peopleId, keyFrame]) => {
      // console.log("people id ", peopleId);
      if (!this.peoplePlayers.hasOwnProperty(peopleId)) {
        // console.log("people players:" ,Object.keys(this.peoplePlayers).length, "index", this.nextIsmIndex);
        const ismIndex = this.nextIsmIndex;
        if (ismIndex < this.countMax) {
          this.peoplePlayers[peopleId] = {
            player: new PeoplePlayer(
              peopleId,
              new PersonStateSetter(this.ism, ismIndex),
              this.scale
            ),
            firstAppearTiming: keyFrame.timing,
          };
          this.nextIsmIndex += 1;
        } else {
          console.warn("people count exceed countMax", this.countMax, peopleId);
        }
      }
      if (this.peoplePlayers.hasOwnProperty(peopleId)) {
        this.peoplePlayers[peopleId].player.enqueueKeyFrame(keyFrame);
      }
    });
  }

  public play(timing: number) {
    if (!this.isInitialized) {
      throw Error("Call init() first before playing.");
    }
    Object.entries(this.peoplePlayers).forEach(
      ([peopleId, { player: peoplePlayer, firstAppearTiming }]) => {
        if (timing >= firstAppearTiming) {
          // 插帧开关检测
          if (
            peoplePlayer.enableFrameInterpolation !==
            this.enableFrameInterpolation
          ) {
            peoplePlayer.enableFrameInterpolation =
              this.enableFrameInterpolation;
          }
          const superPlayerMessage: ISuperPlayerMessage =
            peoplePlayer.play(timing);
          if (superPlayerMessage.shouldRemove) {
            // 收到移除消息，移除。
            delete this.peoplePlayers[peopleId];
          }
        } else {
          peoplePlayer.stateSetter.set(undefined, undefined, false);
        }
      }
    );
    this.ism.instanceMatrix.needsUpdate = true;
    this.lastTiming = timing;
  }

  clear() {
    this.lastTiming = -Infinity;
    Object.keys(this.peoplePlayers).forEach((peopleId) => {
      delete this.peoplePlayers[peopleId];
    });
    this.scene.remove(this.ism);
    this.ism = this.getIsm(this.countMax);
    this.nextIsmIndex = 0;
    this.scene.add(this.ism);
  }

  toggleFrameInterp(toggle: boolean) {
    this.enableFrameInterpolation = toggle;
  }
}
