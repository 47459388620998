import mapboxgl from "mapbox-gl";
import axios from "axios";

import {StatefulGeoLayer} from "./GeoLayer";
import {
    Feature,
    IStatefulGeoSource, StatefulGeoSource,
} from "../../../core/utils/StatefulGeoSource";
import {BaseStationState, CommState, CommType} from "../../../core/GeoPropDefs";
import {FilterComponent} from "../BaseCustomLayer";

export class BaseStationLayer extends StatefulGeoLayer<BaseStationState> {

    filterComponent?: FilterComponent;

    async init(map: mapboxgl.Map): Promise<void> {
        await super.init(map);
        this.filterComponent = new FilterComponent(
            map,
            this.id,
            {
                typeFilter: ['in', ['get', 'type'], ['literal', [1, 2, 3]]],
                stateFilter: ['boolean', true]
            }
        );
        await this.statefulSource!.setFeatures(
            axios.get('/comm_topo_geojson.json').then(resp => (resp.data as Feature[]))
        );

        // map.loadImage("/images/comm/基站-正常.png", (err, image) => {
        //     if (err) throw err;
        //     this.map!.addImage("BaseStation", image!);
        // });
    }

    getLayerDef(): mapboxgl.AnyLayer {
        return {
            type: "symbol",
            layout: {
                "icon-allow-overlap": false,
                "icon-image": [
                    "match",
                    ["number", ["get", "type"], CommType.UNKNOWN],
                    CommType.INTERNET,
                    [
                        "match",
                        ["get", "state"],
                        CommState.NORMAL,
                        "基站-正常",
                        CommState.DIRECT,
                        "基站-直流",
                        CommState.BROKEN,
                        "基站-不可工作",
                        "基站-正常",
                    ],
                    CommType.BS,
                    [
                        "match",
                        ["get", "state"],
                        CommState.NORMAL,
                        "基站-正常",
                        CommState.DIRECT,
                        "基站-直流",
                        CommState.BROKEN,
                        "基站-不可工作",
                        "基站-正常",
                    ],
                    CommType.GW,
                    [
                        "match",
                        ["get", "state"],
                        CommState.NORMAL,
                        "网关-正常",
                        CommState.DIRECT,
                        "网关-直流",
                        CommState.BROKEN,
                        "网关-不可工作",
                        "网关-正常",
                    ],
                    "gateway",
                ],
                "icon-size": [
                    "match",
                    ["number", ["get", "type"], CommType.INTERNET],
                    CommType.INTERNET,
                    0.3,
                    CommType.GW,
                    0.2,
                    CommType.BS,
                    0.1,
                    0.1,
                ],
            },
        } as any;
    }

    async getStatefulSource(): Promise<IStatefulGeoSource<BaseStationState>> {
        return new StatefulGeoSource<BaseStationState>(
            this.map!, this.id, 'id', {state: CommState.NORMAL}
        );
    }

    filter(filter: number[]) {
        if (filter.length > 0) {
            this.filterComponent!.filters.typeFilter = ['in', ['get', 'type'], ['literal', filter]];
        } else {
            this.filterComponent!.filters.typeFilter = ['boolean', true];
        }
        this.filterComponent!.applyFilters();
    }

    toggleBreakDown(toggle: boolean) {
        if (toggle) {
            this.map!.setLayoutProperty(this.id, "icon-allow-overlap", true);
            this.filterComponent!.filters.stateFilter = ['!=', ['get', 'state'], CommState.NORMAL];
        } else {
            this.map!.setLayoutProperty(this.id, "icon-allow-overlap", false);
            this.filterComponent!.filters.stateFilter = ['boolean', true];
        }
        this.filterComponent!.applyFilters();
    }
}