import { Map } from "mapbox-gl";
import { AllAoiPlayer } from "../../core/player/Aoi";
import { PlayerManager } from "../../core/player/PlayerManager";

import { BaseCustomLayer } from "./BaseCustomLayer";
import { RoadKeyFrameFactory } from "./FrameFactories";
import { AoiReader } from "./GeoJsonReader";

export interface IAoiReader {
  get(): Promise<any>;
}

export class AoiLayer extends BaseCustomLayer {
  map: any;
  index: number;

  aoiReader: IAoiReader;
  aoiPlayer: AllAoiPlayer | undefined;

  data: { lineData: any; pointData: any } = { lineData: [], pointData: [] };

  widthScale: number = 1.0;

  initialized: boolean = false;

  player: PlayerManager;
  keyFrameFactory = new RoadKeyFrameFactory();
  subLayerIdToLocalVisibility = {
    aoi_heatmap: true,
  }

  constructor(
    id: string,
    index: number,
    props: {
      playerManager: PlayerManager;
      aoiReader: AoiReader;
    }
  ) {
    super(id, "3d", props);
    this.index = index;
    this.player = props.playerManager;

    this.aoiReader = props.aoiReader;
  }

  async init(map: Map) {
    this.map = map;

    this.data = await this.aoiReader.get();

    this.map.addSource("aoi_point", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: this.data.pointData,
      },
    });

    this.map.addLayer({
      id: "aoi_heatmap",
      type: "heatmap",
      maxzoom: 14,
      source: "aoi_point",
      paint: {
        // Increase the heatmap weight based on frequency and property magnitude
        "heatmap-weight": [
          "interpolate",
          ["linear"],
          ["get", "density"],
          0,
          0,
          100000,
          1,
        ],
        // Increase the heatmap color weight weight by zoom level
        // heatmap-intensity is a multiplier on top of heatmap-weight
        "heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 9, 1, 16, 3],
        // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
        // Begin color ramp at 0-stop with a 0-transparancy color
        // to create a blur-like effect.
        // "heatmap-color": [
        //   "interpolate",
        //   ["linear"],
        //   ["heatmap-density"],
        //   0,
        //   "rgba(33,102,172,0)",
        //   0.2,
        //   "rgb(103,169,207)",
        //   0.4,
        //   "rgb(209,229,240)",
        //   0.6,
        //   "rgb(253,219,199)",
        //   0.8,
        //   "rgb(239,138,98)",
        //   1,
        //   "rgb(178,24,43)",
        // ],
        // Adjust the heatmap radius by zoom level
        "heatmap-radius": [
          "interpolate",
          ["linear"],
          ["zoom"],
          9,
          8,
          12,
          20,
          14,
          40,
          18,
          100,
        ],
        // Transition from heatmap to circle layer by zoom level
        "heatmap-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          12,
          0.7,
          18,
          0.3,
        ],
      },
    });

    const allAoiPlayer = new AllAoiPlayer(this.map, this.data);
    allAoiPlayer.init();
    this.aoiPlayer = allAoiPlayer;

    this.player.attachSubCrowdPlayer(`aoi_${this.index}`, allAoiPlayer);

    console.log("AoiPlayer initialized.");
    this.initialized = true;
    this.setLayerVisibility(false);
  }
}
