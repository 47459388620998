import * as THREE from "three";
import mapboxgl from "mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { BaseCustomLayer } from "./BaseCustomLayer";
import { CoordinateTransformer } from "../../core/utils/coordutils";
import { CarCrowdPlayer, ModelConfig } from "../../core/player/Car";
import { PlayerManager } from "../../core/player/PlayerManager";
import { PeopleCrowdPlayer } from "../../core/player/People";
import {
  AllRoadPlayer,
  IRoadConfig,
  IRoadMapReader,
} from "../../core/player/Road";

export type CarInfo = {
  carId: string | null;
  position: { lat: number; lng: number } | null;
  laneId: string | null;
  description?: string;
};

class CarLayer extends BaseCustomLayer {
  transformer: CoordinateTransformer;
  models: { [carId: string]: ModelConfig };
  mouse: { inside: boolean; point: THREE.Vector3 } = {
    inside: false,
    point: new THREE.Vector3(),
  };

  private carCrowdPlayer?: CarCrowdPlayer;
  private peopleCrowdPlayer?: PeopleCrowdPlayer;
  private trafficLightPlayer?: AllRoadPlayer;
  private enableFrameInterpolation: boolean = true;
  player: PlayerManager;
  onCarSelected?: (carInfo: CarInfo) => void;

  addFinishResolver?: () => void;

  addFinish: Promise<void> = new Promise((resolve) => {
    this.addFinishResolver = resolve;
  });
  camera?: THREE.Camera;
  scene?: THREE.Scene;
  playerScene?: THREE.Scene;
  renderer?: THREE.WebGLRenderer;
  map?: any;
  marker: mapboxgl.Marker = new mapboxgl.Marker({ color: "#FF0000" });
  lastSelected?: THREE.Object3D;
  trySelectCarId?: string;
  index: number;

  roadConfig: IRoadConfig;
  stateColor = {
    0: new THREE.Color(0xffffff),
    1: new THREE.Color(0xff0000),
    2: new THREE.Color(0x00ff00),
    3: new THREE.Color(0xffff00),
  };

  constructor(
    id: string,
    index: number,
    props: {
      transformer: CoordinateTransformer;
      playerManager: PlayerManager;
      onCarSelected?: (carInfo: CarInfo) => void;
      crossLaneMapReader: IRoadMapReader;
    }
  ) {
    super(id, "3d", props);
    this.subLayerIdToLocalVisibility[id] = true;
    this.index = index;

    this.transformer = props.transformer;
    this.player = props.playerManager;
    this.onCarSelected = props.onCarSelected;
    this.models = {
      "1": new ModelConfig(
        "/models/cars/car_mini_red.gltf",
        new THREE.Vector3(
          this.transformer.scale,
          -this.transformer.scale,
          this.transformer.scale
        ),
        new THREE.Euler(0, 0)
      ),
      "2": new ModelConfig(
        "/models/cars/car_normal_red.gltf",
        new THREE.Vector3(
          this.transformer.scale,
          -this.transformer.scale,
          this.transformer.scale
        ),
        new THREE.Euler(0, 0)
      ),
      "3": new ModelConfig(
        "/models/cars/car_normal_white01.gltf",
        new THREE.Vector3(
          this.transformer.scale,
          -this.transformer.scale,
          this.transformer.scale
        ),
        new THREE.Euler(0, 0)
      ),
      "4": new ModelConfig(
        "/models/cars/car_police_black.gltf",
        new THREE.Vector3(
          this.transformer.scale,
          -this.transformer.scale,
          this.transformer.scale
        ),
        new THREE.Euler(0, 0)
      ),
      "5": new ModelConfig(
        "/models/cars/car_sport_blue.gltf",
        new THREE.Vector3(
          this.transformer.scale,
          -this.transformer.scale,
          this.transformer.scale
        ),
        new THREE.Euler(0, 0)
      ),
      "6": new ModelConfig(
        "/models/cars/car_sport_blue02.gltf",
        new THREE.Vector3(
          this.transformer.scale,
          -this.transformer.scale,
          this.transformer.scale
        ),
        new THREE.Euler(0, 0)
      ),
      "7": new ModelConfig(
        "/models/cars/car_sport_orange01.gltf",
        new THREE.Vector3(
          this.transformer.scale,
          -this.transformer.scale,
          this.transformer.scale
        ),
        new THREE.Euler(0, 0)
      ),
      "8": new ModelConfig(
        "/models/cars/car_sport_white.gltf",
        new THREE.Vector3(
          this.transformer.scale,
          -this.transformer.scale,
          this.transformer.scale
        ),
        new THREE.Euler(0, 0)
      ),
      "9": new ModelConfig(
        "/models/cars/car_super_black.gltf",
        new THREE.Vector3(
          this.transformer.scale,
          -this.transformer.scale,
          this.transformer.scale
        ),
        new THREE.Euler(0, 0)
      ),
      "10": new ModelConfig(
        "/models/cars/car_suv_white01.gltf",
        new THREE.Vector3(
          this.transformer.scale,
          -this.transformer.scale,
          this.transformer.scale
        ),
        new THREE.Euler(0, 0)
      ),
      "11": new ModelConfig(
        "/models/cars/bus_green01.gltf",
        new THREE.Vector3(
          this.transformer.scale,
          -this.transformer.scale,
          this.transformer.scale
        ),
        new THREE.Euler(0, 0)
      ),
      "12": new ModelConfig(
        "/models/cars/car_normal_darkgreen.gltf",
        new THREE.Vector3(
          this.transformer.scale,
          -this.transformer.scale,
          this.transformer.scale
        ),
        new THREE.Euler(0, 0)
      ),
      "13": new ModelConfig(
        "/models/cars/car_normal_green01.gltf",
        new THREE.Vector3(
          this.transformer.scale,
          -this.transformer.scale,
          this.transformer.scale
        ),
        new THREE.Euler(0, 0)
      ),
      "14": new ModelConfig(
        "/models/cars/car_normal_yellow01.gltf",
        new THREE.Vector3(
          this.transformer.scale,
          -this.transformer.scale,
          this.transformer.scale
        ),
        new THREE.Euler(0, 0)
      ),
      "15": new ModelConfig(
        "/models/cars/car_sport_yellow02.gltf",
        new THREE.Vector3(
          this.transformer.scale,
          -this.transformer.scale,
          this.transformer.scale
        ),
        new THREE.Euler(0, 0)
      ),
      "16": new ModelConfig(
        "/models/cars/truck_white01.gltf",
        new THREE.Vector3(
          this.transformer.scale,
          -this.transformer.scale,
          this.transformer.scale
        ),
        new THREE.Euler(0, 0)
      ),
    };
    this.roadConfig = {
      basicGeometry: new THREE.PlaneGeometry(1, 1),
      colorStateMap: this.stateColor,
      material: new THREE.MeshBasicMaterial({
        side: THREE.DoubleSide,
        color: new THREE.Color(0xffffff),
        transparent: true,
      }),
      roadMapReader: props.crossLaneMapReader,
      width: this.transformer.scale,
      defaultState: 0,
    };
  }

  async onAdd(map: any, gl: any) {
    this.camera = new THREE.PerspectiveCamera();
    const scene = (this.scene = new THREE.Scene());

    // create two three.js lights to illuminate the model
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.8);
    scene.add(ambientLight);

    const light = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.25);
    scene.add(light);
    this.map = map;
    this.map.on("mousemove", (e: any) => {
      // console.log(e.point, e.originalEvent.target.clientHeight, e.originalEvent.target.clientWidth);
      this.mouse.point = this.transformer.lngLatToPos(e.lngLat);
    });
    this.map.on("mouseover", () => {
      this.mouse.inside = true;
    });
    this.map.on("mouseout", () => {
      this.mouse.inside = false;
    });

    // use the Mapbox GL JS map canvas for three.js
    this.renderer = new THREE.WebGLRenderer({
      canvas: map.getCanvas(),
      context: gl,
      antialias: true,
    });

    this.renderer.autoClear = false;

    this.carCrowdPlayer = new CarCrowdPlayer(this.models, this.scene);
    this.peopleCrowdPlayer = new PeopleCrowdPlayer(
      this.scene,
      this.transformer.scale
    );
    this.trafficLightPlayer = new AllRoadPlayer(this.scene, this.roadConfig);

    await this.carCrowdPlayer.init();
    await this.peopleCrowdPlayer.init();
    await this.trafficLightPlayer.init();

    this.carCrowdPlayer.toggleFrameInterp(this.enableFrameInterpolation);
    this.playerScene = this.carCrowdPlayer.scene;
    this.player.attachSubCrowdPlayer(`car_${this.index}`, this.carCrowdPlayer);
    this.player.attachSubCrowdPlayer(
      `people_${this.index}`,
      this.peopleCrowdPlayer
    );
    this.player.attachSubCrowdPlayer(
      `trafficLight_${this.index}`,
      this.trafficLightPlayer
    );
    this.setLayerVisibility(false);

    this.addFinishResolver!();
  }

  selectCar(carId: string) {
    this.trySelectCarId = carId;
  }

  toggleFrameInterp(toggle: boolean) {
    this.enableFrameInterpolation = toggle;
    this.carCrowdPlayer?.toggleFrameInterp(this.enableFrameInterpolation);
  }

  placeMarkerAndSendSelectMessage() {
    // 发送选中消息
    if (this.onCarSelected && this.lastSelected) {
      const pos = this.transformer.posToLngLat(this.lastSelected!.position);
      this.marker.setLngLat(pos);
      this.marker.addTo(this.map);
      const carInfo: CarInfo = {
        carId: this.lastSelected.userData.carId,
        position: pos,
        laneId: this.lastSelected.userData.laneId,
        description: this.lastSelected.userData.description,
      };
      this.onCarSelected(carInfo);
    }
  }

  detectSelection() {
    // 物体选定探测
    if ((this.mouse.inside || this.trySelectCarId) && this.playerScene) {
      for (const i in this.playerScene.children) {
        const obj = this.playerScene.children[i];
        if (
          obj.visible &&
          ((this.trySelectCarId &&
            String(obj.userData.carId) === this.trySelectCarId) ||
            (this.mouse.inside &&
              obj.position.clone().sub(this.mouse.point).length() /
                this.transformer.scale <
                2 &&
              this.lastSelected !== obj))
        ) {
          if (this.trySelectCarId === obj.userData.carId)
            this.trySelectCarId = undefined;
          this.lastSelected = obj;
          break;
        }
      }
    }
    if (!this.lastSelected?.parent) {
      this.lastSelected = undefined;
      if (this.onCarSelected)
        this.onCarSelected({ carId: null, position: null, laneId: null });
      this.marker.remove();
    }
    this.placeMarkerAndSendSelectMessage();
  }

  render(gl: any, matrix: ArrayLike<number>) {
    if (this.camera && this.renderer && this.scene) {
      const m = new THREE.Matrix4().fromArray(matrix);

      this.camera.projectionMatrix = m.multiply(
        this.transformer.translationMatrix
      );

      this.detectSelection();

      this.renderer.resetState();
      this.renderer.render(this.scene, this.camera);
      this.map.triggerRepaint();
    }
  }
}

export default CarLayer;
