import { IRoadMapReader, Road } from "../../core/player/Road";
import { IWaterReader } from "./WaterLayer";
import { IAoiReader } from "./AoiLayer";
import axios from "axios";
import { CoordinateTransformer } from "../../core/utils/coordutils";
import { LngLatLike } from "mapbox-gl";
import {CommState, ElecState, WaterState} from "../../core/GeoPropDefs";

export interface IReader {
  get(): Promise<any>;
}

export class RoadReader implements IRoadMapReader {
  path: string;
  geoJsonData: any;
  transformer: CoordinateTransformer;

  constructor(path: string, transformer: CoordinateTransformer) {
    this.path = path;
    this.transformer = transformer;
  }

  async next(): Promise<Road | null> {
    if (!this.geoJsonData) {
      this.geoJsonData = (await axios.get(`${this.path}.json`)).data;
    }
    const road = this.geoJsonData.shift();
    if (road) {
      return {
        id: road.properties.id,
        points: road.geometry.coordinates.map((p: LngLatLike) =>
          this.transformer.lngLatToPos(p)
        ),
      };
    } else {
      return null;
    }
  }
}

export class ElecReader implements IReader {
  path: string;

  constructor(path: string) {
    this.path = path;
  }

  async get(): Promise<any> {
    const geoJsonData = (await axios.get(this.path)).data;
    let lineData = [];
    let pointData = [];

    for (let d of geoJsonData as any[]) {
      if (d.geometry.type === "LineString") {
        lineData.push(d);
      } else if (d.geometry.type === "Point") {
        d.properties.state = ElecState.NORMAL;
        pointData.push(d);
      }
    }
    return { lineData, pointData };
  }
}

export class CommReader implements IReader {
  path: string;

  constructor(path: string) {
    this.path = path;
  }

  async get(): Promise<any> {
    const geoJsonData = (await axios.get(this.path)).data;
    let lineData = [];
    let pointData = [];

    for (let d of geoJsonData as any[]) {
      if (d.geometry.type === "LineString") {
        lineData.push(d);
      } else if (d.geometry.type === "Point") {
        d.properties.state = CommState.NORMAL;
        pointData.push(d);
      }
    }
    return { lineData, pointData };
  }
}

export class WaterReader implements IWaterReader {
  path: string;

  constructor(path: string) {
    this.path = path;
  }

  async get(): Promise<any> {
    const geoJsonData = (await axios.get(this.path)).data;
    let lineData = [];
    let pointData = [];

    for (let d of geoJsonData as any[]) {
      if (d.geometry.type === "LineString") {
        d.properties.state = WaterState.NORMAL; //0 means good
        lineData.push(d);
      } else if (d.geometry.type === "Point") {
        pointData.push(d);
      }
    }
    return { lineData, pointData };
  }
}

export class AoiReader implements IAoiReader {
  path: string;
  data: any = undefined;

  constructor(path: string) {
    this.path = path;
  }

  async get(): Promise<any> {
    const geoJsonData = (await axios.get(this.path)).data;
    let lineData = [];
    let pointData = [];
    if (this.data) {
      lineData = this.data.lineData;
      pointData = this.data.pointData;
    } else {
      for (let d of geoJsonData as any[]) {
        if (d.geometry.type === "LineString") {
          d.properties.state = 0; //0 means good
          lineData.push(d);
        } else if (d.geometry.type === "Point") {
          d.properties.density = 6;
          pointData.push(d);
        }
      }
    }
    if (!this.data) {
      this.data = { lineData, pointData };
    }
    return { lineData, pointData };
  }
}

export class RoadCenterReader implements IReader {
  path: string;

  constructor(path: string) {
    this.path = path;
  }

  async get(): Promise<any> {
    const geoJsonData = (await axios.get(this.path)).data;
    let pointData = [];
    for (let d of geoJsonData as any[]) {
      if (d.geometry.type === "Point") {
        pointData.push(d);
      }
    }
    return pointData;
  }
}

export class JsonReader implements IAoiReader {
  path: string;
  data: any = undefined;

  constructor(path: string) {
    this.path = path;
  }

  async get(): Promise<any> {
    let data: any;
    if (this.data) {
      data = this.data;
    } else {
      data = (await axios.get(this.path)).data;
    }
    if (!this.data) {
      this.data = data;
    }
    return data;
  }
}
