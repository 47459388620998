/* eslint-disable no-useless-concat */
import ReactECharts from "echarts-for-react";
import { List, Tabs, Progress, Avatar, Row, Col } from "antd";
import moment from "moment";

import React, { useEffect, useRef, useState } from "react";
import { PlayerManager } from "../../core/player/PlayerManager";
import {
    WaterLinkMetricPlayer,
    RoadMetricPlayer,
} from "../../core/player/Statistics";

import { RoadDetailPlayer } from "../../core/player/RoadDetailPlayer"
import { AoiDetailPlayer } from "../../core/player/AoiDetailPlayer";
import {
    AllElecData,
    AllWaterData,
    AllTrafficData,
    AllCommData,
    CtiyOverviewData,
    CityRuntimeData,
    RoadDetailStat,
    RoadDetailStatInitial,
    AoiDetailStat,
    AoiDetailStatInitial
} from "./type";
import "./index.css";

export const EventEcharts = ({ data }: { data: any }) => {
    const events = ["", "电网", "水网", "交通", "环境"];
    const sizeMap = [0, 10, 20, 25];
    const colorMap = [
        "#A6142799",
        "#3B6DAA99",
        "#6AA5C799",
        "#D76DA499",
        "#F2994A99",
    ];

    const options = {
        title: {
            text: "关键事件告警",
            textStyle: {
                fontSize: 22,
            },
        },
        tooltip: {
            show: false,
            trigger: "axis",
        },
        toolbox: {},
        xAxis: {
            // data: a,
            type: "value",
            max: 1000,
            min: 500,
            axisLine: { show: true },
            axisLabel: {
                formatter: function (data: number) {
                    return moment("00:00:00", "HH:mm:ss")
                        .add(data, "seconds")
                        .format("HH:mm");
                },
            },
        },
        yAxis: {
            type: "category",
            data: events,
            name: "类型",
        },
        grid: { left: "12%" },
        series: [],
    };
    const optionsString = JSON.stringify(options);

    const refEcharts = useRef<ReactECharts>(null);
    const [chart_options, setOptions] = useState<any>(options);
    useEffect(() => {
        if (data) {
            const newOptions = JSON.parse(optionsString);
            let new_series: any = [];

            for (let i = 0; i < events.length; i++) {
                new_series.push({
                    data: [
                        [data.x[0], events[i]],
                        [data.x[1], events[i]],
                    ],
                    type: "line",
                    showSymbol: false,
                    lineStyle: {
                        width: 5, //设置线条粗细
                        color: "#cccccc66",
                    },
                });
            }
            for (let p of data.points) {
                new_series.push({
                    symbolSize: sizeMap[p.size],
                    symbol: "roundRect",
                    itemStyle: {
                        color: colorMap[p.point[1]],
                    },
                    data: [[p.point[0], events[p.point[1]]]],
                    type: "scatter",
                    label: {
                        show: true,
                        formatter: function (param: any) {
                            return p.label;
                        },
                        minMargin: 10,
                        position: "top",
                    },
                    zlevel: 3,
                });
            }
            for (let l of data.lines) {
                new_series.push({
                    data: l.map((point: any) => {
                        return [point[0], events[point[1]]];
                    }),
                    type: "line",
                    showSymbol: false,
                    lineStyle: {
                        width: 2, //设置线条粗细
                        color: "#b5316b",
                    },
                });
            }

            newOptions.series = new_series;
            newOptions.xAxis.min = data.x[0];
            newOptions.xAxis.max = data.x[1];
            setOptions(newOptions);
        }
    }, [JSON.stringify(data), optionsString]);
    return (
        <ReactECharts
            option={chart_options}
            ref={refEcharts}
            style={{ width: "95%", height: "300px", marginTop: 20 }}
        />
    );
};

export const RoadMetricEcharts = ({ data }: { data: any[] }) => {
    const options = {
        title: {
            text: "城市拥堵路段数",
            textStyle: {
                fontSize: 16,
            },
        },
        color: ["#6AA5C7", "#3B6DAA", "#D76DA4", "#a61427"],
        tooltip: {
            trigger: "axis",
        },
        legend: {
            top: 20,
            right: 20,
            data: ["轻度拥堵", "中度拥堵", "重度拥堵", "极端拥堵"],
        },

        toolbox: {
            //   feature: {
            //     saveAsImage: {},
            //   },
        },
        xAxis: {
            data: [23, 24, 25, 26, 27],
            axisLabel: {
                formatter: function (data: number) {
                    return moment("00:00:00", "HH:mm:ss")
                        .add(data, "seconds")
                        .format("HH:mm");
                },
            },
        },
        yAxis: {
            type: "value",
            name: "数量",
            max: 5000,
        },
        grid: { left: "15%" },
        series: [
            {
                name: "轻度拥堵",
                type: "line",
                smooth: true,
                data: [100, 100, 100, 100, 100],
            },
            {
                name: "中度拥堵",
                type: "line",
                smooth: true,
                data: [5000, 500, 5000, 5000, 5000],
            },
            {
                name: "重度拥堵",
                type: "line",
                smooth: true,
                data: [2500, 2500, 2500, 2500, 2500],
            },
            {
                name: "极端拥堵",
                type: "line",
                smooth: true,
                data: [2500, 2500, 2500, 2500, 2500],
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      const newOptions = JSON.parse(optionsString);
      newOptions.xAxis.data = data.map((item) => item.step);
      newOptions.series[0].data = data.map((item) =>
        item.levelCount["2"] > 5000 ? 5000 : item.levelCount["2"]
      );
      newOptions.series[1].data = data.map((item) =>
        item.levelCount["3"] > 5000 ? 5000 : item.levelCount["3"]
      );
      newOptions.series[2].data = data.map((item) =>
        item.levelCount["4"] > 5000 ? 5000 : item.levelCount["4"]
      );
      newOptions.series[3].data = data.map((item) =>
        item.levelCount["5"] > 5000 ? 5000 : item.levelCount["5"]
      );

            setOptions(newOptions);
        }
    }, [JSON.stringify(data), optionsString]);
    return (
        <ReactECharts
            option={chart_options}
            ref={refEcharts}
            style={{ width: "95%", height: "270px", margin: "0 auto" }}
        />
    );
};

export const CommMetricEcharts = ({ data }: { data: any[] }) => {
    const options = {
        title: {
            text: "通信需求及承载",
            top: -5,
            textStyle: {
                fontSize: 16,
            },
        },
        color: ["#BA9DC9", "#A61427"],
        legend: {
            top: 20,
            right: 20,
            data: ["用户通信需求总流量", "通信网络承载总流量"],
        },

        xAxis: {
            data: [23, 24, 25, 26, 27],
            axisLabel: {
                formatter: function (data: number) {
                    return moment("00:00:00", "HH:mm:ss")
                        .add(data, "seconds")
                        .format("HH:mm");
                },
            },
        },
        yAxis: {
            type: "value",
            name: "GB",
            max: 10000,
        },
        grid: { left: "15%" },
        series: [
            {
                name: "用户通信需求总流量",
                type: "line",
                smooth: true,
                data: [100, 100, 100, 100, 100],
            },
            {
                name: "通信网络承载总流量",
                type: "line",
                smooth: true,
                data: [5000, 500, 5000, 5000, 5000],
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);

    const refEcharts = useRef<ReactECharts>(null);
    useEffect(() => {
        if (data) {
            const newOptions = JSON.parse(optionsString);
            newOptions.xAxis.data = data.map((item) => item.step);
            newOptions.series[0].data = data.map((item) =>
                (item.demand / 1000000000).toFixed(2)
            );
            newOptions.series[1].data = data.map((item) =>
                (item.actual / 1000000000).toFixed(2)
            );

            setOptions(newOptions);
        }
    }, [JSON.stringify(data), optionsString]);
    return (
        <ReactECharts
            option={chart_options}
            ref={refEcharts}
            style={{ width: "95%", height: "270px", marginTop: 20 }}
        />
    );
};

export const MultiProgressBar = ({ data }: { data: CityRuntimeData }) => {
  const [v, setV] = useState<CityRuntimeData>();
  useEffect(() => {
    if (data) {
      setV(data);
    }
  }, [data]);
  return (
    <>
      <div
        style={{
          fontSize: "22px",
          fontWeight: 500,
          textAlign: "left",
          marginLeft: 10,
        }}
      >
        城市需求满足情况
      </div>
      <div>
        <div style={{ float: "left", marginLeft: 10 }}>
          <span
            style={{
              fontSize: "16px",
              color: "#3462a0",
              fontWeight: "600",
              marginRight: 10,
            }}
          >
            交通
          </span>
          <span
            style={{
              fontSize: "16px",
              color: "#5b5c5b",
              marginRight: 10,
            }}
          >
            居民需求
          </span>
          <span
            style={{
              fontSize: "22px",
              color: "#3462a0",
              fontWeight: "600",
            }}
          >
            {` ${(v ? v.demand.trafficPerson * 100 : 0).toFixed(0)}%`}
          </span>
        </div>
        <Progress
          percent={v ? v.demand.trafficPerson * 100 : 0}
          style={{ width: "95%" }}
          showInfo={false}
          strokeColor={"#3462a0"}
        />
      </div>
      <div>
        <div style={{ float: "left", marginLeft: 10 }}>
          <span
            style={{
              fontSize: "16px",
              color: "#ba9dc9",
              fontWeight: "600",
              marginRight: 10,
            }}
          >
            通信
          </span>
          <span
            style={{
              fontSize: "16px",
              color: "#5b5c5b",
              marginRight: 10,
            }}
          >
            居民需求
          </span>
          <span
            style={{
              fontSize: "22px",
              color: "#ba9dc9",
              fontWeight: "600",
            }}
          >
            {` ${(v ? v.demand.commPerson * 100 : 0).toFixed(0)}%`}
          </span>
        </div>
        <Progress
          percent={v ? v.demand.commPerson * 100 : 0}
          style={{ width: "95%" }}
          showInfo={false}
          strokeColor={"#ba9dc9"}
        />
      </div>
      <div>
        <div style={{ float: "left", marginLeft: 10 }}>
          <span
            style={{
              fontSize: "16px",
              color: "#6aa5c7",
              fontWeight: "600",
              marginRight: 10,
            }}
          >
            用水
          </span>
          <span
            style={{
              fontSize: "16px",
              color: "#5b5c5b",
              marginRight: 10,
            }}
          >
            居民需求
          </span>
          <span
            style={{
              fontSize: "22px",
              color: "#6aa5c7",
              fontWeight: "600",
            }}
          >
            {` ${(v ? v.demand.waterPerson * 100 : 0).toFixed(0)}%`}
          </span>
          <span
            style={{
              fontSize: "16px",
              color: "#5b5c5b",
              marginLeft: 10,
            }}
          >
            AOI需求
          </span>
          <span
            style={{
              fontSize: "22px",
              color: "#6aa5c7",
              fontWeight: "600",
            }}
          >
            {` ${(v ? v.demand.waterAoi * 100 : 0).toFixed(0)}%`}
          </span>
        </div>
        <Progress
          percent={v ? v.demand.waterPerson * 100 : 0}
          style={{ width: "95%" }}
          showInfo={false}
          strokeColor={"#6aa5c7"}
        />
        <Progress
          percent={v ? v.demand.waterAoi * 100 : 0}
          style={{ width: "95%" }}
          showInfo={false}
          strokeColor={"#6aa5c7"}
        />
      </div>
      <div>
        <div style={{ float: "left", marginLeft: 10 }}>
          <span
            style={{
              fontSize: "16px",
              color: "#d76da4",
              fontWeight: "600",
              marginRight: 10,
            }}
          >
            用电
          </span>
          <span
            style={{
              fontSize: "16px",
              color: "#5b5c5b",
              marginRight: 10,
            }}
          >
            居民需求
          </span>
          <span
            style={{
              fontSize: "22px",
              color: "#d76da4",
              fontWeight: "600",
            }}
          >
            {` ${(v ? v.demand.elecPerson * 100 : 0).toFixed(0)}%`}
          </span>
          <span
            style={{
              fontSize: "16px",
              color: "#5b5c5b",
              marginLeft: 10,
            }}
          >
            AOI需求
          </span>
          <span
            style={{
              fontSize: "22px",
              color: "#d76da4",
              fontWeight: "600",
            }}
          >
            {` ${(v ? v.demand.elecAoi * 100 : 0).toFixed(0)}%`}
          </span>
        </div>
        <Progress
          percent={v ? v.demand.elecPerson * 100 : 0}
          style={{ width: "95%" }}
          showInfo={false}
          strokeColor={"#d76da4"}
        />
        <Progress
          percent={v ? v.demand.elecAoi * 100 : 0}
          style={{ width: "95%" }}
          showInfo={false}
          strokeColor={"#d76da4"}
        />
      </div>
    </>
  );
};

export const CommProgressBar = ({ data }: { data: AllCommData }) => {
  const [v, setV] = useState<AllCommData>();
  useEffect(() => {
    if (data) {
      setV(data);
    }
  }, [data]);
  return (
    <>
      <div>
        <div style={{ float: "left", marginLeft: 10 }}>
          <span
            style={{
              fontSize: "17px",
              color: "#5b5c5b",
            }}
          >
            通信需求满足：
          </span>
          <span
            style={{
              fontSize: "25px",
              color: "#BA9DC9",
              fontWeight: "600",
            }}
          >
            {`${((v ? v.demand.satisfiedRatio : 0) * 100).toFixed(0)}%`}
          </span>
        </div>
        <Progress
          percent={(v ? v.demand.satisfiedRatio : 0) * 100}
          style={{ width: "95%" }}
          showInfo={false}
          strokeColor={"#BA9DC9"}
        />
      </div>
      <div>
        <Row>
          <Col span={12}>
            <div>通信需求未满足人数</div>
            <div>
              <span className="text-red">{`${v ? (v.demand.numUnsatisfiedAgent / 10000).toFixed(2) : 0
                }`}</span>
              万人
            </div>
          </Col>
          <Col span={12}>
            <div>通信总活跃用户数</div>
            <div>
              <span className="text-blue">{`${v ? (v.demand.numActiveAgent / 10000).toFixed(2) : 0
                }`}</span>
              万人
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export const CityLandRadar = () => {
    const options = {
        title: [
            {
                top: -5,
                text: "各区土地面积",
                textStyle: {
                    fontSize: 16,
                },
            },
        ],
        tooltip: {
            trigger: "item",
        },

        radar: {
            indicator: [
                { name: "朝阳", max: 500 },
                { name: "东城", max: 180 },
                { name: "丰台", max: 500 },
                { name: "海淀", max: 500 },
                { name: "石景山", max: 180 },
                { name: "西城", max: 180 },
            ],
        },
        series: [
            {
                name: "面积",
                type: "radar",
                tooltip: {
                    trigger: "axis",
                },
                areaStyle: { color: "#3B6DAA99" },
                lineStyle: { color: "#3B6DAA" },
                itemStyle: { color: "#3B6DAA" },
                data: [
                    {
                        value: [455, 42, 306, 431, 84, 51],
                        name: "土地面积",
                        label: {
                            show: true,
                            formatter: function (params: any) {
                                return `${params.value}km²`;
                            },
                            // color: "#3B6DAA",
                            fontWeight: 500,
                            fontSize: 16,
                            shadowColor: "white",
                        },
                    },
                ],
            },
        ],
    };
    const [chart_options, setOptions] = useState<any>(options);

    const refEcharts = useRef<ReactECharts>(null);

    return (
        <ReactECharts
            option={chart_options}
            ref={refEcharts}
            style={{ width: "95%", height: "250px", margin: "0 auto" }}
        />
    );
};

export const CityPeopleRadar = ({ data }: { data: CtiyOverviewData }) => {
    const options = {
        animation: false,
        title: {
            top: -5,
            text: "各区人口数量",
            textStyle: {
                fontSize: 16,
            },
        },
        tooltip: {
            trigger: "item",
        },

        radar: {
            indicator: [
                { name: "朝阳", max: 480 },
                { name: "东城", max: 200 },
                { name: "丰台", max: 300 },
                { name: "海淀", max: 300 },
                { name: "石景山", max: 480 },
                { name: "西城", max: 200 },
            ],
        },
        series: [
            {
                name: "人口数量",
                type: "radar",
                tooltip: {
                    trigger: "item",
                },
                areaStyle: { color: "#3B6DAA99" },
                lineStyle: { color: "#3B6DAA" },
                itemStyle: { color: "#3B6DAA" },
                data: [
                    {
                        value: [200, 100, 100, 150, 300, 200],
                        name: "人口数量",
                        label: {
                            show: true,
                            formatter: "{c}万人",
                            // color: "#3B6DAA",
                            fontWeight: 500,
                            fontSize: 16,
                            shadowColor: "white",
                        },
                    },
                ],
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);

    const refEcharts = useRef<ReactECharts>(null);
    useEffect(() => {
        if (data) {
            const newOptions = JSON.parse(optionsString);
            newOptions.series[0].data[0].value = [
                (data.chaoyang / 10000).toFixed(1),
                (data.dongcheng / 10000).toFixed(1),
                (data.fengtai / 10000).toFixed(1),
                (data.haidian / 10000).toFixed(1),
                (data.shijingshan / 10000).toFixed(1),
                (data.xicheng / 10000).toFixed(1),
            ];
            setOptions(newOptions);
        }
    }, [JSON.stringify(data), optionsString]);
    return (
        <ReactECharts
            option={chart_options}
            ref={refEcharts}
            style={{ width: "95%", height: "250px", margin: "0 auto" }}
        />
    );
};

export const CityAOIRadar = () => {
    const options = {
        title: [
            {
                top: -5,
                text: "各区AOI数量",
                textStyle: {
                    fontSize: 16,
                },
            },
        ],
        tooltip: {
            trigger: "item",
        },

        radar: {
            indicator: [
                { name: "朝阳", max: 10000 },
                { name: "东城", max: 10000 },
                { name: "丰台", max: 10000 },
                { name: "海淀", max: 10000 },
                { name: "石景山", max: 10000 },
                { name: "西城", max: 10000 },
            ],
        },
        series: [
            {
                name: "AOI数量",
                type: "radar",
                tooltip: {
                    trigger: "axis",
                },
                areaStyle: { color: "#3B6DAA99" },
                lineStyle: { color: "#3B6DAA" },
                itemStyle: { color: "#3B6DAA" },
                data: [
                    {
                        value: [9197, 4725, 5230, 5810, 262, 5541],
                        name: "AOI数量",
                        label: {
                            show: true,
                            formatter: function (params: any) {
                                return `${params.value}个`;
                            },
                            // color: "#3B6DAA",
                            fontWeight: 500,
                            fontSize: 16,
                            shadowColor: "white",
                        },
                    },
                ],
            },
        ],
    };
    const [chart_options, setOptions] = useState<any>(options);

    const refEcharts = useRef<ReactECharts>(null);

    return (
        <ReactECharts
            option={chart_options}
            ref={refEcharts}
            style={{ width: "95%", height: "250px", margin: "0 auto" }}
        />
    );
};

export const RoadMetricRadar = ({ data }: { data: AllTrafficData }) => {
    const options = {
        animation: false,
        title: {
            text: "各区拥堵情况",
            textStyle: {
                fontSize: 16,
            },
        },
        tooltip: {
            show: false,
            trigger: "item",
        },

        radar: {
            indicator: [
                { name: "朝阳", max: 5 },
                { name: "东城", max: 5 },
                { name: "丰台", max: 5 },
                { name: "海淀", max: 5 },
                { name: "石景山", max: 5 },
                { name: "西城", max: 5 },
            ],
        },
        series: [
            {
                name: "拥堵情况",
                type: "radar",
                tooltip: {
                    trigger: "item",
                },
                areaStyle: { color: "#3B6DAA99" },
                lineStyle: { color: "#3B6DAA" },
                itemStyle: { color: "#3B6DAA" },
                data: [
                    {
                        value: [2, 1, 0.5, 1.5, 3, 2],
                        name: "拥堵等级",
                        label: {
                            show: true,
                            formatter: function (params: any) {
                                return `${params.value}`;
                            },
                            fontWeight: 500,
                            fontSize: 16,
                            shadowColor: "white",
                        },
                    },
                ],
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      const newOptions = JSON.parse(optionsString);
      let arr=["chaoyang","dongcheng","fengtai","haidian","shijingshan","xicheng"]
      let temp:any=data.abnormalDetail.regionCongestion
      let arrNum=arr.map((item)=>{
        return temp[item].toFixed(2)||0
      })
      newOptions.series[0].data[0].value=arrNum
      // newOptions.series[0].data[0].value = Object.values(
      //   data.abnormalDetail.regionCongestion
      // ).map((v: any) => v.toFixed(2));

            // let red = false;
            // Object.values(data[data.length - 1].region_level).forEach((v: any) => {
            //   if (v > 2) {
            //     red = true;
            //   }
            // });
            // if (red) {
            //   newOptions.series[0].areaStyle = { color: "#ef5344" };
            //   newOptions.series[0].lineStyle = { color: "#ef5344" };
            //   newOptions.series[0].itemStyle = { color: "#ef5344" };
            // }
            setOptions(newOptions);
        }
    }, [JSON.stringify(data), optionsString]);
    return (
        <ReactECharts
            option={chart_options}
            ref={refEcharts}
            style={{ width: "95%", height: "250px", margin: "0 auto" }}
        />
    );
};

export const ElecMetricRadar = ({ data }: { data: AllElecData }) => {
    const options = {
        animation: false,
        title: {
            text: "各区电站故障情况",
            textStyle: {
                fontSize: 16,
            },
        },
        tooltip: {
            show: false,
            trigger: "item",
        },

        // TODO: max设为最大数量应该可调整
        radar: {
            indicator: [
                { name: "朝阳", max: 200 },
                { name: "东城", max: 200 },
                { name: "丰台", max: 200 },
                { name: "海淀", max: 200 },
                { name: "石景山", max: 200 },
                { name: "西城", max: 200 },
            ],
        },
        series: [
            {
                name: "损坏情况",
                type: "radar",
                tooltip: {
                    trigger: "item",
                },
                areaStyle: { color: "#A6142799" },
                lineStyle: { color: "#A61427" },
                itemStyle: { color: "#A61427" },
                data: [
                    {
                        value: [10, 10, 10, 10, 10, 10],
                        name: "损坏情况",
                        label: {
                            show: true,
                            formatter: function (params: any) {
                                return `${params.value}个`;
                            },
                            // formatter: "{c}%",
                            fontWeight: 500,
                            fontSize: 16,
                            shadowColor: "white",
                        },
                    },
                ],
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      const newOptions = JSON.parse(optionsString);
      newOptions.series[0].data[0].value = [
        (data.abnormalInfo.chaoyang ).toFixed(0),
        (data.abnormalInfo.dongcheng ).toFixed(0),
        (data.abnormalInfo.fengtai ).toFixed(0),
        (data.abnormalInfo.haidian ).toFixed(0),
        (data.abnormalInfo.shijingshan ).toFixed(0),
        (data.abnormalInfo.xicheng ).toFixed(0),
      ];
      setOptions(newOptions);
    }
  }, [JSON.stringify(data), optionsString]);
  return (
    <ReactECharts
      option={chart_options}
      ref={refEcharts}
      style={{ width: "95%", height: "250px", margin: "0 auto" }}
    />
  );
};

export const CommDemandMetricRadar = ({ data }: { data: AllCommData }) => {
    const options = {
        animation: false,
        title: {
            text: "各区用户需求满足情况",
            textStyle: {
                fontSize: 16,
            },
        },
        tooltip: {
            show: false,
            trigger: "item",
        },

        radar: {
            center: ["55%", "50%"],
            indicator: [
                { name: "朝阳", max: 100 },
                { name: "东城", max: 100 },
                { name: "丰台", max: 100 },
                { name: "海淀", max: 100 },
                { name: "石景山", max: 100 },
                { name: "西城", max: 100 },
            ],
        },
        series: [
            {
                name: "需求满足",
                type: "radar",
                tooltip: {
                    trigger: "item",
                },
                areaStyle: { color: "#3B6DAA99" },
                lineStyle: { color: "#3B6DAA" },
                itemStyle: { color: "#3B6DAA" },
                data: [
                    {
                        value: [20, 10, 50, 75, 30, 20],
                        name: "拥堵等级",
                        label: {
                            show: true,
                            formatter: "{c}%",
                            fontWeight: 500,
                            fontSize: 16,
                            shadowColor: "white",
                        },
                    },
                ],
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);

    const refEcharts = useRef<ReactECharts>(null);
    useEffect(() => {
        if (data) {
            const newOptions = JSON.parse(optionsString);
            newOptions.series[0].data[0].value = [
                (data.demand.chaoyang * 100).toFixed(0),
                (data.demand.dongcheng * 100).toFixed(0),
                (data.demand.fengtai * 100).toFixed(0),
                (data.demand.haidian * 100).toFixed(0),
                (data.demand.shijingshan * 100).toFixed(0),
                (data.demand.xicheng * 100).toFixed(0),
            ];
            // let red = false;
            // Object.values(data[data.length - 1].region_level).forEach((v: any) => {
            //   if (v > 2) {
            //     red = true;
            //   }
            // });
            // if (red) {
            //   newOptions.series[0].areaStyle = { color: "#ef5344" };
            //   newOptions.series[0].lineStyle = { color: "#ef5344" };
            //   newOptions.series[0].itemStyle = { color: "#ef5344" };
            // }
            setOptions(newOptions);
        }
    }, [JSON.stringify(data), optionsString]);
    return (
        <ReactECharts
            option={chart_options}
            ref={refEcharts}
            style={{ width: "95%", height: "250px", margin: "0 auto" }}
        />
    );
};

export const CommMetricRadar = ({ data }: { data: AllCommData }) => {
    const options = {
        animation: false,
        title: {
            text: "各区基站异常情况",
            textStyle: {
                fontSize: 16,
            },
        },
        tooltip: {
            show: false,
            trigger: "item",
        },

        radar: {
            indicator: [
                { name: "朝阳", max: 100 },
                { name: "东城", max: 100 },
                { name: "丰台", max: 100 },
                { name: "海淀", max: 100 },
                { name: "石景山", max: 100 },
                { name: "西城", max: 100 },
            ],
        },
        series: [
            {
                name: "异常情况",
                type: "radar",
                tooltip: {
                    trigger: "item",
                },
                areaStyle: { color: "#A6142799" },
                lineStyle: { color: "#A61427" },
                itemStyle: { color: "#A61427" },
                data: [
                    {
                        value: [20, 10, 50, 75, 30, 20],
                        name: "异常情况",
                        label: {
                            show: true,
                            formatter: "{c}%",
                            fontWeight: 500,
                            fontSize: 16,
                            shadowColor: "white",
                        },
                    },
                ],
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);

    const refEcharts = useRef<ReactECharts>(null);
    useEffect(() => {
        if (data) {
            const newOptions = JSON.parse(optionsString);
            newOptions.series[0].data[0].value = [
                (data.runtime.chaoyang * 100).toFixed(0),
                (data.runtime.dongcheng * 100).toFixed(0),
                (data.runtime.fengtai * 100).toFixed(0),
                (data.runtime.haidian * 100).toFixed(0),
                (data.runtime.shijingshan * 100).toFixed(0),
                (data.runtime.xicheng * 100).toFixed(0),
            ];
            setOptions(newOptions);
        }
    }, [JSON.stringify(data), optionsString]);
    return (
        <ReactECharts
            option={chart_options}
            ref={refEcharts}
            style={{ width: "95%", height: "250px", margin: "0 auto" }}
        />
    );
};

const RoadMetricTable = ({ data }: { data: AllTrafficData }) => {
  const [my_data, setData] = useState<any[]>();
  useEffect(() => {
    if (data) {
      setData(data.abnormalDetail.overloadRoadNames);
    }
  }, [JSON.stringify(data)]);
  return (
    <>
      <List
        size="small"
        header={
          <div style={{ fontSize: 16, textAlign: "left", marginLeft: 10 }}>
            严重拥堵路段
          </div>
        }
        bordered
        dataSource={my_data}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    </>
  );
};

export const WaterRoadMetricEcharts = ({ data }: { data: any[] }) => {
    const options = {
        title: {
            text: "城市积水路段数",
            textStyle: {
                fontSize: 16,
            },
        },
        color: ["#6AA5C7", "#3B6DAA", "#D76DA4"],

        tooltip: {
            trigger: "axis",
        },
        legend: {
            top: 20,
            data: ["轻度积水", "中度积水", "重度积水"],
        },
        xAxis: {
            data: [23, 24, 25, 26, 27],
            axisLabel: {
                formatter: function (data: number) {
                    return moment("00:00:00", "HH:mm:ss")
                        .add(data, "seconds")
                        .format("HH:mm");
                },
            },
        },
        yAxis: {
            type: "value",
            name: "数量",
            max: 5000,
        },
        grid: { left: "15%" },
        series: [
            {
                name: "轻度积水",
                type: "line",
                smooth: true,
                data: [100, 100, 100, 100, 100],
            },
            {
                name: "中度积水",
                type: "line",
                smooth: true,
                data: [5000, 500, 5000, 5000, 5000],
            },
            {
                name: "重度积水",
                type: "line",
                smooth: true,
                data: [2500, 2500, 2500, 2500, 2500],
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      const newOptions = JSON.parse(optionsString);
      newOptions.xAxis.data = data.map((item) => item.step);
      newOptions.series[0].data = data.map((item) =>
        item.levelCount["2"] > 1000 ? 1000 : item.levelCount["2"]
      );
      newOptions.series[1].data = data.map((item) =>
        item.levelCount["3"] > 1000 ? 1000 : item.levelCount["3"]
      );
      newOptions.series[2].data = data.map((item) =>
        item.levelCount["4"] > 1000 ? 1000 : item.levelCount["4"]
      );

            setOptions(newOptions);
        }
    }, [JSON.stringify(data), optionsString]);
    return (
        <ReactECharts
            option={chart_options}
            ref={refEcharts}
            style={{ width: "95%", height: "270px", margin: "0 auto" }}
        />
    );
};

const WaterRoadMetricTable = ({ data }: { data: AllTrafficData }) => {
  const [my_data, setData] = useState<any[]>();
  useEffect(() => {
    if (data) {
      setData(data.abnormalDetail.overfloodedRoadNames);
    }
  }, [JSON.stringify(data)]);
  return (
    <>
      <List
        size="small"
        header={
          <div style={{ fontSize: 16, textAlign: "left", marginLeft: 10 }}>
            严重积水路段
          </div>
        }
        bordered
        dataSource={my_data}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    </>
  );
};

export const WaterRoadMetricRadar = ({ data }: { data: AllTrafficData }) => {
    const options = {
        animation: false,
        title: {
            text: "各区积水情况",
            textStyle: {
                fontSize: 16,
            },
        },
        tooltip: {
            show: false,
            trigger: "item",
        },

        // TODO: max应该可调整
        radar: {
            indicator: [
                { name: "朝阳", max: 80 },
                { name: "东城", max: 80 },
                { name: "丰台", max: 80 },
                { name: "海淀", max: 80 },
                { name: "石景山", max: 80 },
                { name: "西城", max: 80 },
            ],
        },
        series: [
            {
                type: "radar",
                tooltip: {
                    trigger: "item",
                },
                areaStyle: { color: "#3B6DAA99" },
                lineStyle: { color: "#3B6DAA" },
                itemStyle: { color: "#3B6DAA" },
                data: [
                    {
                        value: [0, 0, 0, 0, 0, 0],
                        name: "严重积水的道路数量",
                        label: {
                            show: true,
                            formatter: function (params: any) {
                                return `${params.value}个`;
                            },
                            fontWeight: 500,
                            fontSize: 16,
                            shadowColor: "white",
                        },
                    },
                ],
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      const newOptions = JSON.parse(optionsString);
      let arr=["chaoyang","dongcheng","fengtai","haidian","shijingshan","xicheng"]
      let valueTemp:any=data.abnormalDetail.regionOverfloodedNum
      let arrNum=arr.map((item)=>{
        return valueTemp[item].toFixed(2)||0
      })
      newOptions.series[0].data[0].value=arrNum
      // newOptions.series[0].data[0].value = Object.values(
      //   data.abnormalDetail.regionOverfloodedNum
      // ).map((v: any) => v.toFixed(2));
      setOptions(newOptions);
    }
  }, [JSON.stringify(data), optionsString]);
  return (
    <ReactECharts
      option={chart_options}
      ref={refEcharts}
      style={{ width: "95%", height: "250px", margin: "0 auto" }}
    />
  );
};

export const WaterDemandMetricEcharts = ({ data }: { data: AllWaterData }) => {
    const options = {
        title: {
            text: "居民需求",
            textStyle: {
                fontSize: 16,
            },
        },
        series: [
            {
                name: "管网情况",
                type: "pie",
                color: ["#6AA5C7", "#DDDDDD"],
                center: ["40%", "50%"],
                radius: ["55%", "70%"],
                labelLine: {
                    show: false,
                },
                label: {
                    show: true,
                    position: "center",
                    formatter: function (params: any) {
                        // 默认显⽰第⼀个数据
                        if (params.dataIndex === 0) {
                            return "{p|" + params.percent + "%}" + "\n{nm|" + "已满足" + "}";
                        } else {
                            return "{q|" + params.percent + "%}" + "\n{nm|" + "不满足" + "}";
                        }
                    },
                    rich: {
                        p: {
                            color: "#6AA5C7",
                            fontWeight: 500,
                            fontSize: 22,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        q: {
                            color: "#DDDDDD",
                            fontWeight: 500,
                            fontSize: 22,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        nm: {
                            fontSize: 16,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                    },
                },
                avoidLabelOverlap: false,

                data: [
                    { value: 0.7, name: "正常" },
                    { value: 0.3, name: "损坏" },
                ],
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);
    const [my_data, setData] = useState<AllWaterData>();

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      const newOptions = JSON.parse(optionsString);
      newOptions.series[0].data[0].value =
        data.demand.userSatisfiedRatio.toFixed(2);
      newOptions.series[0].data[1].value = (
        1 - data.demand.userSatisfiedRatio
      ).toFixed(2);

      setOptions(newOptions);
      setData(data);
    }
  }, [JSON.stringify(data), optionsString]);
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <ReactECharts
        option={chart_options}
        ref={refEcharts}
        style={{ height: 200, width: "50%" }}
      />
      <div style={{ textAlign: "left" }}>
        <div>居民需求满足人数</div>
        <div>
          <span className="text-lightblue">{`${my_data ? (my_data.demand.userSatisfiedCnt / 10000).toFixed(2) : 0
            }`}</span>
          万人
        </div>
        <div>居民用水需求量</div>
        <div>
          <span className="text-lightblue">{`${my_data ? my_data.demand.userDemand.toFixed(2) : 0
            }`}</span>
          立方米/秒
        </div>
      </div>
    </div>
  );
};

export const ElecDemandMetricEcharts = ({ data }: { data: AllElecData }) => {
    const options = {
        title: {
            text: "居民用电需求及满足情况",
            textStyle: {
                fontSize: 16,
            },
        },
        series: [
            {
                name: "用电情况",
                type: "pie",
                color: ["#3b6daa", "#DDDDDD"],
                center: ["40%", "50%"],
                radius: ["55%", "70%"],
                labelLine: {
                    show: false,
                },
                label: {
                    show: true,
                    position: "center",
                    formatter: function (params: any) {
                        // 默认显⽰第⼀个数据
                        if (params.dataIndex === 0) {
                            return "{p|" + params.percent + "%}" + "\n{nm|" + "已满足" + "}";
                        } else {
                            return "{p|" + params.percent + "%}" + "\n{nm|" + "不满足" + "}";
                        }
                    },
                    rich: {
                        p: {
                            color: "#3b6daa",
                            fontWeight: 500,
                            fontSize: 22,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        nm: {
                            fontSize: 16,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                    },
                },
                avoidLabelOverlap: false,

                data: [
                    { value: 0.7, name: "已满足" },
                    { value: 0.3, name: "不满足" },
                ],
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);
    const [my_data, setData] = useState<AllElecData>();

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      const newOptions = JSON.parse(optionsString);
      newOptions.series[0].data[0].value =
        data.demand.userSatisfiedRatio.toFixed(2);
      newOptions.series[0].data[1].value = (
        1 - data.demand.userSatisfiedRatio
      ).toFixed(2);
      setOptions(newOptions);
      setData(data);
    }
  }, [JSON.stringify(data), optionsString]);
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <ReactECharts
        option={chart_options}
        ref={refEcharts}
        style={{ height: 200, width: "50%" }}
      />
      <div style={{ textAlign: "left" }}>
        <div>居民需求满足情况</div>
        <div>
          <span className="text-blue">
            {`${my_data
              ? (my_data.demand.userSatisfiedCnt / 10000).toFixed(1)
              : 0
              }`}
          </span>
          万人
        </div>
        <div>居民用电需求量</div>
        <div>
          <span className="text-blue">
            {`${my_data ? my_data.demand.userDemand.toFixed(1) : 0}`}
          </span>
          MW
        </div>
      </div>
    </div>
  );
};

export const ElecAOIDemandMetricEcharts = ({ data }: { data: AllElecData }) => {
    const options = {
        title: {
            text: "AOI用电需求及满足情况",
            textStyle: {
                fontSize: 16,
            },
        },
        series: [
            {
                name: "用电情况",
                type: "pie",
                color: ["#3b6daa", "#DDDDDD"],
                center: ["40%", "50%"],
                radius: ["55%", "70%"],
                labelLine: {
                    show: false,
                },
                label: {
                    show: true,
                    position: "center",
                    formatter: function (params: any) {
                        // 默认显⽰第⼀个数据
                        if (params.dataIndex === 0) {
                            return "{p|" + params.percent + "%}" + "\n{nm|" + "已满足" + "}";
                        } else {
                            return "{p|" + 0 + "%}" + "\n{nm|" + "不满足" + "}";
                        }
                    },
                    rich: {
                        p: {
                            color: "#3b6daa",
                            fontWeight: 500,
                            fontSize: 22,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        nm: {
                            fontSize: 16,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                    },
                },
                avoidLabelOverlap: false,

                data: [
                    { value: 0.7, name: "已满足" },
                    { value: 0.3, name: "不满足" },
                ],
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);
    const [my_data, setData] = useState<AllElecData>();

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      const newOptions = JSON.parse(optionsString);
      newOptions.series[0].data[0].value =
        data.demand.aoiSatisfiedRatio.toFixed(2);
      newOptions.series[0].data[1].value = (
        1 - data.demand.aoiSatisfiedRatio
      ).toFixed(2);

      setOptions(newOptions);
      setData(data);
    }
  }, [JSON.stringify(data), optionsString]);
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <ReactECharts
        option={chart_options}
        ref={refEcharts}
        style={{ height: 200, width: "50%" }}
      />
      <div style={{ textAlign: "left" }}>
        <div>AOI需求满足情况</div>
        <div>
          <span className="text-blue">{`${my_data ? my_data.demand.aoiSatisfiedCnt : 0
            }`}</span>
          个
        </div>
        <div>AOI总用电需求量</div>
        <div>
          <span className="text-blue">{`${my_data ? my_data.demand.aoiDemand.toFixed(1) : 0
            }`}</span>
          MW
        </div>
      </div>
    </div>
  );
};

export const WaterAOIDemandMetricEcharts = ({
    data,
}: {
    data: AllWaterData;
}) => {
    const options = {
        title: {
            text: "AOI需求",
            textStyle: {
                fontSize: 16,
            },
        },
        series: [
            {
                name: "管网情况",
                type: "pie",
                color: ["#6AA5C7", "#DDDDDD"],
                center: ["40%", "50%"],
                radius: ["55%", "70%"],
                labelLine: {
                    show: false,
                },
                label: {
                    show: true,
                    position: "center",
                    formatter: function (params: any) {
                        // 默认显⽰第⼀个数据
                        if (params.dataIndex === 0) {
                            return "{p|" + params.percent + "%}" + "\n{nm|" + "已满足" + "}";
                        } else {
                            return "{q|" + params.percent + "%}" + "\n{nm|" + "不满足" + "}";
                        }
                    },
                    rich: {
                        p: {
                            color: "#6AA5C7",
                            fontWeight: 500,
                            fontSize: 22,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        q: {
                            color: "#DDDDDD",
                            fontWeight: 500,
                            fontSize: 22,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        nm: {
                            fontSize: 16,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                    },
                },
                avoidLabelOverlap: false,

                data: [
                    { value: 0.7, name: "正常" },
                    { value: 0.3, name: "损坏" },
                ],
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);
    const [my_data, setData] = useState<AllWaterData>();

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      const newOptions = JSON.parse(optionsString);
      newOptions.series[0].data[0].value =
        data.demand.aoiSatisfiedRatio.toFixed(2);
      newOptions.series[0].data[1].value = (
        1 - data.demand.aoiSatisfiedRatio
      ).toFixed(2);

      setOptions(newOptions);
      setData(data);
    }
  }, [JSON.stringify(data), optionsString]);
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <ReactECharts
        option={chart_options}
        ref={refEcharts}
        style={{ height: 200, width: "50%" }}
      />
      <div style={{ textAlign: "left" }}>
        <div>AOI需求满足情况</div>
        <div>
          <span className="text-lightblue">{`${my_data ? my_data.demand.aoiSatisfiedCnt : 0
            }`}</span>
          个
        </div>
        <div>AOI用水需求量</div>
        <div>
          <span className="text-lightblue">{`${my_data ? my_data.demand.aoiDemand.toFixed(1) : 0
            }`}</span>
          立方米/秒
        </div>
      </div>
    </div>
  );
};

export const WaterBadMetricEcharts = ({ data }: { data: AllWaterData }) => {
    const options = {
        title: {
            text: "供水网受损情况",
            textStyle: {
                fontSize: 16,
            },
        },
        series: [
            {
                name: "管网情况",
                type: "pie",
                color: ["#6AA5C7", "#A61427"],
                center: ["40%", "50%"],
                radius: ["55%", "70%"],
                labelLine: {
                    show: false,
                },
                label: {
                    show: true,
                    position: "center",
                    formatter: function (params: any) {
                        // 默认显⽰第⼀个数据
                        if (params.dataIndex === 0) {
                            return "{p|" + params.percent + "%}" + "\n{nm|" + "正常" + "}";
                        } else {
                            return "{q|" + params.percent + "%}" + "\n{nm|" + "受损" + "}";
                        }
                    },
                    rich: {
                        p: {
                            color: "#6AA5C7",
                            fontWeight: 500,
                            fontSize: 22,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        q: {
                            color: "#A61427",
                            fontWeight: 500,
                            fontSize: 22,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        nm: {
                            fontSize: 16,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                    },
                },
                avoidLabelOverlap: false,

                data: [
                    { value: 70, name: "正常" },
                    { value: 30, name: "损坏" },
                ],
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);
    const [my_data, setData] = useState<AllWaterData>();

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      const newOptions = JSON.parse(optionsString);
      newOptions.series[0].data[0].value = data.abnormalInfo.supply[0];
      newOptions.series[0].data[1].value = data.abnormalInfo.supply[1];

      setOptions(newOptions);
      setData(data);
    }
  }, [JSON.stringify(data), optionsString]);
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <ReactECharts
        option={chart_options}
        ref={refEcharts}
        style={{ height: 200, width: "50%" }}
      />
      <div style={{ textAlign: "left" }}>
        <div>正常水泵节点</div>
        <div>
          <span className="text-lightblue">{`${my_data ? my_data.abnormalInfo.supply[0] : 0
            }`}</span>
          处
        </div>
        <div>受损水泵节点</div>
        <div>
          <span className="text-red">{`${my_data ? my_data.abnormalInfo.supply[1] : 0
            }`}</span>
          处
        </div>
      </div>
    </div>
  );
};

export const SupplyBadMetricEcharts = ({ data }: { data: AllWaterData }) => {
    const options = {
        title: {
            text: "排水网受损情况",
            textStyle: {
                fontSize: 16,
            },
        },
        tooltip: {
            show: false,
        },
        series: [
            {
                name: "管网情况",
                type: "pie",
                color: ["#6AA5C7", "#A61427"],
                center: ["40%", "50%"],
                radius: ["55%", "70%"],
                labelLine: {
                    show: false,
                },
                label: {
                    show: true,
                    position: "center",
                    formatter: function (params: any) {
                        // 默认显⽰第⼀个数据
                        if (params.dataIndex === 0) {
                            return "{p|" + params.percent + "%}" + "\n{nm|" + "正常" + "}";
                        } else {
                            return "{q|" + params.percent + "%}" + "\n{nm|" + "受损" + "}";
                        }
                    },
                    rich: {
                        p: {
                            color: "#6AA5C7",
                            fontWeight: 500,
                            fontSize: 22,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        q: {
                            color: "#A61427",
                            fontWeight: 500,
                            fontSize: 22,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        nm: {
                            fontSize: 16,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                    },
                },
                avoidLabelOverlap: false,

                data: [
                    { value: 7988, name: "正常" },
                    { value: 2111, name: "损坏" },
                ],
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);
    const [my_data, setData] = useState<AllWaterData>();

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      const newOptions = JSON.parse(optionsString);
      newOptions.series[0].data[0].value = data.abnormalInfo.drainage[0];
      newOptions.series[0].data[1].value = data.abnormalInfo.drainage[1];

      setOptions(newOptions);
      setData(data);
    }
  }, [JSON.stringify(data), optionsString]);
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <ReactECharts
        option={chart_options}
        ref={refEcharts}
        style={{ height: 200, width: "50%" }}
      />
      <div style={{ textAlign: "left" }}>
        <div>正常水泵节点</div>
        <div>
          <span className="text-lightblue">{`${my_data ? my_data.abnormalInfo.drainage[0] : 0
            }`}</span>
          处
        </div>
        <div>受损水泵节点</div>
        <div>
          <span className="text-red">{`${my_data ? my_data.abnormalInfo.drainage[1] : 0
            }`}</span>
          处
        </div>
      </div>
    </div>
  );
};

export const ElecBarEcharts = () => {
    return (
        <>
            <div
                style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    marginTop: 20,
                    textAlign: "left",
                }}
            >
                变电站基础信息
            </div>
            <Row>
                <Col span={12}>
                    <div className="card-blue">
                        <div className="inner-card">
                            <Avatar
                                style={{ float: "left" }}
                                shape="square"
                                size="large"
                                src="变压器-550kv.svg"
                            />
                            <div style={{ marginLeft: 10, textAlign: "left" }}>
                                <div>500kV变压器</div>
                                <div>
                                    <span className="text-blue">16</span>
                                    {" 个"}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="card-blue">
                        <div className="inner-card">
                            <Avatar
                                style={{ float: "left" }}
                                shape="square"
                                size="large"
                                src="变压器-220kv.svg"
                            />
                            <div style={{ marginLeft: 10, textAlign: "left" }}>
                                <div>220kV变压器</div>
                                <div>
                                    <span className="text-blue">100</span>
                                    {" 个"}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <div className="card-blue">
                        <div className="inner-card">
                            <Avatar
                                style={{ float: "left" }}
                                shape="square"
                                size="large"
                                src="变压器-110kv.svg"
                            />
                            <div style={{ marginLeft: 10, textAlign: "left" }}>
                                <div>110kV变压器</div>
                                <div>
                                    <span className="text-blue">535</span>
                                    {" 个"}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="card-blue">
                        <div className="inner-card">
                            <Avatar
                                style={{ float: "left" }}
                                shape="square"
                                size="large"
                                src="变压器-10kv.svg"
                            />
                            <div style={{ marginLeft: 10, textAlign: "left" }}>
                                <div>10kV变压器</div>
                                <div>
                                    <span className="text-blue">10227</span>
                                    {" 个"}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export const EleAverageBarEcharts = ({ data }: { data: AllElecData }) => {
    const [my_data, setData] = useState<AllElecData>();

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      setData(data);
    }
  }, [JSON.stringify(data)]);
  return (
    <>
      <div
        style={{
          fontSize: "16px",
          fontWeight: 500,
          textAlign: "left",
          marginLeft: 10,
        }}
      >
        变电站平均承受功率
      </div>
      <div>
        <div style={{ float: "left", marginLeft: 10 }}>
          <span
            style={{
              fontSize: "16px",
              color: "#5b5c5b",
              marginRight: 10,
            }}
          >
            500kV变压器
          </span>
          <span
            style={{
              fontSize: "22px",
              color: "#3462a0",
              fontWeight: "600",
            }}
          >
            {` ${(my_data ? my_data.basicInfo.averagePower[3] : 0).toFixed(
              1
            )}MW`}
          </span>
        </div>
        <Progress
          percent={
            ((my_data ? my_data.basicInfo.averagePower[3] : 0) / 67.51) * 100
          }
          style={{ width: "95%" }}
          showInfo={false}
          strokeColor={"#3462a0"}
        />
      </div>
      <div>
        <div style={{ float: "left", marginLeft: 10 }}>
          <span
            style={{
              fontSize: "16px",
              color: "#5b5c5b",
              marginRight: 10,
            }}
          >
            220kV变压器
          </span>
          <span
            style={{
              fontSize: "22px",
              color: "#6aa5c7",
              fontWeight: "600",
            }}
          >
            {` ${(my_data ? my_data.basicInfo.averagePower[2] : 0).toFixed(
              1
            )}MW`}
          </span>
        </div>

        <Progress
          percent={
            ((my_data ? my_data.basicInfo.averagePower[2] : 0) / 25.81) * 100
          }
          style={{ width: "95%" }}
          showInfo={false}
          strokeColor={"#6aa5c7"}
        />
      </div>
      <div>
        <div style={{ float: "left", marginLeft: 10 }}>
          <span
            style={{
              fontSize: "16px",
              color: "#5b5c5b",
              marginRight: 10,
            }}
          >
            110kV变压器
          </span>
          <span
            style={{
              fontSize: "22px",
              color: "#d76da4",
              fontWeight: "600",
            }}
          >
            {` ${(my_data ? my_data.basicInfo.averagePower[1] : 0).toFixed(
              1
            )}MW`}
          </span>
        </div>
        <Progress
          percent={
            ((my_data ? my_data.basicInfo.averagePower[1] : 0) / 4.827) * 100
          }
          style={{ width: "95%" }}
          showInfo={false}
          strokeColor={"#d76da4"}
        />
      </div>
      <div>
        <div style={{ float: "left", marginLeft: 10 }}>
          <span
            style={{
              fontSize: "16px",
              color: "#5b5c5b",
              marginRight: 10,
            }}
          >
            10kV变压器
          </span>
          <span
            style={{
              fontSize: "22px",
              color: "#ba9dc9",
              fontWeight: "600",
            }}
          >
            {` ${(my_data ? my_data.basicInfo.averagePower[0] : 0).toFixed(
              2
            )}MW`}
          </span>
        </div>
        <Progress
          percent={
            ((my_data ? my_data.basicInfo.averagePower[0] : 0) / 0.098) * 100
          }
          style={{ width: "95%" }}
          showInfo={false}
          strokeColor={"#ba9dc9"}
        />
      </div>
    </>
  );
};

export const TrafficBar1 = ({ data }: { data: AllTrafficData }) => {
    const options = {
        xAxis: {
            type: "value",
            max: 4,
            show: false,
        },
        yAxis: {
            type: "category",
            show: false,
            data: [""],
        },
        series: [
            {
                name: "严重",
                type: "bar",
                stack: "总量",
                itemStyle: {
                    normal: {
                        color: "#6AA5C7",
                    },
                },
                data: [1],
            },
            {
                name: "高危",
                type: "bar",
                stack: "总量",
                itemStyle: {
                    normal: {
                        color: "#3B6DAA",
                    },
                },
                data: [1],
            },
            {
                name: "中危",
                type: "bar",
                stack: "总量",
                itemStyle: {
                    normal: {
                        color: "#D76DA4",
                    },
                },
                data: [1],
            },
            {
                name: "低危",
                type: "bar",
                stack: "总量",
                itemStyle: {
                    normal: {
                        color: "#A61427",
                    },
                },
                data: [1],
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);
    const [my_data, setData] = useState<AllTrafficData>();

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      const newOptions = JSON.parse(optionsString);
      newOptions.xAxis.max = data.abnormalInfo.congestionLevelCounts.reduce(
        (partialSum, a) => partialSum + a,
        0
      );
      newOptions.series[0].data[0] =
        data.abnormalInfo.congestionLevelCounts[0];
      newOptions.series[1].data[0] =
        data.abnormalInfo.congestionLevelCounts[1];
      newOptions.series[2].data[0] =
        data.abnormalInfo.congestionLevelCounts[2];
      newOptions.series[3].data[0] =
        data.abnormalInfo.congestionLevelCounts[3];

      setOptions(newOptions);
      setData(data);
    }
  }, [JSON.stringify(data), optionsString]);
  return (
    <>
      <div
        style={{
          fontSize: "16px",
          fontWeight: 500,
          textAlign: "left",
          marginLeft: 10,
        }}
      >
        全局道路拥堵比例
      </div>
      <Row>
        <Col span={12}>
          轻度拥堵{" "}
          <span className="text-lightblue">{`${my_data ? my_data.abnormalInfo.congestionLevelCounts[0] : 0
            }`}</span>
          条
        </Col>
        <Col span={12}>
          中度拥堵
          <span className="text-blue">{`${my_data ? my_data.abnormalInfo.congestionLevelCounts[1] : 0
            }`}</span>
          条
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          重度拥堵
          <span className="text-pink">{`${my_data ? my_data.abnormalInfo.congestionLevelCounts[2] : 0
            }`}</span>
          条
        </Col>
        <Col span={12}>
          极端拥堵
          <span className="text-red">{`${my_data ? my_data.abnormalInfo.congestionLevelCounts[3] : 0
            }`}</span>
          条
        </Col>
      </Row>
      <ReactECharts
        style={{ height: "20px", marginBottom: 20 }}
        option={chart_options}
        ref={refEcharts}
      />
    </>
  );
};

export const TrafficBar3 = ({ data }: { data: AllTrafficData }) => {
    const options = {
        xAxis: {
            type: "value",
            max: 2,
            show: false,
        },
        yAxis: {
            type: "category",
            show: false,
            data: [""],
        },
        series: [
            {
                name: "严重",
                type: "bar",
                stack: "总量",
                itemStyle: {
                    normal: {
                        color: "#6AA5C7",
                    },
                },
                data: [1],
            },
            {
                name: "低危",
                type: "bar",
                stack: "总量",
                itemStyle: {
                    normal: {
                        color: "#A61427",
                    },
                },
                data: [1],
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);
    const [my_data, setData] = useState<AllTrafficData>();

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      const newOptions = JSON.parse(optionsString);
      newOptions.xAxis.max = data.abnormalInfo.trafficLight.reduce(
        (partialSum, a) => partialSum + a,
        0
      );
      newOptions.series[0].data[0] = data.abnormalInfo.trafficLight[0];
      newOptions.series[1].data[0] = data.abnormalInfo.trafficLight[1];

      setOptions(newOptions);
      setData(data);
    }
  }, [JSON.stringify(data), optionsString]);
  return (
    <>
      <div
        style={{
          fontSize: "16px",
          fontWeight: 500,
          textAlign: "left",
          marginLeft: 10,
        }}
      >
        交通灯运行情况
      </div>
      <Row>
        <Col span={12}>
          正常运作{" "}
          <span className="text-lightblue">{`${my_data ? my_data.abnormalInfo.trafficLight[0] : 0
            }`}</span>
          处
        </Col>
        <Col span={12}>
          异常运作
          <span className="text-red">{`${my_data ? my_data.abnormalInfo.trafficLight[1] : 0
            }`}</span>
          处
        </Col>
      </Row>
      <ReactECharts
        style={{ height: "20px", marginBottom: 20 }}
        option={chart_options}
        ref={refEcharts}
      />
    </>
  );
};

export const CommMetricPie = ({ data }: { data: any }) => {
    const options = {
        title: {
            text: "基站运行情况",
        },
        tooltip: {
            trigger: "item",
        },

        legend: {
            top: 20,
        },
        series: [
            {
                name: "基站情况",
                type: "pie",
                radius: ["40%", "70%"],
                labelLine: {
                    show: false,
                },
                label: {
                    show: false,
                    position: "center",
                },
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: "#fff",
                    borderWidth: 2,
                },
                data: [
                    { value: 0.5, name: "正常" },
                    { value: 0.3, name: "直流供电" },
                    { value: 0.2, name: "宕机" },
                ],
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);

    const refEcharts = useRef<ReactECharts>(null);
    useEffect(() => {
        if (data) {
            const newOptions = JSON.parse(optionsString);
            newOptions.series[0].data[0].value = (
                1 -
                data.battery_ratio -
                data.failure_ratio
            ).toFixed(2);
            newOptions.series[0].data[1].value = data.battery_ratio.toFixed(2);
            newOptions.series[0].data[2].value = data.failure_ratio.toFixed(2);

            setOptions(newOptions);
        }
    }, [JSON.stringify(data), optionsString]);
    return <ReactECharts option={chart_options} ref={refEcharts} />;
};

export const PressureGauge = ({ data }: { data: CityRuntimeData }) => {
    const options = {
        title: [
            {
                text: "城市综合指标",
                top: -5,
                textStyle: {
                    fontSize: 22,
                },
            },
            {
                text: "城市压力指数",
                top: "30%",
                left: "57%",
                textStyle: {
                    fontSize: 15,
                    color: "#666666",
                },
            },
        ],
        grid: { height: "100%", y: 0, y2: 0 },
        series: [
            {
                type: "gauge",
                startAngle: 210,
                endAngle: -30,
                min: 0,
                max: 1,
                splitNumber: 10,
                center: ["35%", "60%"],
                axisLine: {
                    lineStyle: {
                        width: 10,
                        color: [
                            [0.4, "#6AA5C7"],
                            [0.8, "#D76DA4"],
                            [1, "#A61427"],
                        ],
                    },
                },
                pointer: {
                    icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
                    itemStyle: {
                        color: "#C4C4C4",
                    },
                },
                axisTick: {
                    show: false,
                    length: 8,
                    lineStyle: {
                        color: "auto",
                        width: 0.2,
                    },
                },
                splitLine: {
                    length: "50%",
                    distance: 0,
                    lineStyle: {
                        color: "#C4C4C4",
                        type: "dashed",
                        width: 0.5,
                    },
                },
                axisLabel: {
                    show: false,
                    color: "#464646",
                    distance: -40,
                    formatter: function (value: any) {
                        if (value === 0.9) {
                            return "崩溃";
                        } else if (value === 0.6) {
                            return "过载";
                        } else if (value === 0.2) {
                            return "正常";
                        }
                        return "";
                    },
                },
                detail: {
                    formatter: function (value: any) {
                        let level = "";
                        if (value < 0.4) level = "正常";
                        else if (value < 0.8) level = "过载";
                        else level = "崩溃";
                        return `${(value * 100).toFixed(0)}% ${level}`;
                    },
                    offsetCenter: ["230%", "10%"],
                    fontSize: 20,
                    valueAnimation: true,
                    color: "auto",
                },
                data: [
                    {
                        value: 0.5,
                    },
                ],
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      const newOptions = JSON.parse(optionsString);
      newOptions.series[0].data[0].value = data.pressureIndex.toFixed(2);

            setOptions(newOptions);
        }
    }, [JSON.stringify(data), optionsString]);
    return (
        <ReactECharts
            option={chart_options}
            ref={refEcharts}
            style={{
                width: "100%",
                height: "150px",

                marginTop: 20,
            }}
        />
    );
};

export const PanicGauge = ({ data }: { data: CityRuntimeData }) => {
    const options = {
        title: {
            text: "市民恐慌指数",
            top: "20%",
            left: "57%",
            textStyle: {
                fontSize: 15,
                color: "#666666",
            },
        },
        grid: { height: "100%", y: 0, y2: 0 },
        series: [
            {
                type: "gauge",
                startAngle: 210,
                endAngle: -30,
                min: 0,
                max: 1,
                splitNumber: 10,
                center: ["35%", "50%"],
                axisLine: {
                    lineStyle: {
                        width: 10,
                        color: [
                            [0.4, "#6AA5C7"],
                            [0.8, "#D76DA4"],
                            [1, "#A61427"],
                        ],
                    },
                },
                pointer: {
                    icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
                    itemStyle: {
                        color: "#C4C4C4",
                    },
                },
                axisTick: {
                    show: false,
                    length: 8,
                    lineStyle: {
                        color: "auto",
                        width: 0.2,
                    },
                },
                splitLine: {
                    length: "50%",
                    distance: 0,
                    lineStyle: {
                        color: "#C4C4C4",
                        type: "dashed",
                        width: 0.5,
                    },
                },
                axisLabel: {
                    show: false,
                    color: "#464646",
                    distance: -40,
                    formatter: function (value: any) {
                        if (value === 0.9) {
                            return "恐慌";
                        } else if (value === 0.6) {
                            return "焦虑";
                        } else if (value === 0.2) {
                            return "稳定";
                        }
                        return "";
                    },
                },
                detail: {
                    formatter: function (value: any) {
                        let level = "";
                        if (value < 0.4) level = "稳定";
                        else if (value < 0.8) level = "焦虑";
                        else level = "恐慌";
                        return `${(value * 100).toFixed(0)}% ${level}`;
                    },
                    offsetCenter: ["230%", "10%"],
                    fontSize: 20,
                    valueAnimation: true,
                    color: "auto",
                },
                data: [
                    {
                        value: 0.2,
                    },
                ],
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      const newOptions = JSON.parse(optionsString);
      newOptions.series[0].data[0].value = data.publicPanicIndex.toFixed(2);

            setOptions(newOptions);
        }
    }, [JSON.stringify(data), optionsString]);
    return (
        <ReactECharts
            option={chart_options}
            ref={refEcharts}
            style={{ width: "100%", height: "150px", margin: "0 auto" }}
        />
    );
};

export const WaterGauge = ({ data }: { data: AllWaterData }) => {
    const options = {
        title: [
            {
                text: "水网负荷情况",
                textStyle: {
                    fontSize: 16,
                },
            },
            {
                text: "排水网负荷",
                top: "30%",
                left: "50%",
                textStyle: {
                    fontSize: 15,
                    color: "#666666",
                },
            },
        ],
        grid: { height: "100%", y: 0, y2: 0 },
        series: [
            {
                type: "gauge",
                startAngle: 210,
                endAngle: -30,
                min: 0,
                max: 1,
                splitNumber: 10,
                center: ["20%", "60%"],
                axisLine: {
                    lineStyle: {
                        width: 10,
                        color: [
                            [0.4, "#6AA5C7"],
                            [0.8, "#D76DA4"],
                            [1, "#A61427"],
                        ],
                    },
                },
                pointer: {
                    icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
                    itemStyle: {
                        color: "#C4C4C4",
                    },
                },
                axisTick: {
                    show: false,
                    length: 8,
                    lineStyle: {
                        color: "auto",
                        width: 0.2,
                    },
                },
                splitLine: {
                    length: "50%",
                    distance: 0,
                    lineStyle: {
                        color: "#C4C4C4",
                        type: "dashed",
                        width: 0.5,
                    },
                },
                axisLabel: {
                    show: false,
                    color: "#464646",
                    distance: -40,
                    formatter: function (value: any) {
                        if (value === 0.9) {
                            return "崩溃";
                        } else if (value === 0.6) {
                            return "过载";
                        } else if (value === 0.2) {
                            return "正常";
                        }
                        return "";
                    },
                },
                detail: {
                    formatter: function (value: any) {
                        let level = "";
                        if (value < 0.4) level = "低负荷";
                        else if (value < 0.8) level = "中负荷";
                        else level = "高负荷";
                        return `${(value * 100).toFixed(0)}% ${level}`;
                    },
                    offsetCenter: ["300%", "10%"],
                    fontSize: 20,
                    valueAnimation: true,
                    color: "auto",
                },
                data: [
                    {
                        value: 0.5,
                    },
                ],
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      const newOptions = JSON.parse(optionsString);
      newOptions.series[0].data[0].value = data.loadRatio.drainage.toFixed(2);

            setOptions(newOptions);
        }
    }, [JSON.stringify(data), optionsString]);
    return (
        <ReactECharts
            option={chart_options}
            ref={refEcharts}
            style={{
                width: "100%",
                height: "150px",
                marginTop: 10,
            }}
        />
    );
};

export const SupplyGauge = ({ data }: { data: AllWaterData }) => {
    const options = {
        title: [
            {
                text: "供水网负荷",
                top: "30%",
                left: "50%",
                textStyle: {
                    fontSize: 15,
                    color: "#666666",
                },
            },
        ],
        grid: { height: "100%", y: 0, y2: 0 },
        series: [
            {
                type: "gauge",
                startAngle: 210,
                endAngle: -30,
                min: 0,
                max: 1,
                splitNumber: 10,
                center: ["20%", "60%"],
                axisLine: {
                    lineStyle: {
                        width: 10,
                        color: [
                            [0.4, "#6AA5C7"],
                            [0.8, "#D76DA4"],
                            [1, "#A61427"],
                        ],
                    },
                },
                pointer: {
                    icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
                    itemStyle: {
                        color: "#C4C4C4",
                    },
                },
                axisTick: {
                    show: false,
                    length: 8,
                    lineStyle: {
                        color: "auto",
                        width: 0.2,
                    },
                },
                splitLine: {
                    length: "50%",
                    distance: 0,
                    lineStyle: {
                        color: "#C4C4C4",
                        type: "dashed",
                        width: 0.5,
                    },
                },
                axisLabel: {
                    show: false,
                    color: "#464646",
                    distance: -40,
                    formatter: function (value: any) {
                        if (value === 0.9) {
                            return "崩溃";
                        } else if (value === 0.6) {
                            return "过载";
                        } else if (value === 0.2) {
                            return "正常";
                        }
                        return "";
                    },
                },
                detail: {
                    formatter: function (value: any) {
                        let level = "";
                        if (value < 0.4) level = "低负荷";
                        else if (value < 0.8) level = "中负荷";
                        else level = "高负荷";
                        return `${(value * 100).toFixed(0)}% ${level}`;
                    },
                    offsetCenter: ["300%", "10%"],
                    fontSize: 20,
                    valueAnimation: true,
                    color: "auto",
                },
                data: [
                    {
                        value: 0.5,
                    },
                ],
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      const newOptions = JSON.parse(optionsString);
      newOptions.series[0].data[0].value = data.loadRatio.supply.toFixed(2);

            setOptions(newOptions);
        }
    }, [JSON.stringify(data), optionsString]);
    return (
        <ReactECharts
            option={chart_options}
            ref={refEcharts}
            style={{
                width: "100%",
                height: "150px",
            }}
        />
    );
};

export const TrafficGauge = ({ data }: { data: AllTrafficData }) => {
    const options = {
        title: {
            text: "城市平均道路拥堵指数",
            top: "20%",
            left: "47%",
            textStyle: {
                fontSize: 15,
                color: "#666666",
            },
        },
        grid: { height: "100%", y: 0, y2: 0 },
        series: [
            {
                type: "gauge",
                startAngle: 210,
                endAngle: -30,
                min: 1,
                max: 5,
                splitNumber: 8,
                center: ["25%", "50%"],
                axisLine: {
                    lineStyle: {
                        width: 10,
                        color: [
                            [0.25, "#6AA5C7"],
                            [0.5, "#3b6daa"],
                            [0.75, "#D76DA4"],
                            [1, "#A61427"],
                        ],
                    },
                },
                pointer: {
                    icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
                    itemStyle: {
                        color: "#C4C4C4",
                    },
                },
                axisTick: {
                    show: false,
                    length: 8,
                    lineStyle: {
                        color: "auto",
                        width: 0.2,
                    },
                },
                splitLine: {
                    length: "50%",
                    distance: 0,
                    lineStyle: {
                        color: "#C4C4C4",
                        type: "dashed",
                        width: 0.5,
                    },
                },
                axisLabel: { show: false },
                detail: {
                    formatter: function (value: any) {
                        let level = "";
                        if (value < 2) level = "畅通";
                        else if (value < 3) level = "轻度拥堵";
                        else if (value < 4) level = "中度拥堵";
                        else level = "严重拥堵";
                        return `${value.toFixed(2)} ${level}`;
                    },
                    offsetCenter: ["250%", "10%"],
                    fontSize: 20,
                    valueAnimation: true,
                    color: "auto",
                },
                data: [
                    {
                        value: 2.4,
                    },
                ],
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      const newOptions = JSON.parse(optionsString);
      newOptions.series[0].data[0].value =
        data.abnormalInfo.meanCongestionLevel.toFixed(2);

            setOptions(newOptions);
        }
    }, [JSON.stringify(data), optionsString]);
    return (
        <div>
            <div
                style={{
                    fontSize: "22px",
                    fontWeight: 500,
                    marginLeft: 10,
                    textAlign: "left",
                }}
            >
                交通信息
            </div>
            <div
                style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    marginLeft: 10,
                    textAlign: "left",
                }}
            >
                交通拥堵情况
            </div>
            <ReactECharts
                option={chart_options}
                ref={refEcharts}
                style={{ width: "100%", height: "150px", margin: "0 auto" }}
            />
        </div>
    );
};

export const RepairResourceMultiPie = ({ data }: { data: CityRuntimeData }) => {
    const options = {
        legend: { top: 20 },
        color: ["#6AA5C7", "#D76DA4", "#A61427"],
        title: [
            {
                text: "抢修资源",
                top: -5,
                textStyle: {
                    fontSize: 22,
                },
            },
            {
                text: "电力抢修资源",
                left: "24%",
                top: "47%",
                textAlign: "center",
                textStyle: { fontSize: 15 },
            },
            {
                text: "水力抢修资源",
                left: "74%",
                top: "47%",
                textAlign: "center",
                textStyle: { fontSize: 15 },
            },
            {
                text: "交通抢修资源",
                left: "74%",
                top: "92%",
                textAlign: "center",
                textStyle: { fontSize: 15 },
            },
            {
                text: "通信抢修资源",
                left: "24%",
                top: "92%",
                textAlign: "center",
                textStyle: { fontSize: 15 },
            },
        ],
        series: [
            {
                type: "pie",
                radius: ["25%", "32%"],
                center: ["25%", "30%"],
                data: [
                    { value: 0.1, name: "空闲资源" },
                    { value: 0.2, name: "占用资源" },
                    { value: 0.7, name: "受损资源" },
                ],
                label: {
                    show: true,
                    position: "center",
                    formatter: function (params: any) {
                        // 默认显⽰第⼀个数据
                        if (params.dataIndex === 1) {
                            return "{p|" + params.percent + "%}" + "\n{nm|" + "已占用" + "}";
                        }
                        if (params.dataIndex === 2) {
                            return "{q|" + params.percent + "%}" + "\n{nm|" + "损坏" + "}";
                        }
                        if (params.dataIndex === 0) {
                            return "{r|" + params.percent + "%}" + "\n{nm|" + "空闲" + "}";
                        }
                    },
                    rich: {
                        p: {
                            color: "#d76da4",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        q: {
                            color: "#a61427",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        r: {
                            color: "#6aa5c7",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        nm: {
                            fontSize: 12,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                    },
                },
            },
            {
                type: "pie",
                radius: ["25%", "32%"],
                center: ["75%", "30%"],
                data: [
                    { value: 0.3, name: "空闲资源" },
                    { value: 0, name: "占用资源" },
                    { value: 0.7, name: "受损资源" },
                ],
                label: {
                    show: true,
                    position: "center",
                    formatter: function (params: any) {
                        // 默认显⽰第⼀个数据
                        if (params.dataIndex === 1) {
                            return "{p|" + params.percent + "%}" + "\n{nm|" + "已占用" + "}";
                        }
                        if (params.dataIndex === 2) {
                            return "{q|" + params.percent + "%}" + "\n{nm|" + "损坏" + "}";
                        }
                        if (params.dataIndex === 0) {
                            return "{r|" + params.percent + "%}" + "\n{nm|" + "空闲" + "}";
                        }
                    },
                    rich: {
                        p: {
                            color: "#d76da4",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        q: {
                            color: "#a61427",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        r: {
                            color: "#6aa5c7",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        nm: {
                            fontSize: 12,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                    },
                },
            },
            {
                type: "pie",
                radius: ["25%", "32%"],
                center: ["25%", "75%"],
                data: [
                    { value: 0.3, name: "空闲资源" },

                    { value: 0.7, name: "占用资源" },
                    { value: 0, name: "受损资源" },
                ],
                label: {
                    show: true,
                    position: "center",
                    formatter: function (params: any) {
                        // 默认显⽰第⼀个数据
                        if (params.dataIndex === 1) {
                            return "{p|" + params.percent + "%}" + "\n{nm|" + "已占用" + "}";
                        }
                        if (params.dataIndex === 2) {
                            return "{q|" + params.percent + "%}" + "\n{nm|" + "损坏" + "}";
                        }
                        if (params.dataIndex === 0) {
                            return "{r|" + params.percent + "%}" + "\n{nm|" + "空闲" + "}";
                        }
                    },
                    rich: {
                        p: {
                            color: "#d76da4",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        q: {
                            color: "#a61427",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        r: {
                            color: "#6aa5c7",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        nm: {
                            fontSize: 12,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                    },
                },
            },
            {
                type: "pie",
                radius: ["25%", "32%"],
                center: ["75%", "75%"],
                data: [
                    { value: 0.1, name: "空闲资源" },

                    { value: 0.2, name: "占用资源" },
                    { value: 0.7, name: "受损资源" },
                ],
                label: {
                    show: true,
                    position: "center",
                    formatter: function (params: any) {
                        // 默认显⽰第⼀个数据
                        if (params.dataIndex === 1) {
                            return "{p|" + params.percent + "%}" + "\n{nm|" + "已占用" + "}";
                        }
                        if (params.dataIndex === 2) {
                            return "{q|" + params.percent + "%}" + "\n{nm|" + "损坏" + "}";
                        }
                        if (params.dataIndex === 0) {
                            return "{r|" + params.percent + "%}" + "\n{nm|" + "空闲" + "}";
                        }
                    },
                    rich: {
                        p: {
                            color: "#d76da4",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        q: {
                            color: "#a61427",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        r: {
                            color: "#6aa5c7",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        nm: {
                            fontSize: 12,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                    },
                },
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      const newOptions = JSON.parse(optionsString);
      newOptions.series[0].data[0].value =
        data.repairResource.elec[0].toFixed(2);
      newOptions.series[0].data[1].value =
        data.repairResource.elec[1].toFixed(2);
      newOptions.series[0].data[2].value =
        data.repairResource.elec[2].toFixed(2);
      newOptions.series[1].data[0].value =
        data.repairResource.water[0].toFixed(2);
      newOptions.series[1].data[1].value =
        data.repairResource.water[1].toFixed(2);
      newOptions.series[1].data[2].value =
        data.repairResource.water[2].toFixed(2);
      newOptions.series[3].data[0].value =
        data.repairResource.comm[0].toFixed(2);
      newOptions.series[3].data[1].value =
        data.repairResource.comm[1].toFixed(2);
      newOptions.series[3].data[2].value =
        data.repairResource.comm[2].toFixed(2);
      newOptions.series[2].data[0].value =
        data.repairResource.traffic[0].toFixed(2);
      newOptions.series[2].data[1].value =
        data.repairResource.traffic[1].toFixed(2);
      newOptions.series[2].data[2].value =
        data.repairResource.traffic[2].toFixed(2);

            setOptions(newOptions);
        }
    }, [JSON.stringify(data), optionsString]);
    return (
        <ReactECharts
            option={chart_options}
            ref={refEcharts}
            style={{ width: "95%", height: "300px", margin: "0 auto" }}
        />
    );
};

export const CommRunMultiPie1 = ({ data }: { data: AllCommData }) => {
    const options = {
        legend: { top: 20 },
        color: ["#6AA5C7", "#D76DA4", "#ba9dc9", "#A61427"],
        title: [
            {
                text: "通信网运行情况",
                top: -5,
                textStyle: {
                    fontSize: 22,
                },
            },
        ],
        series: [
            {
                type: "pie",
                radius: ["40%", "55%"],
                center: ["25%", "60%"],
                data: [
                    { value: 0.5, name: "正常" },
                    { value: 0.2, name: "直流电" },
                    { value: 0.2, name: "断电" },
                    { value: 0.1, name: "损坏" },
                ],
                label: {
                    show: true,
                    position: "center",
                    formatter: function (params: any) {
                        // 默认显⽰第⼀个数据
                        if (params.dataIndex === 0) {
                            return (
                                "{r|" + params.percent + "%}" + "\n{nm|" + "正常基站" + "}"
                            );
                        }
                        if (params.dataIndex === 1) {
                            return (
                                "{s|" + params.percent + "%}" + "\n{nm|" + "直流供电基站" + "}"
                            );
                        }
                        if (params.dataIndex === 2) {
                            return (
                                "{p|" + params.percent + "%}" + "\n{nm|" + "断电基站" + "}"
                            );
                        }
                        if (params.dataIndex === 3) {
                            return (
                                "{q|" + params.percent + "%}" + "\n{nm|" + "损坏基站" + "}"
                            );
                        }
                    },
                    rich: {
                        p: {
                            color: "#d76da4",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        q: {
                            color: "#a61427",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        r: {
                            color: "#6aa5c7",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        s: {
                            color: "#ba9dc9",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        nm: {
                            fontSize: 12,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                    },
                },
            },
            {
                type: "pie",
                radius: ["40%", "55%"],
                center: ["75%", "60%"],
                data: [
                    { value: 0.5, name: "正常" },
                    { value: 0.2, name: "直流电" },
                    { value: 0, name: "断电" },
                    { value: 0.3, name: "损坏" },
                ],
                label: {
                    show: true,
                    position: "center",
                    formatter: function (params: any) {
                        // 默认显⽰第⼀个数据
                        if (params.dataIndex === 0) {
                            return (
                                "{r|" + params.percent + "%}" + "\n{nm|" + "正常网关" + "}"
                            );
                        }
                        if (params.dataIndex === 1) {
                            return (
                                "{s|" + params.percent + "%}" + "\n{nm|" + "直流供电网关" + "}"
                            );
                        }
                        if (params.dataIndex === 2) {
                            return (
                                "{p|" + params.percent + "%}" + "\n{nm|" + "断电网关" + "}"
                            );
                        }
                        if (params.dataIndex === 3) {
                            return (
                                "{q|" + params.percent + "%}" + "\n{nm|" + "损坏网关" + "}"
                            );
                        }
                    },
                    rich: {
                        p: {
                            color: "#d76da4",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        q: {
                            color: "#a61427",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        r: {
                            color: "#6aa5c7",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        s: {
                            color: "#ba9dc9",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        nm: {
                            fontSize: 12,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                    },
                },
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);
    const [my_data, setData] = useState<AllCommData>();

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      const newOptions = JSON.parse(optionsString);
      newOptions.series[0].data[0].value =
        data.runtime.baseStationPowerCount[0];
      newOptions.series[0].data[1].value =
        data.runtime.baseStationPowerCount[1];
      newOptions.series[0].data[2].value =
        data.runtime.baseStationPowerCount[2];
      newOptions.series[0].data[3].value =
        data.runtime.baseStationPowerCount[3];
      newOptions.series[1].data[0].value = data.runtime.gatewayPowerCount[0];
      newOptions.series[1].data[1].value = data.runtime.gatewayPowerCount[1];
      newOptions.series[1].data[2].value = data.runtime.gatewayPowerCount[2];
      newOptions.series[1].data[3].value = data.runtime.gatewayPowerCount[3];
      setOptions(newOptions);
      setData(data);
    }
  }, [JSON.stringify(data), optionsString]);
  return (
    <div>
      <ReactECharts
        option={chart_options}
        ref={refEcharts}
        style={{ width: "95%", height: "200px", margin: "0 auto" }}
      />
      <div style={{ textAlign: "left", marginLeft: 30 }}>
        <Row>
          <Col span={6}>
            <span className="text-lightblue">正常</span>
          </Col>
          <Col span={9}>
            基站
            <span className="text-lightblue">{`${my_data ? my_data.runtime.baseStationPowerCount[0] : 0
              }`}</span>
            个
          </Col>
          <Col span={9}>
            网关
            <span className="text-lightblue">{`${my_data ? my_data.runtime.gatewayPowerCount[0] : 0
              }`}</span>
            个
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <span className="text-pink">直流电</span>
          </Col>
          <Col span={9}>
            网关
            <span className="text-pink">{`${my_data ? my_data.runtime.gatewayPowerCount[1] : 0
              }`}</span>
            个
          </Col>
          <Col span={9}></Col>
        </Row>
        <Row>
          <Col span={6}>
            <span className="text-purple">断电</span>
          </Col>
          <Col span={9}>
            基站
            <span className="text-purple">{`${my_data ? my_data.runtime.baseStationPowerCount[2] : 0
              }`}</span>
            个
          </Col>
          <Col span={9}>
            网关
            <span className="text-purple">{`${my_data ? my_data.runtime.gatewayPowerCount[2] : 0
              }`}</span>
            个
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <span className="text-red">损坏</span>
          </Col>
          <Col span={9}>
            基站
            <span className="text-red">{`${my_data ? my_data.runtime.baseStationPowerCount[3] : 0
              }`}</span>
            个
          </Col>
          <Col span={9}>
            网关
            <span className="text-red">{`${my_data ? my_data.runtime.gatewayPowerCount[3] : 0
              }`}</span>
            个
          </Col>
        </Row>
      </div>
    </div>
  );
};

export const CommRunMultiPie2 = ({ data }: { data: AllCommData }) => {
    const options = {
        legend: { top: 20 },
        color: ["#6AA5C7", "#A61427"],
        series: [
            {
                type: "pie",
                radius: ["40%", "55%"],
                center: ["25%", "60%"],
                data: [
                    { value: 0.9, name: "低负荷" },
                    { value: 0.1, name: "高负荷" },
                ],
                label: {
                    show: true,
                    position: "center",
                    formatter: function (params: any) {
                        // 默认显⽰第⼀个数据
                        if (params.dataIndex === 0) {
                            return (
                                "{p|" + params.percent + "%}" + "\n{nm|" + "低负荷基站" + "}"
                            );
                        }
                        if (params.dataIndex === 1) {
                            return (
                                "{q|" + params.percent + "%}" + "\n{nm|" + "高负荷基站" + "}"
                            );
                        }
                    },
                    rich: {
                        p: {
                            color: "#6aa5c7",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        q: {
                            color: "#a61427",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        nm: {
                            fontSize: 12,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                    },
                },
            },
            {
                type: "pie",
                radius: ["40%", "55%"],
                center: ["75%", "60%"],
                data: [
                    { value: 0.9, name: "低负荷" },
                    { value: 0.1, name: "高负荷" },
                ],
                label: {
                    show: true,
                    position: "center",
                    formatter: function (params: any) {
                        // 默认显⽰第⼀个数据
                        if (params.dataIndex === 0) {
                            return (
                                "{p|" + params.percent + "%}" + "\n{nm|" + "低负荷基站" + "}"
                            );
                        }
                        if (params.dataIndex === 1) {
                            return (
                                "{q|" + params.percent + "%}" + "\n{nm|" + "高负荷基站" + "}"
                            );
                        }
                    },
                    rich: {
                        p: {
                            color: "#6aa5c7",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        q: {
                            color: "#a61427",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        nm: {
                            fontSize: 12,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                    },
                },
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);
    const [my_data, setData] = useState<AllCommData>();

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      const newOptions = JSON.parse(optionsString);
      newOptions.series[0].data[0].value =
        data.runtime.baseStationNetworkCount[0];
      newOptions.series[0].data[1].value =
        data.runtime.baseStationNetworkCount[1];
      newOptions.series[1].data[0].value =
        data.runtime.gatewayNetworkCount[0];
      newOptions.series[1].data[1].value =
        data.runtime.gatewayNetworkCount[1];

      setOptions(newOptions);
      setData(data);
    }
  }, [JSON.stringify(data), optionsString]);
  return (
    <div>
      <ReactECharts
        option={chart_options}
        ref={refEcharts}
        style={{ width: "95%", height: "200px", margin: "0 auto" }}
      />
      <div style={{ textAlign: "left", marginLeft: 30 }}>
        <Row>
          <Col span={6}>
            <span className="text-lightblue">低负荷</span>
          </Col>
          <Col span={9}>
            基站
            <span className="text-lightblue">{`${my_data ? my_data.runtime.baseStationNetworkCount[0] : 0
              }`}</span>
            个
          </Col>
          <Col span={9}>
            网关
            <span className="text-lightblue">{`${my_data ? my_data.runtime.gatewayNetworkCount[0] : 0
              }`}</span>
            个
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <span className="text-red">高负荷</span>
          </Col>
          <Col span={9}>
            基站
            <span className="text-red">{`${my_data ? my_data.runtime.baseStationNetworkCount[1] : 0
              }`}</span>
            个
          </Col>
          <Col span={9}>
            网关
            <span className="text-red">{`${my_data ? my_data.runtime.gatewayNetworkCount[1] : 0
              }`}</span>
            个
          </Col>
        </Row>
      </div>
    </div>
  );
};

export const BadElecMultiPie = ({ data }: { data: AllElecData }) => {
    const options = {
        legend: { top: 20 },
        color: ["#6AA5C7", "#D76DA4", "#A61427"],
        title: [
            {
                text: "各级变电站工作状态",
                top: -5,
                textStyle: {
                    fontSize: 16,
                },
            },
            {
                text: "500kV变压器",
                left: "24%",
                top: "47%",
                textAlign: "center",
                textStyle: { fontSize: 15 },
            },
            {
                text: "220kV变压器",
                left: "74%",
                top: "47%",
                textAlign: "center",
                textStyle: { fontSize: 15 },
            },
            {
                text: "110kV变压器",
                left: "74%",
                top: "92%",
                textAlign: "center",
                textStyle: { fontSize: 15 },
            },
            {
                text: "10kV变压器",
                left: "24%",
                top: "92%",
                textAlign: "center",
                textStyle: { fontSize: 15 },
            },
        ],
        series: [
            {
                type: "pie",
                radius: ["25%", "32%"],
                center: ["25%", "30%"],
                data: [
                    { value: 0.7, name: "正常" },
                    { value: 0.2, name: "停机" },
                    { value: 0.1, name: "损坏" },
                ],
                label: {
                    show: true,
                    position: "center",
                    formatter: function (params: any) {
                        // 默认显⽰第⼀个数据
                        if (params.dataIndex === 0) {
                            return "{p|" + params.percent + "%}" + "\n{nm|" + "正常" + "}";
                        }
                        if (params.dataIndex === 1) {
                            return "{q|" + params.percent + "%}" + "\n{nm|" + "停机" + "}";
                        }
                        if (params.dataIndex === 2) {
                            return "{r|" + params.percent + "%}" + "\n{nm|" + "损坏" + "}";
                        }
                    },
                    rich: {
                        p: {
                            color: "#6aa5c7",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        q: {
                            color: "#d76da4",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        r: {
                            color: "#a61427",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        nm: {
                            fontSize: 12,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                    },
                },
            },
            {
                type: "pie",
                radius: ["25%", "32%"],
                center: ["75%", "30%"],
                data: [
                    { value: 0.7, name: "正常" },
                    { value: 0, name: "停机" },
                    { value: 0.3, name: "损坏" },
                ],
                label: {
                    show: true,
                    position: "center",
                    formatter: function (params: any) {
                        // 默认显⽰第⼀个数据
                        if (params.dataIndex === 0) {
                            return "{p|" + params.percent + "%}" + "\n{nm|" + "正常" + "}";
                        }
                        if (params.dataIndex === 1) {
                            return "{q|" + params.percent + "%}" + "\n{nm|" + "停机" + "}";
                        }
                        if (params.dataIndex === 2) {
                            return "{r|" + params.percent + "%}" + "\n{nm|" + "损坏" + "}";
                        }
                    },
                    rich: {
                        p: {
                            color: "#6aa5c7",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        q: {
                            color: "#d76da4",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        r: {
                            color: "#a61427",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        nm: {
                            fontSize: 12,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                    },
                },
            },
            {
                type: "pie",
                radius: ["25%", "32%"],
                center: ["25%", "75%"],
                data: [
                    { value: 0, name: "正常" },
                    { value: 0.7, name: "停机" },
                    { value: 0.3, name: "损坏" },
                ],
                label: {
                    show: true,
                    position: "center",
                    formatter: function (params: any) {
                        // 默认显⽰第⼀个数据
                        if (params.dataIndex === 0) {
                            return "{p|" + params.percent + "%}" + "\n{nm|" + "正常" + "}";
                        }
                        if (params.dataIndex === 1) {
                            return "{q|" + params.percent + "%}" + "\n{nm|" + "停机" + "}";
                        }
                        if (params.dataIndex === 2) {
                            return "{r|" + params.percent + "%}" + "\n{nm|" + "损坏" + "}";
                        }
                    },
                    rich: {
                        p: {
                            color: "#6aa5c7",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        q: {
                            color: "#d76da4",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        r: {
                            color: "#a61427",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        nm: {
                            fontSize: 12,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                    },
                },
            },
            {
                type: "pie",
                radius: ["25%", "32%"],
                center: ["75%", "75%"],
                data: [
                    { value: 0.7, name: "正常" },
                    { value: 0.2, name: "停机" },
                    { value: 0.1, name: "损坏" },
                ],
                label: {
                    show: true,
                    position: "center",
                    formatter: function (params: any) {
                        // 默认显⽰第⼀个数据
                        if (params.dataIndex === 0) {
                            return "{p|" + params.percent + "%}" + "\n{nm|" + "正常" + "}";
                        }
                        if (params.dataIndex === 1) {
                            return "{q|" + params.percent + "%}" + "\n{nm|" + "停机" + "}";
                        }
                        if (params.dataIndex === 2) {
                            return "{r|" + params.percent + "%}" + "\n{nm|" + "损坏" + "}";
                        }
                    },
                    rich: {
                        p: {
                            color: "#6aa5c7",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        q: {
                            color: "#d76da4",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        r: {
                            color: "#a61427",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        nm: {
                            fontSize: 12,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                    },
                },
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      const newOptions = JSON.parse(optionsString);
      newOptions.series[0].data[0].value =
        data.abnormalInfo.trans500[0].toFixed(0);
      newOptions.series[0].data[1].value =
        data.abnormalInfo.trans500[1].toFixed(0);
      newOptions.series[0].data[2].value =
        data.abnormalInfo.trans500[2].toFixed(0);
      newOptions.series[1].data[0].value =
        data.abnormalInfo.trans220[0].toFixed(0);
      newOptions.series[1].data[1].value =
        data.abnormalInfo.trans220[1].toFixed(0);
      newOptions.series[1].data[2].value =
        data.abnormalInfo.trans220[2].toFixed(0);
      newOptions.series[2].data[0].value =
        data.abnormalInfo.trans10[0].toFixed(0);
      newOptions.series[2].data[1].value =
        data.abnormalInfo.trans10[1].toFixed(0);
      newOptions.series[2].data[2].value =
        data.abnormalInfo.trans10[2].toFixed(0);
      newOptions.series[3].data[0].value =
        data.abnormalInfo.trans110[0].toFixed(0);
      newOptions.series[3].data[1].value =
        data.abnormalInfo.trans110[1].toFixed(0);
      newOptions.series[3].data[2].value =
        data.abnormalInfo.trans110[2].toFixed(0);

            setOptions(newOptions);
        }
    }, [JSON.stringify(data), optionsString]);
    return (
        <>
            <div
                style={{
                    fontSize: "22px",
                    fontWeight: 500,
                    marginLeft: 10,
                    textAlign: "left",
                }}
            >
                电网异常信息
            </div>
            <ReactECharts
                option={chart_options}
                ref={refEcharts}
                style={{ width: "95%", height: "300px", margin: "0 auto" }}
            />
        </>
    );
};

export const RepairProgressMultiPie = ({ data }: { data: CityRuntimeData }) => {
    const options = {
        legend: { top: 20 },
        color: ["#BA9DC9", "#DDDDDD"],
        title: [
            {
                text: "抢修进度",
                top: -5,
                textStyle: {
                    fontSize: 22,
                },
            },
            {
                text: "电网抢修进度",
                left: "24%",
                top: "47%",
                textAlign: "center",
                textStyle: { fontSize: 15 },
            },
            {
                text: "水网抢修进度",
                left: "74%",
                top: "47%",
                textAlign: "center",
                textStyle: { fontSize: 15 },
            },
            {
                text: "交通抢修进度",
                left: "74%",
                top: "92%",
                textAlign: "center",
                textStyle: { fontSize: 15 },
            },
            {
                text: "通信抢修进度",
                left: "24%",
                top: "92%",
                textAlign: "center",
                textStyle: { fontSize: 15 },
            },
        ],
        series: [
            {
                type: "pie",
                radius: ["25%", "32%"],
                center: ["25%", "30%"],
                data: [
                    { value: 0.7, name: "已抢修" },
                    { value: 0.1, name: "未完成" },
                ],
                label: {
                    show: true,
                    position: "center",
                    formatter: function (params: any) {
                        // 默认显⽰第⼀个数据
                        if (params.dataIndex === 0) {
                            return (
                                "{q|" + params.data.value + "h}" + "\n{nm|" + "已抢修" + "}"
                            );
                        }
                        if (params.dataIndex === 1) {
                            return (
                                "{r|" + params.data.value + "h}" + "\n{nm|" + "未完成" + "}"
                            );
                        }
                    },
                    rich: {
                        p: {
                            color: "#A61427",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        q: {
                            color: "#BA9DC9",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        r: {
                            color: "#DDDDDD",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        nm: {
                            fontSize: 12,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                    },
                },
            },
            {
                type: "pie",
                radius: ["25%", "32%"],
                center: ["75%", "30%"],
                data: [
                    { value: 0.7, name: "已抢修" },
                    { value: 0.1, name: "未完成" },
                ],
                label: {
                    show: true,
                    position: "center",
                    formatter: function (params: any) {
                        // 默认显⽰第⼀个数据
                        if (params.dataIndex === 0) {
                            return (
                                "{q|" + params.data.value + "h}" + "\n{nm|" + "已抢修" + "}"
                            );
                        }
                        if (params.dataIndex === 1) {
                            return (
                                "{r|" + params.data.value + "h}" + "\n{nm|" + "未完成" + "}"
                            );
                        }
                    },
                    rich: {
                        p: {
                            color: "#A61427",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        q: {
                            color: "#BA9DC9",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        r: {
                            color: "#DDDDDD",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        nm: {
                            fontSize: 12,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                    },
                },
            },
            {
                type: "pie",
                radius: ["25%", "32%"],
                center: ["25%", "75%"],
                data: [
                    { value: 0.7, name: "已抢修" },
                    { value: 0.1, name: "未完成" },
                ],
                label: {
                    show: true,
                    position: "center",
                    formatter: function (params: any) {
                        // 默认显⽰第⼀个数据
                        if (params.dataIndex === 0) {
                            return (
                                "{q|" + params.data.value + "h}" + "\n{nm|" + "已抢修" + "}"
                            );
                        }
                        if (params.dataIndex === 1) {
                            return (
                                "{r|" + params.data.value + "h}" + "\n{nm|" + "未完成" + "}"
                            );
                        }
                    },
                    rich: {
                        p: {
                            color: "#A61427",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        q: {
                            color: "#BA9DC9",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        r: {
                            color: "#DDDDDD",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        nm: {
                            fontSize: 12,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                    },
                },
            },
            {
                type: "pie",
                radius: ["25%", "32%"],
                center: ["75%", "75%"],
                data: [
                    { value: 0.7, name: "已抢修" },
                    { value: 0.1, name: "未完成" },
                ],
                label: {
                    show: true,
                    position: "center",
                    formatter: function (params: any) {
                        // 默认显⽰第⼀个数据
                        if (params.dataIndex === 0) {
                            return (
                                "{q|" + params.data.value + "h}" + "\n{nm|" + "已抢修" + "}"
                            );
                        }
                        if (params.dataIndex === 1) {
                            return (
                                "{r|" + params.data.value + "h}" + "\n{nm|" + "未完成" + "}"
                            );
                        }
                    },
                    rich: {
                        p: {
                            color: "#A61427",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        q: {
                            color: "#BA9DC9",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        r: {
                            color: "#DDDDDD",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                        nm: {
                            fontSize: 12,
                            backgroundColor: "white", // 覆盖index=0时的数据
                        },
                    },
                },
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);

  const refEcharts = useRef<ReactECharts>(null);
  useEffect(() => {
    if (data) {
      const newOptions = JSON.parse(optionsString);
      newOptions.series[0].data[0].value = data.repairTime.elec[0].toFixed(1);
      newOptions.series[0].data[1].value = data.repairTime.elec[1].toFixed(1);
      newOptions.series[1].data[0].value = data.repairTime.water[0].toFixed(1);
      newOptions.series[1].data[1].value = data.repairTime.water[1].toFixed(1);
      newOptions.series[3].data[0].value = data.repairTime.comm[0].toFixed(1);
      newOptions.series[3].data[1].value = data.repairTime.comm[1].toFixed(1);
      newOptions.series[2].data[0].value =
        data.repairTime.traffic[0].toFixed(1);
      newOptions.series[2].data[1].value =
        data.repairTime.traffic[1].toFixed(1);

            setOptions(newOptions);
        }
    }, [JSON.stringify(data), optionsString]);
    return (
        <ReactECharts
            option={chart_options}
            ref={refEcharts}
            style={{ width: "95%", height: "300px", marginTop: 20 }}
        />
    );
};

export const ElecBadMultiPie = ({ data }: { data: any }) => {
    const options = {
        legend: { top: 20 },
        color: ["#A61427", "#DDDDDD"],
        title: [
            {
                text: "电网受损情况",
                textStyle: {
                    fontSize: 16,
                },
            },
            {
                text: "500kV变压器",
                left: "24%",
                top: "47%",
                textAlign: "center",
                textStyle: { fontSize: 15 },
            },
            {
                text: "220kV变压器",
                left: "74%",
                top: "47%",
                textAlign: "center",
                textStyle: { fontSize: 15 },
            },
            {
                text: "110kV变压器",
                left: "74%",
                top: "92%",
                textAlign: "center",
                textStyle: { fontSize: 15 },
            },
            {
                text: "10kV变压器",
                left: "24%",
                top: "92%",
                textAlign: "center",
                textStyle: { fontSize: 15 },
            },
        ],
        series: [
            {
                type: "pie",
                radius: ["25%", "32%"],
                center: ["25%", "30%"],
                data: [
                    { value: 0.7, name: "损坏" },
                    { value: 0.3, name: "正常" },
                ],
                label: { show: false },
            },
            {
                type: "pie",
                radius: ["25%", "32%"],
                center: ["75%", "30%"],
                data: [
                    { value: 0.7, name: "损坏" },
                    { value: 0.3, name: "正常" },
                ],
                label: { show: false },
            },
            {
                type: "pie",
                radius: ["25%", "32%"],
                center: ["25%", "75%"],
                data: [
                    { value: 0.7, name: "损坏" },
                    { value: 0.3, name: "正常" },
                ],
                label: { show: false },
            },
            {
                type: "pie",
                radius: ["25%", "32%"],
                center: ["75%", "75%"],
                data: [
                    { value: 0.7, name: "损坏" },
                    { value: 0.3, name: "正常" },
                ],
                label: { show: false },
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);

    const refEcharts = useRef<ReactECharts>(null);
    useEffect(() => {
        if (data) {
            const newOptions = JSON.parse(optionsString);
            newOptions.series[0].data[0].value = (1 - data.elec).toFixed(2);
            newOptions.series[0].data[1].value = data.elec.toFixed(2);
            newOptions.series[1].data[0].value = (1 - data.water).toFixed(2);
            newOptions.series[1].data[1].value = data.water.toFixed(2);
            newOptions.series[3].data[0].value = (1 - data.comm).toFixed(2);
            newOptions.series[3].data[1].value = data.comm.toFixed(2);
            newOptions.series[2].data[0].value = (1 - data.traffic).toFixed(2);
            newOptions.series[2].data[1].value = data.traffic.toFixed(2);

            setOptions(newOptions);
        }
    }, [JSON.stringify(data), optionsString]);
    return (
        <div style={{ marginTop: 20 }}>
            <div
                style={{
                    fontSize: "22px",
                    fontWeight: 500,
                    textAlign: "left",
                    marginLeft: 10,
                }}
            >
                城市需求满足情况
            </div>
            <ReactECharts
                option={chart_options}
                ref={refEcharts}
                style={{ width: "95%", height: "300px", margin: "0 auto" }}
            />
        </div>
    );
};

export const MultiPie = ({ data }: { data: any }) => {
    const options = {
        legend: {},
        color: ["#9C1423", "#D8D8D8"],
        title: [
            {
                text: "用电需求不满",
                left: "24%",
                top: "12%",
                textAlign: "center",
                textStyle: { fontSize: 15 },
            },
            {
                text: "用水需求不满",
                left: "74%",
                top: "12%",
                textAlign: "center",
                textStyle: { fontSize: 15 },
            },
            {
                text: "通信需求不满",
                left: "74%",
                top: "57%",
                textAlign: "center",
                textStyle: { fontSize: 15 },
            },
            {
                text: "交通需求不满",
                left: "24%",
                top: "57%",
                textAlign: "center",
                textStyle: { fontSize: 15 },
            },
        ],
        series: [
            {
                roseType: "radius",
                type: "pie",
                radius: ["30%", "40%"],
                center: ["25%", "30%"],
                data: [
                    { value: 0.7, name: "满足" },
                    { value: 0.3, name: "不满" },
                ],
                labelLine: { length: 0, length2: 0 },
            },
            {
                type: "pie",
                radius: "20%",
                center: ["75%", "30%"],
                data: [
                    { value: 1, name: "满足" },
                    { value: 0, name: "" },
                ],
                labelLine: { length: 0, length2: 0 },
            },
            {
                type: "pie",
                radius: "20%",
                center: ["25%", "75%"],
                data: [
                    { value: 1, name: "满足" },
                    { value: 0, name: "" },
                ],
                labelLine: { length: 0, length2: 0 },
            },
            {
                type: "pie",
                radius: "20%",
                center: ["75%", "75%"],
                data: [
                    { value: 1, name: "满足" },
                    { value: 0, name: "" },
                ],
                labelLine: { length: 0, length2: 0 },
            },
        ],
    };
    const optionsString = JSON.stringify(options);
    const [chart_options, setOptions] = useState<any>(options);

    const refEcharts = useRef<ReactECharts>(null);
    useEffect(() => {
        if (data) {
            const newOptions = JSON.parse(optionsString);
            newOptions.series[0].data[0].value = (1 - data.elec).toFixed(2);
            newOptions.series[0].data[1].value = data.elec.toFixed(2);
            newOptions.series[1].data[0].value = (1 - data.water).toFixed(2);
            newOptions.series[1].data[1].value = data.water.toFixed(2);
            newOptions.series[3].data[0].value = (1 - data.comm).toFixed(2);
            newOptions.series[3].data[1].value = data.comm.toFixed(2);
            newOptions.series[2].data[0].value = (1 - data.traffic).toFixed(2);
            newOptions.series[2].data[1].value = data.traffic.toFixed(2);

            setOptions(newOptions);
        }
    }, [JSON.stringify(data), optionsString]);
    return (
        <ReactECharts
            option={chart_options}
            ref={refEcharts}
            style={{ width: "95%", height: "300px", margin: "0 auto" }}
        />
    );
};

export const CityMultiCard = () => {
    return (
        <>
            <div style={{ marginBottom: 20 }}>
                <div
                    style={{
                        fontSize: "22px",
                        fontWeight: 500,
                        marginLeft: 10,
                        textAlign: "left",
                    }}
                >
                    城市概况
                </div>
                <Row>
                    <Col span={12}>
                        <div className="card-blue">
                            <div className="inner-card">
                                <Avatar
                                    style={{ float: "left" }}
                                    shape="square"
                                    size="large"
                                    src="城市面积.svg"
                                />
                                <div style={{ marginLeft: 10, textAlign: "left" }}>
                                    <div>模拟面积</div>
                                    <div>
                                        <span className="text-blue">667</span>
                                        {" km²"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="card-blue">
                            <div className="inner-card">
                                <Avatar
                                    style={{ float: "left" }}
                                    shape="square"
                                    size="large"
                                    src="城市行政区数.svg"
                                />
                                <div style={{ marginLeft: 10, textAlign: "left" }}>
                                    <div>行政区数</div>
                                    <div>
                                        <span className="text-blue">6</span>
                                        {" 个"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <div className="card-blue">
                            <div className="inner-card">
                                <Avatar
                                    style={{ float: "left" }}
                                    shape="square"
                                    size="large"
                                    src="城区总人数.svg"
                                />
                                <div style={{ marginLeft: 10, textAlign: "left" }}>
                                    <div>总人口</div>
                                    <div>
                                        <span className="text-blue">1052</span>
                                        {" 万人"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="card-blue">
                            <div className="inner-card">
                                <Avatar
                                    style={{ float: "left" }}
                                    shape="square"
                                    size="large"
                                    src="机动车保有量.svg"
                                />
                                <div style={{ marginLeft: 10, textAlign: "left" }}>
                                    <div>机动车保有量</div>
                                    <div>
                                        <span className="text-blue">394</span>
                                        {" 万辆"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="card-blue">
                            <div className="inner-card">
                                <Avatar
                                    style={{ float: "left" }}
                                    shape="square"
                                    size="large"
                                    src="总道路数.svg"
                                />
                                <div style={{ marginLeft: 10, textAlign: "left" }}>
                                    <div>总道路数</div>
                                    <div>
                                        <span className="text-blue">17043</span>
                                        {" 条"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="card-blue">
                            <div className="inner-card">
                                <Avatar
                                    style={{ float: "left" }}
                                    shape="square"
                                    size="large"
                                    src="城市总AOI.svg"
                                />
                                <div style={{ marginLeft: 10, textAlign: "left" }}>
                                    <div>城市总AOI数</div>
                                    <div>
                                        <span className="text-blue">33047</span>
                                        {" 个"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export const CityBasicMultiCard = () => {
    return (
        <>
            <div
                style={{
                    fontSize: "22px",
                    fontWeight: 500,
                    textAlign: "left",
                    marginLeft: 10,
                }}
            >
                城市基础信息
            </div>
            <div style={{ marginBottom: 20 }}>
                <div
                    style={{
                        fontSize: "16px",
                        fontWeight: 500,
                        marginLeft: 10,
                        textAlign: "left",
                    }}
                >
                    城市基础设施信息
                </div>
                <Row>
                    <Col span={12}>
                        <div className="card-blue">
                            <div className="inner-card">
                                <Avatar
                                    style={{ float: "left" }}
                                    shape="square"
                                    size="large"
                                    src="总道路数.svg"
                                />
                                <div style={{ marginLeft: 10, textAlign: "left" }}>
                                    <div>总道路数</div>
                                    <div>
                                        <span className="text-blue">17043</span>
                                        {" 条"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="card-blue">
                            <div className="inner-card">
                                <Avatar
                                    style={{ float: "left" }}
                                    shape="square"
                                    size="large"
                                    src="城市总AOI.svg"
                                />
                                <div style={{ marginLeft: 10, textAlign: "left" }}>
                                    <div>城市总AOI数</div>
                                    <div>
                                        <span className="text-blue">33047</span>
                                        {" 个"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <div className="card-blue">
                            <div className="inner-card">
                                <Avatar
                                    style={{ float: "left" }}
                                    shape="square"
                                    size="large"
                                    src="基站总数-blue.svg"
                                />
                                <div style={{ marginLeft: 10, textAlign: "left" }}>
                                    <div>基站总数</div>
                                    <div>
                                        <span className="text-blue">3880</span>
                                        {" 个"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="card-blue">
                            <div className="inner-card">
                                <Avatar
                                    style={{ float: "left" }}
                                    shape="square"
                                    size="large"
                                    src="网关总数-blue.svg"
                                />
                                <div style={{ marginLeft: 10, textAlign: "left" }}>
                                    <div>网关总数</div>
                                    <div>
                                        <span className="text-blue">25</span>
                                        {" 个"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <div className="card-blue">
                            <div className="inner-card">
                                <Avatar
                                    style={{ float: "left" }}
                                    shape="square"
                                    size="large"
                                    src="变压器-550kv.svg"
                                />
                                <div style={{ marginLeft: 10, textAlign: "left" }}>
                                    <div>500kV变压器</div>
                                    <div>
                                        <span className="text-blue">16</span>
                                        {" 个"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="card-blue">
                            <div className="inner-card">
                                <Avatar
                                    style={{ float: "left" }}
                                    shape="square"
                                    size="large"
                                    src="变压器-220kv.svg"
                                />
                                <div style={{ marginLeft: 10, textAlign: "left" }}>
                                    <div>220kV变压器</div>
                                    <div>
                                        <span className="text-blue">100</span>
                                        {" 个"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <div className="card-blue">
                            <div className="inner-card">
                                <Avatar
                                    style={{ float: "left" }}
                                    shape="square"
                                    size="large"
                                    src="变压器-110kv.svg"
                                />
                                <div style={{ marginLeft: 10, textAlign: "left" }}>
                                    <div>110kV变压器</div>
                                    <div>
                                        <span className="text-blue">535</span>
                                        {" 个"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="card-blue">
                            <div className="inner-card">
                                <Avatar
                                    style={{ float: "left" }}
                                    shape="square"
                                    size="large"
                                    src="变压器-10kv.svg"
                                />
                                <div style={{ marginLeft: 10, textAlign: "left" }}>
                                    <div>10kV变压器</div>
                                    <div>
                                        <span className="text-blue">10227</span>
                                        {" 个"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <div className="card-blue">
                            <div className="inner-card">
                                <Avatar
                                    style={{ float: "left" }}
                                    shape="square"
                                    size="large"
                                    src="供水管网数.svg"
                                />
                                <div style={{ marginLeft: 10, textAlign: "left" }}>
                                    <div>供水管网数</div>
                                    <div>
                                        <span className="text-blue">21385</span>
                                        {" 条"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="card-blue">
                            <div className="inner-card">
                                <Avatar
                                    style={{ float: "left" }}
                                    shape="square"
                                    size="large"
                                    src="排水管网数.svg"
                                />
                                <div style={{ marginLeft: 10, textAlign: "left" }}>
                                    <div>排水管网数</div>
                                    <div>
                                        <span className="text-blue">13466</span>
                                        {" 条"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export const TrafficMultiCard = ({ data }: { data: AllTrafficData }) => {
  const [my_data, setData] = useState<AllTrafficData>();
  useEffect(() => {
    if (data) {
      setData(data);
    }
  }, [JSON.stringify(data)]);
  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <div
          style={{
            fontSize: "22px",
            fontWeight: 500,
            marginLeft: 10,
            textAlign: "left",
          }}
        >
          交通基础信息
        </div>
        <Row>
          <Col span={12}>
            <div className="card-blue">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="城区总人数.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>城区总人数</div>
                  <div>
                    <span className="text-blue">1052</span>
                    {" 万人"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="card-blue">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="总道路数.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>总道路数</div>
                  <div>
                    <span className="text-blue">17043</span>
                    {" 条"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className="card-blue">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="在途车辆数.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>在途车辆数</div>
                  <div>
                    <span className="text-blue">{`${my_data
                      ? (my_data.basicInfo.numVehicle / 10000).toFixed(1)
                      : 0
                      }`}</span>
                    {" 万辆"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="card-blue">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="全局平均车速.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>全局平均车速</div>
                  <div>
                    <span className="text-blue">{`${my_data
                      ? my_data.basicInfo.vehicleAverageSpeed.toFixed(2)
                      : 0
                      }`}</span>
                    {" km/h"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className="card-blue">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="在途人数.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>在途总人数</div>
                  <div>
                    <span className="text-blue">{`${my_data
                      ? (
                        my_data.basicInfo.numPersonOutOfAoi / 10000
                      ).toFixed(1)
                      : 0
                      }`}</span>
                    {" 万人"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="card-blue">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="城区总人数.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>AOI总人数</div>
                  <div>
                    <span className="text-blue">{`${my_data
                      ? (
                        my_data.basicInfo.numPersonInAoi / 10000
                      ).toFixed(1)
                      : 0
                      }`}</span>
                    {" 万人"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export const TrafficMultiCard2 = ({ data }: { data: AllTrafficData }) => {
  const [my_data, setData] = useState<AllTrafficData>();
  useEffect(() => {
    if (data) {
      setData(data);
    }
  }, [JSON.stringify(data)]);
  return (
    <>
      <div
        style={{
          fontSize: "16px",
          fontWeight: 500,
          marginLeft: 10,
          textAlign: "left",
        }}
      >
        交通基础设施异常情况
      </div>
      <div style={{ marginBottom: 20 }}>
        <Row>
          <Col span={12}>
            <div className="card-blue">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="道路中断数.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>道路中断数</div>
                  <div>
                    <span className="text-blue">{`${my_data
                      ? my_data.abnormalInfo.facility.numInterruptedRoad
                      : 0
                      }`}</span>
                    {" 条"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="card-blue">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="红绿灯中断数.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>红绿灯断电数</div>
                  <div>
                    <span className="text-blue">{`${my_data
                      ? my_data.abnormalInfo.facility.numBadTrafficLight
                      : 0
                      }`}</span>
                    {" 个"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className="card-blue">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="道路积水.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>积水道路数</div>
                  <div>
                    <span className="text-blue">{`${my_data
                      ? my_data.abnormalInfo.facility.numRoadWithWater
                      : 0
                      }`}</span>
                    {" 条"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="card-blue">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="道路受损.png"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>限行道路数</div>
                  <div>
                    <span className="text-blue">{`${my_data
                      ? my_data.abnormalInfo.facility.numRestrictedRoad
                      : 0
                      }`}</span>
                    {" 条"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export const WaterMultiCard = ({ data }: { data: AllWaterData }) => {
  const [my_data, setData] = useState<AllWaterData>();
  useEffect(() => {
    if (data) {
      setData(data);
    }
  }, [JSON.stringify(data)]);
  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <div
          style={{
            fontSize: "22px",
            fontWeight: 500,
            marginLeft: 10,
            textAlign: "left",
          }}
        >
          水网基础信息
        </div>
        <div
          style={{
            fontSize: "16px",
            fontWeight: 500,
            marginLeft: 10,
            textAlign: "left",
          }}
        >
          排水网基础信息
        </div>
        <Row>
          <Col span={12}>
            <div className="card-lightblue">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="排水管网数-lightblue.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>排水管网数</div>
                  <div>
                    <span className="text-lightblue">13466</span>
                    {" 条"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="card-lightblue">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="排水节点数.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>排水节点数</div>
                  <div>
                    <span className="text-lightblue">17337</span>
                    {" 个"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className="card-lightblue">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="排水管泵数.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>排水水泵数</div>
                  <div>
                    <span className="text-lightblue">2285</span>
                    {" 个"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="card-lightblue">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="排水管泵耗能.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>排水泵平均耗能</div>
                  <div>
                    <span className="text-lightblue">{`${my_data
                      ? my_data.drainageBasicInfo.averagePower.toFixed(1)
                      : 0
                      }`}</span>
                    {" kW"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className="card-lightblue">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="蓄水节点储水量.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>待排水水量</div>
                  <div>
                    <span className="text-lightblue">{`${my_data
                      ? my_data.drainageBasicInfo.undrainedVolume.toFixed(
                        1
                      )
                      : 0
                      }`}</span>
                    {" m³"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="card-lightblue">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="已排水水量.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>已排水水量</div>
                  <div>
                    <span className="text-lightblue">{`${my_data
                      ? (
                        my_data.drainageBasicInfo.drainedVolume / 10000
                      ).toFixed(0)
                      : 0
                      }`}</span>
                    {" 万m³"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className="card-lightblue">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="排水网平均流量.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>排水网平均流量</div>
                  <div>
                    <span className="text-lightblue">{`${my_data
                      ? my_data.drainageBasicInfo.averageFlow.toFixed(1)
                      : 0
                      }`}</span>
                    {" m³/s"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="card-lightblue">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="积水水量.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>积水水量</div>
                  <div>
                    <span className="text-lightblue">{`${my_data
                      ? my_data.drainageBasicInfo.floodedVolume.toFixed(1)
                      : 0
                      }`}</span>
                    {" m³"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export const WaterMultiCard2 = ({ data }: { data: AllWaterData }) => {
  const [my_data, setData] = useState<AllWaterData>();
  useEffect(() => {
    if (data) {
      setData(data);
    }
  }, [JSON.stringify(data)]);
  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <div
          style={{
            fontSize: "15px",
            fontWeight: 500,
            marginLeft: 10,
            textAlign: "left",
          }}
        >
          供水网基础信息
        </div>
        <Row>
          <Col span={12}>
            <div className="card-lightblue">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="供水管网数-lightblue.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>供水管网数</div>
                  <div>
                    <span className="text-lightblue">21385</span>
                    {" 条"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="card-lightblue">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="供水节点数.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>供水节点数</div>
                  <div>
                    <span className="text-lightblue">16214</span>
                    {" 个"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className="card-lightblue">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="供水管泵数.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>供水水泵数</div>
                  <div>
                    <span className="text-lightblue">34</span>
                    {" 个"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="card-lightblue">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="供水管泵耗能.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>供水泵平均耗能</div>
                  <div>
                    <span className="text-lightblue">{`${my_data
                      ? my_data.supplyBasicInfo.averagePower.toFixed(1)
                      : 0
                      }`}</span>
                    {" kW"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className="card-lightblue">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="供水网平均流量.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>供水网平均流量</div>
                  <div>
                    <span className="text-lightblue">{`${my_data
                      ? (
                        my_data.supplyBasicInfo.averageFlow * 1000
                      ).toFixed(1)
                      : 0
                      }`}</span>
                    {" L/s"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col span={12}></Col>
        </Row>
      </div>
    </>
  );
};

export const CommMultiCard = ({ data }: { data: AllCommData }) => {
  const [my_data, setData] = useState<AllCommData>();
  useEffect(() => {
    if (data) {
      setData(data);
    }
  }, [JSON.stringify(data)]);
  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <div
          style={{
            fontSize: "22px",
            fontWeight: 500,
            marginLeft: 10,
            textAlign: "left",
          }}
        >
          通信基础信息
        </div>
        <Row>
          <Col span={12}>
            <div className="card-purple">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="基站总数.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>基站总数</div>
                  <div>
                    <span className="text-purple">3880</span>
                    {" 个"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="card-purple">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="网关总数.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>网关总数</div>
                  <div>
                    <span className="text-purple">25</span>
                    {" 个"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className="card-purple">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="接入用户数.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>接入用户数</div>
                  <div>
                    <span className="text-purple">{`${my_data ? my_data.basicInfo.numActiveAgent : 0
                      }`}</span>
                    {" 人"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="card-purple">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="通信中断.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>中断用户数</div>
                  <div>
                    <span className="text-purple">{`${my_data ? my_data.basicInfo.numOutageAgent : 0
                      }`}</span>
                    {" 人"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className="card-purple">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="基站耗电量.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>基站耗电量</div>
                  <div>
                    <span className="text-purple">{`${my_data
                      ? my_data.basicInfo.powerConsumption.toFixed(1)
                      : 0
                      }`}</span>
                    {" kWh"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="card-purple">
              <div className="inner-card">
                <Avatar
                  style={{ float: "left" }}
                  shape="square"
                  size="large"
                  src="用户通信总需求.svg"
                />
                <div style={{ marginLeft: 10, textAlign: "left" }}>
                  <div>用户通信需求</div>
                  <div>
                    <span className="text-purple">{`${my_data
                      ? (my_data.basicInfo.demandFlow / 1000000000).toFixed(
                        1
                      )
                      : 0
                      }`}</span>
                    {" GB"}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export const StatisticsPlayer: React.FC<{
    playerManager: PlayerManager;
    index: number;
    currentTab: string;
    chooseObj: {
        left: string,
        right: string
    }
}> = ({
    playerManager,
    index,
    currentTab,
    chooseObj
}: {
    playerManager: PlayerManager;
    index: number;
    currentTab: string,
    chooseObj: {
        left: string,
        right: string
    }
}) => {
    let currentName = ""
    if (index == 0) {
      currentName = chooseObj.left
    } else if (index == 1) {
      currentName = chooseObj.right
    }
    //new_data
    const all_elec_data = useRef<AllElecData>({
      step: 0,
      basicInfo: {
        stationCnt: [1, 2, 3, 4],
        averagePower: [1, 2, 3, 4],
      },
      demand: {
        userDemand: 1,
        userSatisfiedCnt: 100000,
        userSatisfiedRatio: 0.6,
        aoiDemand: 1,
        aoiSatisfiedCnt: 100000,
        aoiSatisfiedRatio: 0.6,
      },
      abnormalInfo: {
        chaoyang: 100,
        dongcheng: 200,
        fengtai: 50,
        haidian: 10,
        shijingshan: 100,
        xicheng: 100,
        trans500: [1, 2, 3, 4],
        trans220: [1, 2, 3, 4],
        trans10: [1, 2, 3, 4],
        trans110: [1, 2, 3, 4],
      },
    });
    const all_water_data = useRef<AllWaterData>();
    const all_traffic_data = useRef<AllTrafficData>();
    const all_comm_data = useRef<AllCommData>();
    const city_overview_data = useRef<CtiyOverviewData>();
    const city_runtime_data = useRef<CityRuntimeData>();

        const road_data = useRef<any>(undefined);
        const water_road_data = useRef<any>(undefined);
        const comm_demand_data = useRef<any>(undefined);

        const water_drainage_link_data = useRef<any>(undefined);
        const water_supply_link_data = useRef<any>(undefined);
        const elec_node_data = useRef<any>(undefined);
        const city_metric_data = useRef<any>(undefined);
        const demand_metric_data = useRef<any>(undefined);
        const elec_demand_data = useRef<any>(undefined);
        const comm_node_data = useRef<any>(undefined);
        const comm_data = useRef<any>(undefined);
        const events_data = useRef<any>(undefined);
        const traffic_metric_data = useRef<any>(undefined);


        const [cur, setCur] = useState("total")

        useEffect(() => {
            const roadPlayer = new RoadMetricPlayer((d) => {
                road_data.current = d;
            });
            playerManager.attachSubCrowdPlayer(`roadMetric_${index}`, roadPlayer, true);
        }, []);
        useEffect(() => {
            const waterRoadPlayer = new RoadMetricPlayer((d) => {
                water_road_data.current = d;
            });
            playerManager.attachSubCrowdPlayer(
                `waterRoadMetric_${index}`,
                waterRoadPlayer,
                true
            );
        }, []);
        useEffect(() => {
            const roadPlayer = new RoadMetricPlayer((d) => {
                console.log(d);
                comm_demand_data.current = d;
            });
            playerManager.attachSubCrowdPlayer(
                `commDemandMetric_${index}`,
                roadPlayer,
                true
            );
        }, []);

        useEffect(() => {
            const waterLinkPlayer = new WaterLinkMetricPlayer((d) => {
                water_drainage_link_data.current = d;
            });
            playerManager.attachSubCrowdPlayer(
                `waterDrainageLinkMetric_${index}`,
                waterLinkPlayer,
                true
            );
        }, []);

        useEffect(() => {
            const waterLinkPlayer = new WaterLinkMetricPlayer((d) => {
                water_supply_link_data.current = d;
            });
            playerManager.attachSubCrowdPlayer(
                `waterSupplyLinkMetric_${index}`,
                waterLinkPlayer,
                true
            );
        }, []);

        useEffect(() => {
            const elecNodePlayer = new WaterLinkMetricPlayer((d) => {
                elec_node_data.current = d;
            });
            playerManager.attachSubCrowdPlayer(
                `elecNodeMetric_${index}`,
                elecNodePlayer,
                true
            );
        }, []);

        useEffect(() => {
            const commMetricPlayer = new WaterLinkMetricPlayer((d) => {
                comm_data.current = d;
            });
            playerManager.attachSubCrowdPlayer(
                `commMetric_${index}`,
                commMetricPlayer,
                true
            );
        }, []);

        useEffect(() => {
            const commNodePlayer = new WaterLinkMetricPlayer((d) => {
                comm_node_data.current = d;
            });
            playerManager.attachSubCrowdPlayer(
                `commNodeMetric_${index}`,
                commNodePlayer,
                true
            );
        }, []);

        useEffect(() => {
            const elecDemandPlayer = new WaterLinkMetricPlayer((d) => {
                elec_demand_data.current = d;
            });
            playerManager.attachSubCrowdPlayer(
                `elecDemandMetric_${index}`,
                elecDemandPlayer,
                true
            );
        }, []);

        useEffect(() => {
            const elecDemandPlayer = new WaterLinkMetricPlayer((d) => {
                all_water_data.current = d;
            });
            playerManager.attachSubCrowdPlayer(
                `waterState_${index}`,
                elecDemandPlayer,
                true
            );
        }, []);

        useEffect(() => {
            const elecDemandPlayer = new WaterLinkMetricPlayer((d) => {
                city_runtime_data.current = d;
            });
            playerManager.attachSubCrowdPlayer(
                `cityRuntime_${index}`,
                elecDemandPlayer,
                true
            );
        }, []);

        useEffect(() => {
            const elecDemandPlayer = new WaterLinkMetricPlayer((d) => {
                all_traffic_data.current = d;
            });
            playerManager.attachSubCrowdPlayer(
                `trafficState_${index}`,
                elecDemandPlayer,
                true
            );
        }, []);

        useEffect(() => {
            const elecDemandPlayer = new WaterLinkMetricPlayer((d) => {
                all_comm_data.current = d;
            });
            playerManager.attachSubCrowdPlayer(
                `commState_${index}`,
                elecDemandPlayer,
                true
            );
        }, []);

        useEffect(() => {
            const elecDemandPlayer = new WaterLinkMetricPlayer((d) => {
                city_overview_data.current = d;
            });
            playerManager.attachSubCrowdPlayer(
                `cityOverview_${index}`,
                elecDemandPlayer,
                true
            );
        }, []);

        useEffect(() => {
            const elecDemandPlayer = new WaterLinkMetricPlayer((d) => {
                all_elec_data.current = d;
            });
            playerManager.attachSubCrowdPlayer(
                `elecState_${index}`,
                elecDemandPlayer,
                true
            );
        }, []);

        useEffect(() => {
            const eventsPlayer = new WaterLinkMetricPlayer((d) => {
                events_data.current = d;
            });
            playerManager.attachSubCrowdPlayer(`event_${index}`, eventsPlayer, true);
        }, []);

        useEffect(() => {
            const tmPlayer = new WaterLinkMetricPlayer((d) => {
                traffic_metric_data.current = d;
            });
            playerManager.attachSubCrowdPlayer(
                `trafficMetric_${index}`,
                tmPlayer,
                true
            );
        }, []);

        useEffect(() => {
            const tmPlayer = new WaterLinkMetricPlayer((d) => {
                city_metric_data.current = d;
            });
            playerManager.attachSubCrowdPlayer(`cityMetric_${index}`, tmPlayer, true);
        }, []);

        useEffect(() => {
            const tmPlayer = new WaterLinkMetricPlayer((d) => {
                demand_metric_data.current = d;
            });
            playerManager.attachSubCrowdPlayer(`demandMetric_${index}`, tmPlayer, true);
        }, []);



        useEffect(() => {
            if (currentTab == "aoiSelector") {
                if ((index == 0 && chooseObj.left) || (index == 1 && chooseObj.right)) {
                    setCur("aoiChose")
                }
            } else if (currentTab == "roadCanChose") {
                if ((index == 0 && chooseObj.left) || (index == 1 && chooseObj.right)) {
                    setCur("roadChose")
                }
            } else {
                setCur("total")
            }
        }, [currentTab, chooseObj])

        const handleClose = () => {
            if ((index == 0 && chooseObj.left) || (index == 1 && chooseObj.right)) {
                setCur(" ")
            }
        }

        return (
            <>
                {/* <Tabs> */}
                    {/* <Tabs.TabPane tab="城市概况" key="total">
                        <div style={{ overflowY: "auto", maxHeight: "80vh" }}>

                            <CityLandRadar />
                            <CityPeopleRadar data={city_overview_data.current!} />
                            <CityBasicMultiCard />
                            <CityAOIRadar />
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="城市运行情况" key="run">
                        <div style={{ overflowY: "auto", maxHeight: "80vh" }}>
                            <PressureGauge data={city_runtime_data.current!}></PressureGauge>
                            <PanicGauge data={city_runtime_data.current!}></PanicGauge>
                            <MultiProgressBar data={city_runtime_data.current!} />
                            <EventEcharts data={events_data.current}></EventEcharts>
                            <RepairResourceMultiPie data={city_runtime_data.current!} />
                            <RepairProgressMultiPie data={city_runtime_data.current!} />
                        </div>
                    </Tabs.TabPane> */}
                    {/* <Tabs.TabPane tab={"交通"} key={"road"}> */}
                        <div style={{ overflowY: "auto", maxHeight: "80vh" }}>
                            <CityMultiCard />
                            {/* <TrafficMultiCard data={all_traffic_data.current!} /> */}
                            <TrafficGauge data={all_traffic_data.current!} />
                            <TrafficBar1 data={all_traffic_data.current!} />
                            <RoadMetricEcharts data={road_data.current} />
                            {/* <TrafficMultiCard2 data={all_traffic_data.current!} />
                            <TrafficBar3 data={all_traffic_data.current!} />
                            <div
                                style={{
                                    fontSize: "22px",
                                    fontWeight: 500,
                                    marginLeft: 10,
                                    marginTop: 20,
                                    textAlign: "left",
                                }}
                            >
                                异常路况明细
                            </div>
                            <RoadMetricRadar data={all_traffic_data.current!} />
                            <RoadMetricTable data={all_traffic_data.current!} />
                            

                            <WaterRoadMetricRadar data={all_traffic_data.current!} />
                            <WaterRoadMetricTable data={all_traffic_data.current!} />
                            <WaterRoadMetricEcharts data={water_road_data.current!} /> */}
                        </div>
                    {/* </Tabs.TabPane> */}
                    {/* <Tabs.TabPane tab={"水网"} key={"drainage"}>
                        <div style={{ overflowY: "auto", maxHeight: "80vh" }}>
                            <WaterMultiCard data={all_water_data.current!} />
                            <WaterMultiCard2 data={all_water_data.current!} />
                            <div
                                style={{
                                    fontSize: "22px",
                                    fontWeight: 500,
                                    marginTop: 20,
                                    textAlign: "left",
                                }}
                            >
                                用水需求情况
                            </div>
                            <WaterDemandMetricEcharts data={all_water_data.current!} />
                            <WaterAOIDemandMetricEcharts data={all_water_data.current!} />
                            <WaterGauge data={all_water_data.current!} />
                            <SupplyGauge data={all_water_data.current!} />
                            <div
                                style={{
                                    fontSize: "22px",
                                    fontWeight: 500,
                                    marginTop: 20,
                                    textAlign: "left",
                                }}
                            >
                                水网异常信息
                            </div>
                            <WaterBadMetricEcharts data={all_water_data.current!} />
                            <SupplyBadMetricEcharts data={all_water_data.current!} />
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={"电网"} key={"elec"}>
                        <div style={{ overflowY: "auto", maxHeight: "80vh" }}>
                            <div
                                style={{
                                    fontSize: "22px",
                                    fontWeight: 500,

                                    textAlign: "left",
                                }}
                            >
                                电网基础信息
                            </div>
                            <ElecBarEcharts />
                            <EleAverageBarEcharts data={all_elec_data.current!} />
                            <div
                                style={{
                                    fontSize: "22px",
                                    fontWeight: 500,
                                    marginTop: 20,
                                    textAlign: "left",
                                }}
                            >
                                用电需求情况
                            </div>
                            <ElecDemandMetricEcharts data={all_elec_data.current!} />
                            <ElecAOIDemandMetricEcharts data={all_elec_data.current!} />
                            <BadElecMultiPie data={all_elec_data.current!} />
                            <ElecMetricRadar data={all_elec_data.current!} />
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={"通信"} key={"comm"}>
                        <div style={{ overflowY: "auto", maxHeight: "80vh" }}>
                            <CommMultiCard data={all_comm_data.current!} />
                            <div
                                style={{
                                    fontSize: "22px",
                                    fontWeight: 500,
                                    marginTop: 20,
                                    textAlign: "left",
                                }}
                            >
                                通信需求情况
                            </div>
                            <CommProgressBar data={all_comm_data.current!} />
                            <CommMetricEcharts data={comm_demand_data.current!} />
                            <CommDemandMetricRadar data={all_comm_data.current!} />
                            <CommRunMultiPie1 data={all_comm_data.current!} />
                            <CommRunMultiPie2 data={all_comm_data.current!} />
                            <CommMetricRadar data={all_comm_data.current!} />
                        </div>
                    </Tabs.TabPane> */}
                    {/* {
            currentTab == "aoiSelector" &&
            <Tabs.TabPane tab={"AOI点选"} key={"aoiChose"}>
              <AoiInfo
                playerManager={playerManager}
                index={index}
                currentName={currentName}
              ></AoiInfo>
            </Tabs.TabPane>
          } */}
                    {/* {
                        currentTab == "roadCanChose" &&
                        <Tabs.TabPane tab={"道路点选"} key={"roadChose"}>
                            <RoadInfo
                                playerManager={playerManager}
                                index={index}
                                currentName={currentName}
                            ></RoadInfo>
                        </Tabs.TabPane>
                    } */}
                {/* </Tabs> */}
                {/* {
                    "aoiChose" == cur &&
                    <div className={`${index == 0 ? "leftPop pop-content" : "rightPop pop-content"}`}>
                        <div className="close-modal" onClick={handleClose}>X</div>
                        <div className="pop-body">
                            <AoiInfo
                                playerManager={playerManager}
                                index={index}
                                currentName={currentName}
                            ></AoiInfo>
                        </div>
                    </div>
                }
                {
                    "roadChose" == cur &&
                    <div className={`${index == 0 ? "leftPop pop-content" : "rightPop pop-content"}`}>
                        <div className="close-modal" onClick={handleClose}>X</div>
                        <div className="pop-body">
                            <RoadInfo
                                playerManager={playerManager}
                                index={index}
                                currentName={currentName}
                            ></RoadInfo>
                        </div>
                    </div>
                } */}
            </>
        );
    };

//todo
interface StatisticsPlayerProps {
    playerManager: PlayerManager;
    index: number;
    currentName: string
}
const RoadInfo = React.memo(
    (props: StatisticsPlayerProps) => {
        const statPlayerRef = useRef<RoadDetailPlayer | undefined>();
        const [roadDetail, setRoadDetail] = useState<RoadDetailStat>(RoadDetailStatInitial)
        //道路选择绑定player
        useEffect(() => {
            statPlayerRef.current = new RoadDetailPlayer((data) => {
                setRoadDetail(data);
            });
            props.playerManager.attachSubCrowdPlayer(`road_stat_${props.index}`, statPlayerRef.current!, true);
        }, [props])
        let road_car_person_options = {
            title: {
                top: -5,
                textStyle: {
                    fontSize: 16,
                },
            },
            color: ["#BA9DC9", "#A61427"],
            legend: {
                top: 20,
                right: 20,
                data: ["道路历史人数", "道路历史车辆数"],
            },
            xAxis: {
                // data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
                data: [0],
                axisLabel: {
                    formatter: (val: number) => {
                        return moment("2022-01-01").add(moment.duration(val, 'seconds')).format("hh:mm:ss")
                    }
                },
            },
            yAxis: {
                type: "value",
                name: "人",
                max: 10,
            },
            grid: { left: "15%" },
            series: [
                {
                    name: "道路历史人数",
                    type: "line",
                    smooth: true,
                    // data: [1000, 1000, 1000, 1000, 1000],
                },
                {
                    name: "道路历史车辆数",
                    type: "line",
                    smooth: true,
                    // data: [5000, 500, 5000, 5000, 5000],
                },
            ],
        }
        const road_water_options = {
            title: {
                top: -5,
                textStyle: {
                    fontSize: 16,
                },
            },
            color: ["#BA9DC9", "#A61427"],
            legend: {
                top: 20,
                right: 20,
                data: ["道路历史积水深度"],
            },
            xAxis: {
                // data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
                data: [0],
                axisLabel: {
                    formatter: (val: number) => {
                        return moment("2022-01-01").add(moment.duration(val, 'seconds')).format("hh:mm:ss")
                    }
                },
            },
            yAxis: {
                type: "value",
                max: 100,
                name: "mm",
            },
            grid: { left: "15%" },
            series: [
                {
                    name: "道路历史积水深度",
                    type: "line",
                    smooth: true,
                    data: [100, 100, 100, 100, 100],
                }
            ],
        }
        const roadBaseInfo = [{
            title: "道路名称",
            value: "长安街",
            iconName: "总道路数.svg",
            apiName: "road",
        }, {
            title: "道路人数",
            value: 100,
            unit: "人",
            iconName: "在途人数.svg",
            apiName: "num_pedestrians",
        }, {
            title: "道路车辆数",
            value: 100,
            unit: "辆",
            iconName: "在途车辆数.svg",
            apiName: "num_vehicles"
        }, {
            title: "道路积水深度",
            value: 100,
            unit: "mm",
            iconName: "道路积水.svg",
            apiName: "depth",
        }]
        const [roadBaseInfoState, setRoadBaseInfoState] = useState(roadBaseInfo)
        const optionsString = JSON.stringify(road_car_person_options);
        const road_water_optionsString = JSON.stringify(road_water_options)
        const [road_car_person_optionsState, setRoad_car_person_optionsState] = useState(road_car_person_options)
        const [road_water_optionsState, setRoad_water_optionsState] = useState(road_water_options)
        const getMaxNumInArrays = (a: number[], b: number[]): number => {
            let length = a.length + b.length
            return a.concat(b).sort((a, b) => a - b)[length - 1]
        }
        useEffect(() => {
            roadBaseInfo.forEach((item) => {
                if (item.apiName) {
                    if (item.apiName == "depth") {
                        item.value = roadDetail[item.apiName].toFixed(2)
                    } else {
                        //@ts-ignore
                        item.value = roadDetail[item.apiName]
                    }
                }
            })
            setRoadBaseInfoState(roadBaseInfo)
            const newOptions = JSON.parse(optionsString);
            const newRoad_water_options = JSON.parse(road_water_optionsString)
            newOptions.xAxis.data = roadDetail["pedestrian_history"].map((_, index) => roadDetail.step - (roadDetail["pedestrian_history"].length - index - 1))
            newOptions.yAxis.max = getMaxNumInArrays(roadDetail["pedestrian_history"], roadDetail["vehicle_history"]) * 1.2
            //设置道路历史人数
            newOptions.series[0].data = roadDetail["pedestrian_history"]

            //设置道路历史车辆数
            newOptions.series[1].data = roadDetail["vehicle_history"]
            newRoad_water_options.yAxis.max = Math.ceil(roadDetail["depth_history"].sort((a, b) => a - b)[roadDetail["depth_history"].length - 1] * 1.2 * 1000)
            newRoad_water_options.xAxis.data = roadDetail["depth_history"].map((_, index) => roadDetail.step - (roadDetail["depth_history"].length - index - 1))
            //设置道路历史积水深度
            newRoad_water_options.series[0].data = roadDetail["depth_history"].map((item) => item * 1000)
            setRoad_water_optionsState(newRoad_water_options)
            setRoad_car_person_optionsState(newOptions)
        }, [roadDetail])
        return <div className="beside-style"><div className="base-info">道路基础信息</div>
            {
                <Row>
                    {
                        roadBaseInfoState.map((item, index) => {
                            return <Col span={24} key={index} className="mb10">
                                <div className="card-blue">
                                    <div className="inner-card">
                                        <Avatar
                                            style={{ float: "left" }}
                                            shape="square"
                                            size="large"
                                            src={item.iconName || "变压器-550kv.svg"}
                                        />
                                        <div style={{ marginLeft: 10, textAlign: "left" }} className="date-detail">
                                            <div>{item.title}</div>
                                            <div>
                                                <span className="text-blue">
                                                    {item.apiName == "road" ? props.currentName : item.value}
                                                </span>
                                                {item.unit}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        })
                    }
                </Row>
            }
            <div className="base-info">道路历史情况</div>
            {/* 道路历史人数和道理历史车辆数 */}
            <ReactECharts
                option={road_car_person_optionsState}
                style={{ width: "95%", height: "270px", }}
            />
            {/* 道路历史积水深度 */}
            <ReactECharts
                option={road_water_optionsState}
                style={{ width: "95%", height: "270px", }}
            />
        </div>
    }
)

const AoiInfo = React.memo(
    (props: StatisticsPlayerProps) => {
        const statPlayerRef = useRef<AoiDetailPlayer | undefined>()
        const [aoiDetail, setAoiDetail] = useState<AoiDetailStat>(AoiDetailStatInitial)
        //aoi选择绑定player
        useEffect(() => {
            statPlayerRef.current = new AoiDetailPlayer((data) => {
                setAoiDetail(data);
            });
            props.playerManager.attachSubCrowdPlayer(`aoi_stat_${props.index}`, statPlayerRef.current!, true);
        }, [props])
        const history_options = {
            legend: {
                top: 20,
                right: 20,
                data: ["AOI历史人数"]
            },
            xAxis: {
                data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
                axisLabel: {
                    formatter: (val: number) => {
                        return moment("2022-01-01").add(moment.duration(val, 'seconds')).format("hh:mm:ss")
                    }
                },
            },
            yAxis: {
                type: "value",
                name: "人",
                max: 1000,
            },
            grid: { left: "15%" },
            series: [
                {
                    name: "AOI历史人数",
                    type: "line",
                    smooth: true,
                    data: [100, 100, 100, 100, 100],
                }
            ],
        }
        const commun_options = {
            title: {
                top: -5,
                textStyle: {
                    fontSize: 16,
                },
            },
            color: ["#BA9DC9", "#A61427"],
            legend: {
                top: 20,
                right: 20,
                data: ["AOI通信总需求量", "AOI通信网络承总流量"],
            },
            xAxis: {
                data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
                axisLabel: {
                    formatter: (val: number) => {
                        return moment("2022-01-01").add(moment.duration(val, 'seconds')).format("hh:mm:ss")
                    }
                },
            },
            yAxis: {
                type: "value",
                name: "GB",
                max: 15000000000,
            },
            grid: { left: "15%" },
            series: [
                {
                    name: "AOI通信总需求量",
                    type: "line",
                    smooth: true,
                    data: [100, 100, 100, 100, 100],
                },
                {
                    name: "AOI通信网络承总流量",
                    type: "line",
                    smooth: true,
                    data: [5000, 500, 5000, 5000, 5000],
                },
            ],
        }
        const water_options = {
            title: {
                // text: "通信需求及承载",
                top: -5,
                textStyle: {
                    fontSize: 16,
                },
            },
            color: ["#BA9DC9", "#A61427"],
            legend: {
                top: 20,
                right: 20,
                data: ["AOI总用水需求量", "AOI总供水量"],
            },
            xAxis: {
                data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
                axisLabel: {
                    formatter: (val: number) => {
                        return moment("2022-01-01").add(moment.duration(val, 'seconds')).format("hh:mm:ss")
                    }
                },
            },
            yAxis: {
                type: "value",
                name: "m³",
                max: 1,
            },
            grid: { left: "15%" },
            series: [
                {
                    name: "AOI总用水需求量",
                    type: "line",
                    smooth: true,
                    data: [100, 100, 100, 100, 100],
                },
                {
                    name: "AOI总供水量",
                    type: "line",
                    smooth: true,
                    data: [5000, 500, 5000, 5000, 5000],
                },
            ],
        }
        const ele_options = {
            title: {
                top: -5,
                textStyle: {
                    fontSize: 16,
                },
            },
            color: ["#BA9DC9", "#A61427"],
            legend: {
                top: 20,
                right: 20,
                data: ["AOI总用电需求量", "AOI总供电量"],
            },
            xAxis: {
                data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
                axisLabel: {
                    formatter: (val: number) => {
                        return moment("2022-01-01").add(moment.duration(val, 'seconds')).format("hh:mm:ss")
                    }
                },
            },
            yAxis: {
                type: "value",
                name: "kW",
                max: 30000,
            },
            grid: { left: "15%" },
            series: [
                {
                    name: "AOI总用电需求量",
                    type: "line",
                    smooth: true,
                    data: [100, 100, 100, 100, 100],
                },
                {
                    name: "AOI总供电量",
                    type: "line",
                    smooth: true,
                    data: [5000, 500, 5000, 5000, 5000],
                },
            ],
        }
        const baseInfoArr = [
            { title: "AOI名称(id)", value: "故宫", unit: "", iconName: "AOI名称.svg", apiName: "aoiId" },
            { title: "AOI人数", value: 1, unit: "人", iconName: "城区总人数.svg", apiName: "agent" },
            { title: "AOI总用水需求", value: 1, unit: "m³", iconName: "AOI总用水需求.svg", apiName: "water" },
            { title: "AOI总用电需求", value: 1, unit: "kW", iconName: "AOI总用电需求.svg", apiName: "elec" },
            { title: "AOI用户总通信需求", value: 1, unit: "GB", iconName: "AOI用户总通信需求.svg", apiName: "comm" }
        ]
        const satisfiedArr = [
            { title: "用水", value: 0.5, apiName: "water" },
            { title: "用电", value: 0.4, apiName: "elec" },
            { title: "通信", value: 0.3, apiName: "comm" }]
        const [baseInfoArrState, setBaseInfoArrState] = useState(baseInfoArr)
        const [satisfiedArrState, setSatisfiedArrState] = useState(satisfiedArr)
        const [history_optionsState, setHistory_optionsState] = useState(history_options)
        const [commun_optionsState, setCommun_optionsState] = useState(commun_options)
        const [water_optionsState, setWater_optionsState] = useState(water_options)
        const [ele_optionsState, setEle_optionsState] = useState(ele_options)
        const history_optionsString = JSON.stringify(history_options);
        const commun_optionsString = JSON.stringify(commun_options)
        const water_optionsString = JSON.stringify(water_options)
        const ele_optionsString = JSON.stringify(ele_options)
        const getMaxNumInArrays = (a: number[], b: number[]): number => {
            let length = a.length + b.length
            return a.concat(b).sort((a, b) => a - b)[length - 1]
        }

        useEffect(() => {
            const newHistory_options = JSON.parse(history_optionsString)
            const newCommun_options = JSON.parse(commun_optionsString)
            const newWater_options = JSON.parse(water_optionsString)
            const newEle_options = JSON.parse(ele_optionsString)

            newHistory_options.xAxis.data = aoiDetail.history.agent.map((_, index) => aoiDetail.step - (aoiDetail.history.agent.length - index - 1))
            newHistory_options.yAxis.max = Math.ceil(aoiDetail.history.agent.sort((a, b) => a - b)[aoiDetail.history.agent.length - 1] * 1.2)
            newCommun_options.xAxis.data = aoiDetail.history.comm.demand.map((_, index) => aoiDetail.step - (aoiDetail.history.comm.demand.length - index - 1))
            newCommun_options.yAxis.max = (getMaxNumInArrays(aoiDetail.history.comm.demand, aoiDetail.history.comm.supply) * 1.2 / 1000000000).toFixed(3)
            newWater_options.xAxis.data = aoiDetail.history.water.demand.map((_, index) => aoiDetail.step - (aoiDetail.history.water.demand.length - index - 1))
            newWater_options.yAxis.max = (getMaxNumInArrays(aoiDetail.history.water.demand, aoiDetail.history.water.supply) * 1.2).toFixed(3)
            newEle_options.xAxis.data = aoiDetail.history.elec.demand.map((_, index) => aoiDetail.step - (aoiDetail.history.elec.demand.length - index - 1))
            newEle_options.yAxis.max = getMaxNumInArrays(aoiDetail.history.elec.demand, aoiDetail.history.elec.supply) * 1.2

            newHistory_options.series[0].data = aoiDetail.history.agent
            newCommun_options.series[0].data = aoiDetail.history.comm.demand.map((item) => item / 1000000000)
            newCommun_options.series[1].data = aoiDetail.history.comm.supply.map((item) => item / 1000000000)
            newWater_options.series[0].data = aoiDetail.history.water.demand
            newWater_options.series[1].data = aoiDetail.history.water.supply
            newEle_options.series[0].data = aoiDetail.history.elec.demand
            newEle_options.series[1].data = aoiDetail.history.elec.supply

            satisfiedArrState.forEach((item) => {
                //@ts-ignore
                item.value = aoiDetail.demand[item.apiName].toFixed(2)
            })
            baseInfoArrState.forEach((item) => {
                if (item.apiName == "id") {
                    item.value = aoiDetail.id
                } else if (item.apiName == "comm") {
                    item.value = (aoiDetail.basic[item.apiName] / 1000000000).toFixed(3)
                } else if (item.apiName == "water") {
                    item.value = aoiDetail.basic[item.apiName].toFixed(3)
                } else {
                    //@ts-ignore
                    item.value = Math.ceil(aoiDetail.basic[item.apiName])
                }
            })
            setEle_optionsState(newEle_options)
            setWater_optionsState(newWater_options)
            setCommun_optionsState(newCommun_options)
            setHistory_optionsState(newHistory_options)
            setSatisfiedArrState(satisfiedArrState)
            setBaseInfoArrState(baseInfoArrState)
        }, [aoiDetail])

    return <div className="beside-style"><div className="base-info">AOI基础消息</div>
      <Row>
        {
          baseInfoArrState.map((item, index) => {
            return <Col span={24} key={index} className="mb10">
              <div className="card-blue">
                <div className="inner-card">
                  <Avatar
                    style={{ float: "left" }}
                    shape="square"
                    size="large"
                    src={item.iconName || "变压器-550kv.svg"}
                  />
                  <div style={{ marginLeft: 10, textAlign: "left" }} className="date-detail" >
                    <div>{item.title}</div>
                    <div>
                      <span className="text-blue">{item.apiName=="aoiId"?props.currentName:item.value}</span>
                      {item.unit}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          })
        }
      </Row>
      <div className="base-info">AOI需求满足情况</div>
      {
        satisfiedArrState.map((item, index) => {
          return <div key={index} className="proess-box">
            <span>{item.title}</span>
            <span style={{
              fontSize: "14px",
              color: "#5b5c5b",
            }}> 满足需求</span>
            <span style={{
              fontSize: "25px",
              color: "#BA9DC9",
              fontWeight: "600",
            }}>{item.value * 100}%</span>
            <Progress
              percent={item.value * 100}
              // percent={(v ? v.demand.satisfiedRatio : 0) * 100}
              style={{ width: "95%" }}
              showInfo={false}
              strokeColor={"#BA9DC9"}
            />
          </div>
        })
      }
      <div className="base-info">AOI历史情况</div>

            {/* aoi历史人数 */}
            <ReactECharts
                option={history_optionsState}
                style={{ width: "95%", height: "270px", }}
            />
            {/* aoi通信量 */}
            <ReactECharts
                option={commun_optionsState}
                style={{ width: "95%", height: "270px", }}
            />
            {/* aoi用电量 */}
            <ReactECharts
                option={ele_optionsState}
                style={{ width: "95%", height: "270px", }}
            />
            {/* aoi用水量 */}
            <ReactECharts
                option={water_optionsState}
                style={{ width: "95%", height: "270px", }}
            />

        </div>
    }

)
