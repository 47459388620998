import {AbstractPlayer, ICrowdPlayer} from "./AbstractPlayer";
import {ICustomDataKeyFrame, IKeyFrame} from "./KeyFrame";
import mapboxgl from "mapbox-gl";

class RoadBreakPlayer extends AbstractPlayer {
    lastPlayedFrame?: IKeyFrame;

    constructor(readonly map: mapboxgl.Map, readonly layerName: string) {
        super();
    }

    play(timing: number): any {
        const currentKeyFrame = this.getCurrentKeyFrame(timing) as ICustomDataKeyFrame;
        if (this.lastPlayedFrame?.timing !== currentKeyFrame?.timing) {
            this.lastPlayedFrame = currentKeyFrame;
            if (currentKeyFrame) {
                this.map.setFilter(this.layerName, [
                    'in', ['get', 'id'], ['literal', currentKeyFrame.data]
                ]);
            }
        }
    }

    clear() {
        super.clear();
        this.lastPlayedFrame = undefined;
    }
}


export class RoadBreakCrowdPlayer implements ICrowdPlayer {

    players: {
        macro: RoadBreakPlayer
    }

    constructor(private map: mapboxgl.Map, private layerName: string) {
        this.players = {
            macro: new RoadBreakPlayer(map, layerName),
        }
    }

    init() {
        this.map.setFilter(this.layerName, ['boolean', false]);
    }

    appendKeyFrames(keyFrames: { [p: string]: IKeyFrame }): any {
        this.players.macro.enqueueKeyFrame(keyFrames.macro);
    }

    clear(): void {
        this.players.macro.clear();
    }

    play(timing: number): any {
        this.players.macro.play(timing);
    }

}