import {AbstractPlayer, ICrowdPlayer} from "./AbstractPlayer";
import {ICustomDataKeyFrame, IKeyFrame} from "./KeyFrame";
import mapboxgl, {GeoJSONSource} from "mapbox-gl";

class PopulationHeatmapPlayer extends AbstractPlayer {
    lastPlayedFrame?: IKeyFrame;

    constructor(readonly map: mapboxgl.Map, readonly sourceName: string) {
        super();
    }

    play(timing: number): any {
        const currentKeyFrame = this.getCurrentKeyFrame(timing) as ICustomDataKeyFrame;
        if (this.lastPlayedFrame?.timing !== currentKeyFrame?.timing) {
            this.lastPlayedFrame = currentKeyFrame;
            if (currentKeyFrame) {
                (this.map.getSource(this.sourceName) as GeoJSONSource).setData(
                    {
                        type: "FeatureCollection",
                        features: this.toGeoJsonFeatures(currentKeyFrame)
                    }
                );
            }
        }
    }

    toGeoJsonFeatures(frame: ICustomDataKeyFrame) {
        return frame.data.map((item: {lng: number, lat:number}) => {
            return {
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [item.lng, item.lat],
                },
            };
        });
    }

    clear() {
        super.clear();
        this.lastPlayedFrame = undefined;
    }
}


export class PopulationHeatmap implements ICrowdPlayer {

    players: {
        macro: PopulationHeatmapPlayer
    }

    constructor(private map: mapboxgl.Map, private sourceName: string) {
        this.players = {
            macro: new PopulationHeatmapPlayer(map, sourceName),
        }
    }

    init() {
    }

    appendKeyFrames(keyFrames: { [p: string]: IKeyFrame }): any {
        this.players.macro.enqueueKeyFrame(keyFrames.macro);
    }

    clear(): void {
        this.players.macro.clear();
    }

    play(timing: number): any {
        this.players.macro.play(timing);
    }

}