import React from "react";
import {Button, Tooltip} from "antd";
import {createFromIconfontCN} from "@ant-design/icons";
import {ELayerIconState, LayerController} from "../core/LayerController";

interface PropTypes {
    controller: LayerController,
    iconSelectCallback(a:string):void;
}

const IconFont = createFromIconfontCN({
    // scriptUrl: "//at.alicdn.com/t/font_3397267_fl7iiu7qeq.js",
    scriptUrl: "//at.alicdn.com/t/font_3397267_y3yy0ckhrj.js",
});

export class LayerControllerIcons extends React.Component<PropTypes> {

    componentDidMount() {
        this.props.controller.registerOrUnregisterLayerVisibilityChangedObserver('controllerIcons', (sender) => {
            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        this.props.controller.registerOrUnregisterLayerVisibilityChangedObserver('controllerIcons');
    }

    render() {
        const layerAndState = this.props.controller.getControlledLayerState();
        const {iconSelectCallback}=this.props

        return (<>
            {layerAndState.map(({layer, state},index) => (
                <Tooltip placement="bottom" title={layer.displayName} key={index}>
                    <Button
                        type={state.iconState === ELayerIconState.VISIBLE ? "link" : "text"}
                        disabled={state.iconState === ELayerIconState.DISABLED}
                        icon={<IconFont type={layer.icon} />}
                        onClick={() => {
                            iconSelectCallback(layer.name)
                            this.props.controller.toggleLayer(layer.name);
                        }}
                    />
                </Tooltip>

            ))}
        </>)
    }
}