import React  from "react";
import { useRef, useState } from "react";
import {Row, Col, Button, Layout, Modal, message} from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import MapBoxRainfallSelector from "../Components/MapBoxRainfallSelector";


const SelectPointPage: React.FC<{}> = (props) => {
  const ref1 = useRef<MapBoxRainfallSelector>(null);
  const ref2 = useRef<MapBoxRainfallSelector>(null);
  const history = useHistory();
  const [jobNames, setJobNames] = useState<string[]>([]);

  return (
    <>
      <Layout>
        <Layout.Header>
          <div style={{ float: "left" }}>
            <img src="/logo-com.png" height={40} alt="Logo" />
          </div>
          <div
            className="logo"
            style={{ color: "white", display: "inline-block" }}
          >
            {/*Simulet - Vis - v{displayVersion}*/}
          </div>
        </Layout.Header>
        <Layout.Content>
          <Row>
            <Col span={12}>
              <MapBoxRainfallSelector ref={ref1} side="left" onSelectComplete={(jobName) => {
                jobNames[0] = jobName;
                setJobNames(jobNames);
              }}/>
            </Col>
            <Col span={12}>
              <MapBoxRainfallSelector ref={ref2} side="right" onSelectComplete={(jobName) => {
                jobNames[1] = jobName;
                setJobNames(jobNames);
              }}/>
            </Col>
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 0,
              height: "10vh",
            }}
          >
            <Button
              icon={<PlayCircleOutlined />}
              type="primary"
              onClick={async () => {
                if (jobNames[0] && jobNames[1]) {
                  history.push(`/sim?job1=${jobNames[0]}&job2=${jobNames[1]}&norealtime=true`);
                } else {
                  message.error("请选择方案后开始");
                }
              }}
            >
              开始模拟
            </Button>
          </div>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default SelectPointPage;
