import {BaseCustomLayer} from "./BaseCustomLayer";
import {PlayerManager} from "../../core/player/PlayerManager";
import {Map} from "mapbox-gl";
import {RoadBreakCrowdPlayer} from "../../core/player/RoadBreak";
import {IReader} from "./GeoJsonReader";

const iconRoadBreakLayer = {
  type: "symbol",
  source: "road_break",
  layout: {
    "icon-allow-overlap": false,
    "icon-image": "道路中断",
    "icon-size": ['step',
        ['zoom'],
        0.1,
        13, 0.2,
        16, 0.3
    ],
  }
}

export class StoppedRoadLayer extends BaseCustomLayer {
  map: any;
  index: number;

  roadCenterReader: IReader;
  roadBreakPlayer?: RoadBreakCrowdPlayer;
  data: any;
  initialized: boolean = false;

  player: PlayerManager;

  constructor(
    id: string,
    index: number,
    props: {
      playerManager: PlayerManager;
      reader: any;
    }
  ) {
    super(id, "3d", props);
    this.index = index;
    this.player = props.playerManager;
    this.subLayerIdToLocalVisibility[id] = true;
    this.roadCenterReader = props.reader;
  }

  async init(map: Map, filter: number[] = []) {
    this.map = map;

    this.data = await this.roadCenterReader.get();

    this.map.addSource("road_break", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: this.data,
      },
    });

    this.map.addLayer({id: this.id, ...iconRoadBreakLayer});

    const player = new RoadBreakCrowdPlayer(this.map, this.id);
    player.init();
    this.roadBreakPlayer = player;

    this.player.attachSubCrowdPlayer(`stopped_road_${this.index}`, player);

    this.initialized = true;
    this.setLayerVisibility(false);
  }
}
