import axios from "axios";
import { Observable, forkJoin } from "rxjs";

export const apiUrl = process.env.REACT_APP_BACKEND_URL ?? "https://api-visual.fiblab.net";

export type MultiRequestTarget = {
  url: string,
  type: string,
  index: number,
  context?: any,
}

export type MultiResponse = {
  data: any,
  requestTarget: MultiRequestTarget,
}

export const multiRequest = (
  targets: MultiRequestTarget[],
  consumer: (response: MultiResponse[]) => void
) => {
  console.log(targets);
  const creatRequest = (target: MultiRequestTarget) => {
    return new Observable<MultiResponse>((subscriber) => {
      axios
        .get(apiUrl + target.url)
        .then((response) => {
          subscriber.next({
            data: (response.data as any).data,
            requestTarget: target,
          });
          subscriber.complete();
        })
        .catch((error) => {
          subscriber.error(error);
        });
    });
  };
  const observables = targets.map((target) => creatRequest(target));

  forkJoin(observables).subscribe(consumer);
};
