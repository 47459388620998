import { AbstractPlayer, ICrowdPlayer } from "./AbstractPlayer";
import { ICustomDataKeyFrame, IKeyFrame } from "./KeyFrame";

export type SpeedDistributionDataType = {
  x: number[];
  y: { phy: number[]; nn: number[]; real: number[] };
};
export type SpeedDiffDataType = { cvr: number; nvr: number };

export class StatisticsPlayer<T> extends AbstractPlayer {
  private statisticsData: T | undefined = undefined;

  clear() {
    super.clear();
    this.statisticsData = undefined;
  }

  play(timing: number): any {
    // this.maybeRollback(timing);
    const currentFrame = this.getCurrentKeyFrame(timing);

    if (currentFrame) {
      this.statisticsData = (currentFrame as ICustomDataKeyFrame).data;
    } else {
      this.statisticsData = undefined;
    }
    return {};
  }

  getData(): T | undefined {
    if (this.statisticsData) {
      const dataCopy = {};
      Object.assign(dataCopy, this.statisticsData);

      // @ts-ignore
      return dataCopy;
    } else {
      return undefined;
    }
  }
}
export class RoadMetricPlayer implements ICrowdPlayer {
  private lastTiming = -Infinity;
  player: StatisticsPlayer<any>;
  data_cache: any[] = [];
  constructor(private onDataChanged: (data: any) => void) {
    this.player = new StatisticsPlayer<any>();
  }
  appendKeyFrames(keyFrames: { [p: string]: IKeyFrame }): any {
    Object.entries(keyFrames).forEach(([statisticsType, keyFrame]) => {
      this.player.enqueueKeyFrame(keyFrame);
    });
  }

  clear(): void {
    this.lastTiming = -Infinity;
  }

  play(timing: number): any {
    this.player.play(timing);
    if (Math.floor(timing) !== Math.floor(this.lastTiming)) {
      const d = this.player.getData();
      if (d) {
        let push = true;
        for (let i = 0; i < this.data_cache.length; i++) {
          if (this.data_cache[i].step === d.step) {
            push = false;
            break;
          }
        }
        if (push) {
          this.data_cache.push(d);
          if (this.data_cache.length > 5) {
            this.data_cache.shift();
          }
          this.onDataChanged(this.data_cache);
        }
      }
    }
    this.lastTiming = timing;
  }
}

export class WaterLinkMetricPlayer implements ICrowdPlayer {
  private lastTiming = -Infinity;
  player: StatisticsPlayer<any>;
  constructor(private onDataChanged: (data: any) => void) {
    this.player = new StatisticsPlayer<any>();
  }
  appendKeyFrames(keyFrames: { [p: string]: IKeyFrame }): any {
    Object.entries(keyFrames).forEach(([statisticsType, keyFrame]) => {
      this.player.enqueueKeyFrame(keyFrame);
    });
  }

  clear(): void {
    this.lastTiming = -Infinity;
  }

  play(timing: number): any {
    this.player.play(timing);
    if (Math.floor(timing) !== Math.floor(this.lastTiming)) {
      const d = this.player.getData();
      if (d) {
        this.onDataChanged(d);
      }
    }
    this.lastTiming = timing;
  }
}

export class FrameInfoPlayer extends AbstractPlayer implements ICrowdPlayer {
  frameInfo: any = {};
  lastTiming = -Infinity;

  constructor(private onDataChanged: (info: any) => void) {
    super();
  }

  appendKeyFrames(keyFrames: { [p: string]: IKeyFrame }): any {
    this.enqueueKeyFrame(keyFrames["info"]);
  }

  play(timing: number): any {
    // this.maybeRollback(timing);
    const currentFrame = this.getCurrentKeyFrame(timing);

    if (currentFrame) {
      this.frameInfo = (currentFrame as ICustomDataKeyFrame).data;
    } else {
      this.frameInfo = {};
    }
    if (Math.floor(timing) !== Math.floor(this.lastTiming)) {
      this.onDataChanged(this.frameInfo);
    }
    this.lastTiming = timing;
  }

  clear() {
    super.clear();
    this.lastTiming = -Infinity;
  }
}
