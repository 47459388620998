// aoi可选图层
import mapboxgl from "mapbox-gl";
import axios from "axios";

import {StatefulGeoLayer} from "./GeoLayer";
import {
    Feature,
    IStatefulGeoSource, StatefulGeoSource,
} from "../../../core/utils/StatefulGeoSource";


export class AoiSelectorLayer extends StatefulGeoLayer<{}> {

    canClick = true;
    lastSelected?: number;

    async init(map: mapboxgl.Map): Promise<void> {
        await super.init(map);
        await this.statefulSource!.setFeatures(
            axios.get('geojson_aoi_beijing5ring_0424.json').then(resp => (resp.data as Feature[]))
        );

        PubSub.subscribe("choseType",(_,data)=>{
            if(data&&data.type=="road-detail"){
                if (this.lastSelected) {
                    this.map?.removeFeatureState({source: this.statefulSource!.sourceName, id: this.lastSelected});
                }
            }
        })
    }

    getLayerDef(): mapboxgl.AnyLayer {
        return {
            type: "fill",
            paint: {
                'fill-color': [
                    'case',
                    ['feature-state', 'selected'],
                    '#FF0000',
                    '#333333'
                ],
                'fill-opacity': 0.4,
            },
            minzoom:15
        } as any;
    }

    async getStatefulSource(): Promise<IStatefulGeoSource<{}>> {
        return new StatefulGeoSource(
            this.map!, this.id, 'id', {}
        );
    }

    on(type: "click", features: any[]) {
        PubSub.publish('choseType', {type: 'aoi-detail',currentID:features[0].properties.id,currentIndex:this.index,roadName:features[0].properties.id});
        if (this.lastSelected) {
            this.map?.removeFeatureState({source: this.statefulSource!.sourceName, id: this.lastSelected});
        }
        this.lastSelected = features[0].properties.id;
        this.map?.setFeatureState({source: this.statefulSource!.sourceName, id: features[0].properties.id}, {selected: true});
    }
}