import { AbstractPlayer, ICrowdPlayer } from "./AbstractPlayer";
import { ICustomDataKeyFrame, IKeyFrame } from "./KeyFrame";
import mapboxgl, { GeoJSONSource } from "mapbox-gl";

class SpecialCarPlayer extends AbstractPlayer {
  lastPlayedFrame?: IKeyFrame;

  constructor(readonly map: mapboxgl.Map, readonly layerName: string) {
    super();
  }

  play(timing: number): any {
    const currentKeyFrame = this.getCurrentKeyFrame(
      timing
    ) as ICustomDataKeyFrame;
    if (this.lastPlayedFrame?.timing !== currentKeyFrame?.timing) {
      this.lastPlayedFrame = currentKeyFrame;
      if (currentKeyFrame) {
        (this.map.getSource("special_car") as GeoJSONSource).setData({
          type: "FeatureCollection",
          features: this.convert_data(currentKeyFrame),
        });
      }
    }
  }

  convert_data(frame: ICustomDataKeyFrame) {
    return frame.data.map((item: any) => {
      return {
        type: "Feature",
        properties: {
          type: item.type,
        },
        geometry: {
          type: "LineString",
          coordinates: item.trajectory.map((point: any) => {
            return [point.lng, point.lat];
          }),
        },
      };
    });
  }

  clear() {
    super.clear();
    this.lastPlayedFrame = undefined;
  }
}

export class SpecialCarCrowdPlayer implements ICrowdPlayer {
  players: {
    line: SpecialCarPlayer;
  };

  constructor(private map: mapboxgl.Map, private layerName: string) {
    this.players = {
      line: new SpecialCarPlayer(map, layerName),
    };
  }

  init() {}

  appendKeyFrames(keyFrames: { [p: string]: IKeyFrame }): any {
    this.players.line.enqueueKeyFrame(keyFrames.line);
  }

  clear(): void {
    this.players.line.clear();
  }

  play(timing: number): any {
    this.players.line.play(timing);
  }
}
