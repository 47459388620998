// 通信网
export enum CommType {
    INTERNET = 1,
    GW,
    BS,
    UNKNOWN
}

export enum CommState {
    NORMAL = 1,
    DIRECT,
    BROKEN
}

export interface BaseStationState {
    state: CommState
}

// 电网
export enum ElecType {
    ET_500KV = 1,
    ET_220KV,
    ET_110KV,
    ET_10KV
}

export enum ElecState {
    NORMAL = 0,
    RUINED,
    STOPPED
}

export interface ElecNodeState {
    state: ElecState
}


// 水网
export enum WaterState {
    NORMAL = 0,
    BAD,
}