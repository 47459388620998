import { BaseCustomLayer } from "./BaseCustomLayer";
import * as THREE from "three";

import {
  AllRoadPlayer,
  IRoadConfig,
  IRoadMapReader,
} from "../../core/player/Road";
import { CoordinateTransformer } from "../../core/utils/coordutils";
import { PlayerManager } from "../../core/player/PlayerManager";
import { RoadKeyFrameFactory } from "./FrameFactories";

export class RoadLayer extends BaseCustomLayer {
  stateColor = {
    0: new THREE.Color(0x2e8b57),
    1: new THREE.Color(0x32cd32),
    2: new THREE.Color(0xeeee00),
    3: new THREE.Color(0xaa0000),
    4: new THREE.Color(0x800000),
    5: new THREE.Color(0x600000),
    6: new THREE.Color(0x7d8597),
  };

  opacityParam = {
    far: 13,
    near: 16,
    opacityFar: 0.7,
    opacityNear: 0.05,
  };
  material = new THREE.MeshBasicMaterial({
    side: THREE.DoubleSide,
    color: new THREE.Color(0xffffff),
    transparent: true,
  });
  geo = new THREE.PlaneGeometry(1.005, 1);
  map: any;

  roadConfig: IRoadConfig;

  widthScale: number = 1.0;

  transformer: CoordinateTransformer;

  camera?: THREE.Camera;
  scene?: THREE.Scene;
  renderer?: THREE.WebGLRenderer;

  initialized: boolean = false;
  addFinishResolver?: () => void;

  addFinish: Promise<void> = new Promise((resolve) => {
    this.addFinishResolver = resolve;
  });

  player: PlayerManager;
  keyFrameFactory = new RoadKeyFrameFactory();
  index: number;

  constructor(
    id: string,
    index: number,
    props: {
      transformer: CoordinateTransformer;
      playerManager: PlayerManager;
      roadMapReader: IRoadMapReader;
      width?: number;
    }
  ) {
    super(id, "3d", props);
    this.subLayerIdToLocalVisibility[id] = true;
    this.index = index;
    this.transformer = props.transformer;
    this.player = props.playerManager;

    this.roadConfig = {
      basicGeometry: this.geo,
      colorStateMap: this.stateColor,
      material: this.material,
      roadMapReader: props.roadMapReader,
      width: (props.width || 5) * this.transformer.scale,
      defaultState: 0,
    };
  }

  async onAdd(map: any, gl: any) {
    this.map = map;
    this.camera = new THREE.Camera();
    this.scene = new THREE.Scene();

    this.renderer = new THREE.WebGLRenderer({
      canvas: map.getCanvas(),
      context: gl,
      antialias: true,
    });
    this.renderer.autoClear = false;

    const roadCrowdPlayer = new AllRoadPlayer(this.scene, this.roadConfig);
    await roadCrowdPlayer.init();

    this.player.attachSubCrowdPlayer(`road_${this.index}`, roadCrowdPlayer);

    console.log("RoadPlayer initialized.");
    this.initialized = true;
    this.setLayerVisibility(false);
    this.addFinishResolver!();
  }

  adjustMaterialWidth(scale: number, offset = 0) {
    if (this.widthScale !== scale) {
      this.widthScale = scale;
      const geoAdjusted = new THREE.PlaneGeometry(1.005, scale);
      geoAdjusted.applyMatrix4(
        new THREE.Matrix4().makeTranslation(0, offset, 0)
      );
      this.geo.copy(geoAdjusted);
    }
  }

  /**
   * 获取在ZoomLevel下道路的不透明度
   * @param z Zoom等级
   * @returns {number} 不透明度
   */
  opacityFunc(z: number) {
    // const a = 13, b = 16, opa = 0.7, opb = 0.2;
    const {
      far: a,
      near: b,
      opacityFar: opa,
      opacityNear: opb,
    } = this.opacityParam;
    const f = ((z - a) * (opb - opa)) / (b - a) + opa;
    return Math.min(
      this.opacityParam.opacityFar,
      Math.max(this.opacityParam.opacityNear, f)
    );
  }

  render(gl: any, matrix: ArrayLike<number>) {
    if (this.initialized) {
      const m = new THREE.Matrix4().fromArray(matrix);

      const zoomLevel = this.map.getZoom();
      this.adjustMaterialWidth(
        Math.pow(25 - zoomLevel, 3.7) / 18000,
        Math.pow((22 - zoomLevel) / 12, 5)
      );
      // this.material.opacity = this.opacityFunc(zoomLevel);

      this.camera!.projectionMatrix = m.multiply(
        this.transformer.translationMatrix
      );
      this.renderer!.resetState();
      this.renderer!.render(this.scene!, this.camera!);
      this.map.triggerRepaint();
    }
  }
}
