import * as THREE from "three";
import { loadModel } from "../utils/load_model";
import {
  IFunctionalKeyFrame,
  IKeyFrame,
  IMovementKeyFrame,
  KeyFrameTool,
} from "./KeyFrame";
import { AbstractPlayer, ICrowdPlayer } from "./AbstractPlayer";
import { normAngle } from "../utils/coordutils";

export class ModelConfig {
  path: string;
  scale: THREE.Vector3;
  rotation: THREE.Euler;
  model: THREE.Object3D | undefined = undefined;
  modelLoaded: boolean = false;

  constructor(
    path: string,
    scale: THREE.Vector3,
    rotation: THREE.Euler,
    model?: THREE.Object3D
  ) {
    this.path = path;
    this.scale = scale;
    this.rotation = rotation;
    this.model = model;
  }

  async loadModel() {
    if (!this.modelLoaded) {
      const model = new THREE.Scene();
      const modelToAdd = this.model
        ? this.model.clone()
        : await loadModel(this.path);
      const bbox = new THREE.Box3().setFromObject(modelToAdd);
      modelToAdd.applyMatrix4(
        new THREE.Matrix4()
          .scale(this.scale)
          .multiply(
            new THREE.Matrix4().makeScale(
              5 / (bbox.max.x - bbox.min.x),
              2 / (bbox.max.y - bbox.min.y),
              1
            )
          ) // 设置模型长宽为5x2
          .multiply(
            new THREE.Matrix4().makeTranslation(
              -bbox.max.x,
              -(bbox.max.y + bbox.min.y) / 2,
              0
            )
          )
          .multiply(new THREE.Matrix4().makeRotationFromEuler(this.rotation))
      );
      model.add(modelToAdd);
      // 影子
      const helper = new THREE.BoxHelper(modelToAdd);
      helper.geometry.computeBoundingBox();
      const shadowGeo = new THREE.PlaneGeometry(1, 1, 10, 10);
      shadowGeo.computeBoundingBox();
      const shadowMaterial = new THREE.ShaderMaterial({
        side: THREE.DoubleSide,
        transparent: true,
        uniforms: {
          color: { value: new THREE.Color(0) },
          bboxMin: { value: shadowGeo.boundingBox!.min },
          bboxMax: { value: shadowGeo.boundingBox!.max },
        },
        vertexShader: `
                    uniform vec3 bboxMin;
                    uniform vec3 bboxMax;
                  
                    varying vec2 vUv;
                
                    void main() {
                      vUv.x = (position.x - bboxMin.x) / (bboxMax.x - bboxMin.x);
                      vUv.y = (position.y - bboxMin.y) / (bboxMax.y - bboxMin.y);
                      gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
                    }
                `,
        fragmentShader: `
                    uniform vec3 color;
                  
                    varying vec2 vUv;
                    
                    
                    void main() {
                      float xxx = vUv.x - 0.5;
                      float yyy = vUv.y - 0.5;
                    
                      gl_FragColor = vec4(color, 1.0 - 2.0 * sqrt(xxx*xxx + yyy*yyy));
                    }
                `,
      });
      const mesh = new THREE.Mesh(shadowGeo, shadowMaterial);
      mesh.position.x =
        (helper.geometry.boundingBox!.max.x +
          helper.geometry.boundingBox!.min.x) /
        2;
      mesh.position.y =
        (helper.geometry.boundingBox!.max.y +
          helper.geometry.boundingBox!.min.y) /
        2;
      mesh.scale.x =
        (helper.geometry.boundingBox!.max.x -
          helper.geometry.boundingBox!.min.x) *
        1.5;
      mesh.scale.y =
        (helper.geometry.boundingBox!.max.y -
          helper.geometry.boundingBox!.min.y) *
        1.5;
      model.add(mesh);
      this.model = model;
      this.modelLoaded = true;
    }
    return this.model;
  }

  cloneModel(): THREE.Object3D {
    if (this.modelLoaded && this.model) return this.model.clone();
    else
      throw Error("Model must be loaded before clone. Call loadModel first.");
  }
}

class CarModelState {
  position: THREE.Vector3 | undefined;
  rotation: THREE.Euler | undefined;

  constructor(position: THREE.Vector3, rotation?: THREE.Euler) {
    this.position = position;
    this.rotation = rotation;
  }
}

export class CarModel {
  config: ModelConfig;
  model: THREE.Object3D;
  constructor(config: ModelConfig) {
    this.config = config;
    this.model = config.cloneModel();
    this.model.userData.carModel = this;
  }

  setState(state: CarModelState) {
    if (state.position) this.model.position.copy(state.position);
    if (state.rotation) this.model.rotation.copy(state.rotation);
  }

  highlight(close = false) {
    // Deprecated.
  }
}

interface ISuperPlayerMessage {
  shouldRemove?: boolean;
}

class CarPlayer extends AbstractPlayer {
  model: CarModel;
  id: string;
  enableFrameInterpolation: boolean = true;

  constructor(model: CarModel, id: string) {
    super();
    this.model = model;
    this.model.model.userData.carId = this.id = id;
  }

  angleInterp(ang1: number, ang2: number, percentage: number): number {
    let delta = normAngle(ang2 - ang1);
    if (delta > Math.PI) delta = delta - Math.PI * 2;
    return ang1 + delta * percentage;
  }

  /**
   * 设置车辆插帧（位置、朝向等）。
   * 从lastKeyFrame向currentKeyFrame插帧：
   * 将车辆置于lastKeyFrame状态和currentKeyFrame
   *     状态的中间处（由参数percentage决定）的某个状态。
   * @param percentage    插帧百分比，0.0-1.0
   * @param kf1           第一帧
   * @param kf2           第二帧
   * @param interpPosition 是否进行位置插帧
   * @param interpRotation 是否进行转向插帧
   */
  setF2FPercentage(
    percentage: number,
    kf1: IMovementKeyFrame,
    kf2: IMovementKeyFrame,
    interpPosition: boolean = true,
    interpRotation: boolean = true
  ): CarModelState {
    if (percentage < 0.0 || percentage > 1.0) {
      console.error("setF2FPercentage参数错误。");
      throw Error("setF2FPercentage参数错误。");
    }

    const positionCurrent = kf1.position;
    const positionNext = kf2.position;
    const rotationCurrent = kf1.direction;
    const rotationNext = kf2.direction;
    const delta = positionNext.clone().sub(positionCurrent);

    const angle = new THREE.Vector2(delta.x, delta.y).angle();
    let rotation: THREE.Euler;
    if (
      rotationCurrent &&
      rotationNext &&
      interpRotation &&
      delta.length() > 3 * this.model.config.scale.x
    ) {
      // 进行方向插帧
      const rz =
        percentage < 0.5
          ? this.angleInterp(rotationCurrent.z, angle, percentage * 2)
          : this.angleInterp(angle, rotationNext.z, (percentage - 0.5) * 2);
      rotation = new THREE.Euler(0, 0, rz);
    } else if (rotationCurrent) {
      // 不插帧，使用rotationCurrent
      rotation = rotationCurrent;
    } else {
      // 不插帧，计算rotation
      rotation = new THREE.Euler(0, 0, angle);
    }
    const position = interpPosition
      ? positionCurrent.clone().add(delta.multiplyScalar(percentage))
      : positionCurrent;
    return new CarModelState(position, rotation);
  }

  play(timing: number): ISuperPlayerMessage {
    this.maybeRollback(timing);

    const currentFrame = this.getCurrentKeyFrame(timing);
    const nextFrame = this.getNextKeyFrame();

    if (currentFrame) {
      if (KeyFrameTool.isFunctionalFrame(currentFrame)) {
        // 处理功能帧

        const functionalFrame = currentFrame as IFunctionalKeyFrame;
        if (functionalFrame.hide) {
          this.model.model.visible = false;
        } else if (functionalFrame.remove) {
          return { shouldRemove: true };
        }
      } else if (KeyFrameTool.isMovementFrame(currentFrame)) {
        this.model.model.visible = true;
        if (nextFrame && KeyFrameTool.isMovementFrame(nextFrame)) {
          // 本帧及下一帧均为位置关键帧，且插帧开关打开，需要插帧
          const percentage =
            (timing - currentFrame.timing) / currentFrame.length;
          const newState = this.setF2FPercentage(
            percentage,
            currentFrame as IMovementKeyFrame,
            nextFrame as IMovementKeyFrame,
            this.enableFrameInterpolation,
            this.enableFrameInterpolation
          );
          this.model.setState(newState);
        } else {
          // 无需插帧
          this.model.setState(
            new CarModelState(
              (currentFrame as IMovementKeyFrame).position,
              (currentFrame as IMovementKeyFrame).direction
            )
          );
        }
        if (currentFrame.userData)
          Object.assign(this.model.model.userData, currentFrame.userData);
      } else {
        console.error("无法识别帧类型。", currentFrame);
        throw Error("无法识别帧类型。");
      }
    }

    return {};
  }
}

interface CarModelConfigMap {
  [key: string]: ModelConfig;
}

export class CarCrowdPlayer implements ICrowdPlayer {
  private carPlayers: {
    [carId: string]: { player: CarPlayer; firstAppearTiming: number };
  } = {};
  private readonly carModelConfigs: CarModelConfigMap;
  private isInitialized: boolean = false;
  public readonly scene: THREE.Scene = new THREE.Scene();
  private superScene: THREE.Scene;
  private lastTiming: number = -Infinity;
  private enableFrameInterpolation: boolean = true;

  public getModelIdOfCar: (carId: string) => string;

  constructor(
    carModelConfigs: CarModelConfigMap,
    superScene: THREE.Scene,
    cbGetModelIdOfCar?: (carId: string) => string
  ) {
    this.carModelConfigs = carModelConfigs;
    this.superScene = superScene;
    this.getModelIdOfCar = cbGetModelIdOfCar || this.hashCarModel;
  }

  public async init() {
    if (this.isInitialized) {
      console.warn("Trying to re-initialize CarCrowdPlayer takes no effect.");
      return;
    }
    await Promise.all(
      Object.values(this.carModelConfigs).map((v) => {
        return v.loadModel();
      })
    );
    this.superScene.add(this.scene);
    this.isInitialized = true;
  }

  hashCarModel(carId: string): string {
    const configIds = Object.keys(this.carModelConfigs);
    return configIds[parseInt(carId) % configIds.length];
  }

  public appendKeyFrames(keyFrames: { [carId: string]: IKeyFrame }) {
    Object.entries(keyFrames).forEach(([carId, keyFrame]) => {
      if (!this.carPlayers.hasOwnProperty(carId)) {
        const modelId = this.getModelIdOfCar(carId);

        this.carPlayers[carId] = {
          player: new CarPlayer(
            new CarModel(this.carModelConfigs[modelId]),
            carId
          ),
          firstAppearTiming: keyFrame.timing,
        };
      }
      this.carPlayers[carId].player.enqueueKeyFrame(keyFrame);
    });
  }

  public play(timing: number) {
    if (!this.isInitialized) {
      throw Error("Call init() first before playing.");
    }
    Object.entries(this.carPlayers).forEach(
      ([carId, { player: carPlayer, firstAppearTiming }]) => {
        if (timing >= firstAppearTiming) {
          if (this.lastTiming < firstAppearTiming) {
            // 第一次出现在场景中，添加。
            this.scene.add(carPlayer.model.model);
            carPlayer.model.model.visible = true;
          }
          // 插帧开关检测
          if (
            carPlayer.enableFrameInterpolation !== this.enableFrameInterpolation
          ) {
            carPlayer.enableFrameInterpolation = this.enableFrameInterpolation;
          }
          const superPlayerMessage: ISuperPlayerMessage =
            carPlayer.play(timing);
          if (superPlayerMessage.shouldRemove) {
            // 收到移除消息，移除。
            this.scene.remove(carPlayer.model.model);
            delete this.carPlayers[carId];
          }
        } else {
          if (carPlayer.model.model.visible)
            carPlayer.model.model.visible = false;
        }
      }
    );
    this.lastTiming = timing;
  }

  getCarModel(carId: string): THREE.Object3D | undefined {
    return this.carPlayers[carId]?.player.model.model;
  }

  clear() {
    this.lastTiming = -Infinity;
    Object.entries(this.carPlayers).forEach(
      ([carId, { player: carPlayer }]) => {
        this.scene.remove(carPlayer.model.model);
        delete this.carPlayers[carId];
      }
    );
  }

  toggleFrameInterp(toggle: boolean) {
    this.enableFrameInterpolation = toggle;
  }
}
