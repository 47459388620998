import mapboxgl from "mapbox-gl";
import axios from "axios";

import {StatefulGeoLayer} from "./GeoLayer";
import {
    Feature,
    IStatefulGeoSource, StatefulGeoSource,
} from "../../../core/utils/StatefulGeoSource";
import {ElecNodeState, ElecState, ElecType} from "../../../core/GeoPropDefs";
import {FilterComponent} from "../BaseCustomLayer";

const minZoomToShowSmallElec = 12;
const smallElecGt = ElecType.ET_110KV;

const baseMapIconSize: number[] = [];
baseMapIconSize[0] = 0;
baseMapIconSize[ElecType.ET_500KV] = 0.4;
baseMapIconSize[ElecType.ET_220KV] = 0.3;
baseMapIconSize[ElecType.ET_110KV] = 0.2;
baseMapIconSize[ElecType.ET_10KV] = 0.1;


export class ElecNodeLayer extends StatefulGeoLayer<ElecNodeState> {

    filterComponent?: FilterComponent;

    async init(map: mapboxgl.Map): Promise<void> {
        await super.init(map);
        this.filterComponent = new FilterComponent(map, this.id, {
            stateFilter: ['boolean', true],
            typeFilter: ['boolean', true],
            zoomFilter: ['any',
                ['all',
                    ['>', ['get', 'type'], smallElecGt],
                    ['>', ['zoom'], minZoomToShowSmallElec],
                ],
                ['<=', ['get', 'type'], smallElecGt]
            ],
        });
        await this.statefulSource!.setFeatures(
            axios.get('/geojson_elec_beijing_0518.json')
                .then(resp => (resp.data as Feature[]).filter(f => f.geometry.type === "Point"))
        );

        // map.loadImage("/images/comm/基站-正常.png", (err, image) => {
        //     if (err) throw err;
        //     this.map!.addImage("BaseStation", image!);
        // });
    }

    getLayerDef(): mapboxgl.AnyLayer {
        return {
            type: "symbol",
            layout: {
                "icon-allow-overlap": false,
                "icon-image": [
                    "match",
                    ["get", "type"],
                    ElecType.ET_500KV,
                    [
                        "match", ["number", ["get", "state"], ElecState.NORMAL],
                        ElecState.NORMAL,
                        '变压器-500kv-正常',
                        '变压器-500kv-异常'
                    ],
                    ElecType.ET_220KV,
                    [
                        "match", ["number", ["get", "state"], ElecState.NORMAL],
                        ElecState.NORMAL,
                        '变压器-220kv-正常',
                        '变压器-220kv-异常'
                    ],
                    ElecType.ET_110KV,
                    [
                        "match", ["number", ["get", "state"], ElecState.NORMAL],
                        ElecState.NORMAL,
                        '变压器-110kv-正常',
                        '变压器-110kv-异常'
                    ],
                    ElecType.ET_10KV,
                    [
                        "match", ["number", ["get", "state"], ElecState.NORMAL],
                        ElecState.NORMAL,
                        '变压器-10kv-正常',
                        '变压器-10kv-异常'
                    ],
                    "变电站-正常",
                ],
                "icon-size": ['step',
                    ['zoom'],
                    ["at",
                        ["get", "type"],
                        ['literal', baseMapIconSize],
                    ],
                    13,
                    [
                        '*',
                        ["at",
                            ["get", "type"],
                            ['literal', baseMapIconSize],
                        ],
                        2.0
                    ],
                    16,
                    [
                        '*',
                        ["at",
                            ["get", "type"],
                            ['literal', baseMapIconSize],
                        ],
                        3.0
                    ]
                ],
            }
        } as any;
    }

    async getStatefulSource(): Promise<IStatefulGeoSource<ElecNodeState>> {
        return new StatefulGeoSource<ElecNodeState>(
            this.map!, this.id, 'id', {state: ElecState.NORMAL}
        );
    }

    filter(filter: number[]) {
        if (filter.length > 0) {
            this.filterComponent!.filters.typeFilter = [
                "in",
                ['get', 'type'],
                ["literal", filter]
            ];
        } else {
            this.filterComponent?.clear();
        }
        this.filterComponent?.applyFilters();
    }

    toggleBreakDown(toggle: boolean) {
        if (toggle) {
            this.filterComponent!.filters.stateFilter = ['!=', ['get', 'state'], ElecState.NORMAL];
        } else {
            this.filterComponent!.filters.stateFilter = ['boolean', true];
        }
        this.filterComponent?.applyFilters();
    }
}


export class ElecLinkLayer extends StatefulGeoLayer<{}> {

    filterComponent?: FilterComponent;

    async init(map: mapboxgl.Map): Promise<void> {
        await super.init(map);
        this.filterComponent = new FilterComponent(map, this.id, {
            typeFilter: ['boolean', true],
            zoomFilter: ['any',
                ['all',
                    ['<=', ['zoom'], minZoomToShowSmallElec],
                    ['<=', ["to-number", ["slice", ["to-string", ["at", 0, ["get", "relation"]]], 0, 1]], smallElecGt],
                    ['<=', ["to-number", ["slice", ["to-string", ["at", 1, ["get", "relation"]]], 0, 1]], smallElecGt],
                ],
                ['>', ['zoom'], minZoomToShowSmallElec],
            ]
        });
        await this.statefulSource!.setFeatures(
            axios.get('/geojson_elec_beijing_0518.json')
                .then(resp => (resp.data as Feature[]).filter(f => f.geometry.type === "LineString"))
        );
    }

    getLayerDef(): mapboxgl.AnyLayer {
        return {
            type: "line",
            layout: {
                "line-join": "round",
                "line-cap": "round",
            },
            paint: {
                "line-color": "#ffde59",
                "line-width": 2,
                "line-opacity": 0.5,
            },
        } as any;
    }

    async getStatefulSource(): Promise<IStatefulGeoSource<{}>> {
        return new StatefulGeoSource<{}>(
            this.map!, this.id, 'id', {}
        );
    }

    filter(filter: number[]) {
        if (filter.length > 0) {
            this.filterComponent!.filters.typeFilter = [
                "all",
                [
                    "in",
                    ["to-number", ["slice", ["to-string", ["at", 0, ["get", "relation"]]], 0, 1]],
                    ["literal", filter]
                ],
                [
                    "in",
                    ["to-number", ["slice", ["to-string", ["at", 1, ["get", "relation"]]], 0, 1]],
                    ["literal", filter]
                ],
            ];
        } else {
            this.filterComponent?.clear();
        }
        this.filterComponent?.applyFilters();
    }
}