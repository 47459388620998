import React, { Component } from "react";
import mapboxgl, { Marker } from "mapbox-gl";
import { message } from "antd";
import {BaseCustomLayer} from "./BaseCustomLayer";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZmh5ZHJhbGlzayIsImEiOiJja3VzMWc5NXkwb3RnMm5sbnVvd3IydGY0In0.FrwFkYIMpLbU83K9rHSe8w";

export interface MarkInfo {
  color?: string;
  coordinate: mapboxgl.LngLatLike;
  info: any;
  onClick?: (info: any, e: mapboxgl.MapMouseEvent) => void;
}

interface MapboxProps {
  lng: number;
  lat: number;
  zoom: number;
  pitch?: number;
  buildingLayer: boolean;
  externalLayers: any[];
  otherLayers?: any[];
  sources?: { name: string; data: any }[];
  style: any;
  markers?: MarkInfo[];
  images?: {name: string, path: string}[];
}

class Mapbox extends Component<MapboxProps> {
  mapContainer: React.RefObject<any>;
  map?: mapboxgl.Map;

  constructor(props: MapboxProps) {
    super(props);
    const { lng, lat, zoom, pitch } = props;
    this.state = {
      lng: lng,
      lat: lat,
      zoom: zoom,
      pitch: pitch,
    };
    this.mapContainer = React.createRef();
  }

  async componentDidMount() {
    const { lng, lat, zoom, pitch } = this.props;
    // const center = this.spline.getPoint(0.01)
    // console.log(new mapboxgl.MercatorCoordinate(center.x, center.y).toLngLat())
    this.map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: "mapbox://styles/mapbox/light-v10",
      center: [lng, lat],
      zoom: zoom,
      pitch: pitch,
    });
    this.map.dragRotate.disable();
    this.map.on("load", () => this.onMapLoad());
  }

  async onMapLoad() {
    console.log("map loaded.", this.map);
    message.loading({ content: "正在加载地图.", key: "loading", duration: 15 });
    setTimeout(() => {
      message.success({
        content: "地图加载完成！",
        key: "loading",
        duration: 2,
      });
    }, 10000);
    if (this.props.buildingLayer) this.addBuildingLayer();
    if (this.props.sources) {
      this.props.sources.forEach(({ name, data }) =>
        this.map!.addSource(name, data)
      );
    }
    if (this.props.images) {
      this.props.images.forEach(imageProp => {
        this.map!.loadImage(imageProp.path, (error, image) => {
          if (error) throw error;
          if (!this.map!.hasImage(imageProp.name))
            this.map!.addImage(imageProp.name, image!);
        });
      })
    }
    if (this.props.markers) {
      this.props.markers.forEach((marker) => {
        const m = new Marker({ color: marker.color }).setLngLat(
          marker.coordinate
        );

        m.addTo(this.map!);
      });
    }
    this.props.externalLayers.forEach((layer) => {
      this.map!.addLayer(layer, "waterway-label");
      // this.map!.setLayoutProperty(layer.id, "visibility", "none");
    });

    if (this.props.otherLayers) {
      const clickableLayerName: string[] = [];
      for (const oL of this.props.otherLayers) {
        await oL.init(this.map!);
        if (oL.canClick && oL.clickableLayers.length > 0) {
          clickableLayerName.push(...oL.clickableLayers);
        }
      }
      this.map!.on('click', clickableLayerName, (e) => {
        this.onMapClicked(e);
      });
    }
  }

  onJobLoaded(jobName: string) {
    this.props.otherLayers?.forEach(layer => {
      layer.onJobLoaded(jobName);
    })
  }

  onMapClicked(e: {features?: mapboxgl.MapboxGeoJSONFeature[]}) {
    console.log("outer click", e.features);
    const features = e.features;
    const clickableLayer = this.props.otherLayers!.filter(l => l.canClick);
    const clMap: {[id: string]: {
      layer: BaseCustomLayer,
      features: any[];
    }} = {};
    if (features) {

      // features按所属layer分组
      features.forEach(feature => {
        clickableLayer.forEach(cL => {
          if (cL.clickableLayers.includes(feature.layer.id)) {
            clMap[cL.id] = clMap[cL.id] ?? {
              layer: cL,
              features: []
            };

            clMap[cL.id].features.push(feature);
          }
        });
      });

      // 向layer发送事件
      Object.values(clMap).forEach(({layer, features}) => {
        layer.on('click', features);
      });
    }
  }

  addBuildingLayer() {
    this.map!.addLayer(
      {
        id: "buildings",
        source: "composite",
        "source-layer": "building",
        filter: ["==", "extrude", "true"],
        type: "fill-extrusion",
        minzoom: 15,
        paint: {
          "fill-extrusion-color": "#fff",

          // Use an 'interpolate' expression to
          // add a smooth transition effect to
          // the buildings as the user zooms in.
          "fill-extrusion-height": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            ["*", ["get", "height"], 5],
          ],
          "fill-extrusion-base": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            ["get", "min_height"],
          ],
          "fill-extrusion-opacity": 0.6,
        },
      },
      "waterway-label"
    );
  }

  render() {
    const { style } = this.props;
    return (
      <div>
        <div ref={this.mapContainer} className="map-container" style={style} />
      </div>
    );
  }
}

export default Mapbox;
