import {AbstractPlayer, ICrowdPlayer} from "./AbstractPlayer";
import {IKeyFrame, ITypedDataKeyFrame} from "./KeyFrame";
import {IStatefulGeoSource} from "../utils/StatefulGeoSource";


export class GeoSourceStateChangePlayer<StateType> extends AbstractPlayer implements ICrowdPlayer  {

    lastFrameTime?: number;

    constructor(private statefulGeoSource: IStatefulGeoSource<StateType>) {
        super();
    }

    appendKeyFrames(keyFrames: { [p: string]: IKeyFrame }): any {
        this.enqueueKeyFrame(keyFrames.geo);
    }

    clear(): void {
        this.statefulGeoSource.clearAllStates();
        super.clear();
    }

    play(timing: number): any {
        const keyFrame = this.getCurrentKeyFrame(timing) as ITypedDataKeyFrame<any>;
        if (keyFrame && this.lastFrameTime !== keyFrame.timing) {
            console.log(keyFrame);
            if (keyFrame.typeDef === 'state-change') {
                this.statefulGeoSource.setStates(keyFrame.data);
            } else if (keyFrame.typeDef === 'state-change-full') {
                this.statefulGeoSource.clearAllStates(false);
                this.statefulGeoSource.setStates(keyFrame.data);
            } else if (keyFrame.typeDef === 'geo-source') {
                this.statefulGeoSource.setFeaturesSync(keyFrame.data);
            }
        }
        this.lastFrameTime = keyFrame ? keyFrame.timing : undefined;
    }

}
