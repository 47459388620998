import { LngLatLike } from "mapbox-gl";
import * as THREE from "three";

import {
  ICustomDataKeyFrame,
  IFunctionalKeyFrame,
  IKeyFrame,
  IMovementKeyFrame,
  IStateChangeKeyFrame,
  ITypedDataKeyFrame,
  KeyFrameType,
} from "../../core/player/KeyFrame";
import { CoordinateTransformer } from "../../core/utils/coordutils";
import {StatefulGeoSourceState} from "../../core/utils/StatefulGeoSource";


export class RoadKeyFrameFactory {
  newKeyFrame(timing: number, length: number, state: number): IKeyFrame {
    return {
      timing,
      length,
      state,
      frameType: KeyFrameType.movement,
    } as IStateChangeKeyFrame;
  }

  static newKeyFrame(timing: number, length: number, state: number): IKeyFrame {
    return {
      timing,
      length,
      state,
      frameType: KeyFrameType.movement,
    } as IStateChangeKeyFrame;
  }
}

export class CopyDataFrameFactory {
  newCustomDataKeyFrame(timing: number, length: number, data: any): IKeyFrame {
    return CopyDataFrameFactory.newCustomDataKeyFrame(timing, length, data);
  }

  static newCustomDataKeyFrame(timing: number, length: number, data: any): IKeyFrame {
    return {
      timing,
      length,
      data: data,
      frameType: KeyFrameType.movement,
    } as ICustomDataKeyFrame;
  }

  newTypedCustomDataKeyFrame<T>(timing: number, length: number, data: T, typeDef: string = ''): IKeyFrame {
    return CopyDataFrameFactory.newTypedCustomDataKeyFrame(timing, length, data, typeDef);
  }

  static newTypedCustomDataKeyFrame<T>(timing: number, length: number, data: T, typeDef: string = ''): IKeyFrame {
    return {
      timing,
      length,
      data: data,
      frameType: KeyFrameType.movement,
      typeDef,
    } as ITypedDataKeyFrame<T>;
  }
}

export class GeoStateChangeFrameFactory {
  newStateChangeKeyFrame<StateType>(
      timing: number,
      length: number,
      data: StatefulGeoSourceState<StateType>[],
      fullUpdate: boolean = false
  ) : IKeyFrame {
    return GeoStateChangeFrameFactory.newStateChangeKeyFrame(timing, length, data, fullUpdate);
  }

  static newStateChangeKeyFrame<StateType>(
      timing: number,
      length: number,
      data: StatefulGeoSourceState<StateType>[],
      fullUpdate: boolean = false
  ) : IKeyFrame {
    return {
      timing,
      length,
      data: data,
      frameType: KeyFrameType.movement,
      typeDef: fullUpdate ? 'state-change-full' : 'state-change'
    } as ITypedDataKeyFrame<StatefulGeoSourceState<StateType>[]>;
  }
}

export class GeoSourceChangeFrameFactory {
  newGeoSourceKeyFrame(timing: number, length: number, data: any[]) {
    return GeoSourceChangeFrameFactory.newGeoSourceKeyFrame(timing, length, data);
  }

  static newGeoSourceKeyFrame(timing: number, length: number, data: any[]) {
    return {
      timing,
      length,
      data: data,
      frameType: KeyFrameType.movement,
      typeDef: 'geo-source'
    } as ITypedDataKeyFrame<any[]>;
  }
}

export class CarLayerKeyFrameFactory {
  transformer: CoordinateTransformer;

  constructor(transformer: CoordinateTransformer) {
    this.transformer = transformer;
  }

  newMovementFrame(
    coordinate: LngLatLike,
    timing: number,
    length: number,
    direction?: number,
    userData: any = {}
  ): IKeyFrame {
    const position = this.transformer.lngLatToPos(coordinate);
    const d =
      direction !== undefined ? new THREE.Euler(0, 0, -direction) : undefined;
    return {
      position,
      direction: d,
      timing,
      length,
      frameType: KeyFrameType.movement,
      userData,
    } as IMovementKeyFrame;
  }

  newFunctionalFrame(
    timing: number,
    length: number = Infinity,
    hide: boolean = false,
    remove: boolean = false
  ): IKeyFrame {
    return {
      frameType: KeyFrameType.functional,
      timing,
      length,
      hide,
      remove,
    } as IFunctionalKeyFrame;
  }
}

export class PedsimKeyFrameFactory {
  newMovementFrame(
    position: THREE.Vector3,
    timing: number,
    length: number,
    direction?: number,
    userData: any = {}
  ): IKeyFrame {
    const d =
      direction !== undefined ? new THREE.Euler(0, 0, -direction) : undefined;
    return {
      position,
      direction: d,
      timing,
      length,
      frameType: KeyFrameType.movement,
      userData,
    } as IMovementKeyFrame;
  }

  newFunctionalFrame(
    timing: number,
    length: number = Infinity,
    hide: boolean = false,
    remove: boolean = false
  ): IKeyFrame {
    return {
      frameType: KeyFrameType.functional,
      timing,
      length,
      hide,
      remove,
    } as IFunctionalKeyFrame;
  }
}
