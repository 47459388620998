import React from "react";
import queryString from "query-string";
import WolongVis from "./Pages/WolongVis";
import {
    Route,
    Switch,
    Redirect,
    BrowserRouter as Router,
} from "react-router-dom";
import HomePage from "./Pages/HomePage";
import RainStartPage from "./Pages/RainStartPage";
import WarStartPage from "./Pages/WarStartPage";

import "./App.css";
import Store from './store/store'

class App extends React.Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path="/">
                        <RainStartPage />
                    </Route>
                    <Route path="/sim">
                        <WolongVis
                            displayVersion="v1.0a"
                            twoSide={true}
                        />
                    </Route>
                    <Route path="/simone">
                        <WolongVis
                            displayVersion="v1.0a"
                            twoSide={false}
                        />
                    </Route>
                </Switch>
            </Router>
        );
    }
}

export default App;
