import {BaseCustomLayer, FilterComponent} from "./BaseCustomLayer";
import { PlayerManager } from "../../core/player/PlayerManager";
import { RoadKeyFrameFactory } from "./FrameFactories";
import { Map } from "mapbox-gl";
import { WaterReader } from "./GeoJsonReader";
import { AllSupplyPlayer } from "../../core/player/Supply";
import {WaterState} from "../../core/GeoPropDefs";

export interface IWaterReader {
  get(): Promise<any>;
}

export class SupplyLayer extends BaseCustomLayer {
  map: any;

  waterReader: IWaterReader;
  waterPlayer: AllSupplyPlayer | undefined;

  data: { lineData: any; pointData: any } = { lineData: [], pointData: [] };

  widthScale: number = 1.0;

  initialized: boolean = false;

  player: PlayerManager;
  keyFrameFactory = new RoadKeyFrameFactory();
  index: number;

  filterComponent?: FilterComponent;
  nodeFilterComponent?: FilterComponent;

  subLayerIdToLocalVisibility = {
    supply: true,
    supply_point: true,
  }

  constructor(
    id: string,
    index: number,
    props: {
      playerManager: PlayerManager;
      waterReader: WaterReader;
    }
  ) {
    super(id, "3d", props);
    this.index = index;
    this.player = props.playerManager;

    this.waterReader = props.waterReader;
  }

  async init(map: Map) {
    this.map = map;
    this.filterComponent = new FilterComponent(this.map, 'supply', {
      stateFilter: ['boolean', true]
    });
    this.nodeFilterComponent = new FilterComponent(this.map, 'supply_point', {
      stateFilter: ['boolean', true]
    });
    this.data = await this.waterReader.get();

    this.map.addSource("supply_point", {
      type: "geojson",

      data: {
        type: "FeatureCollection",
        features: this.data.pointData,
      },
    });

    this.map.addSource("supply", {
      type: "geojson",

      data: {
        type: "FeatureCollection",
        features: this.data.lineData,
      },
    });

    this.map.addLayer({
      id: "supply",
      type: "line",
      source: "supply",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": [
          "match",
          ["get", "state"],
          WaterState.NORMAL,
          "#2F80ED",
          WaterState.BAD,
          "#CD06DE",
          /* other */ "#ccc",
        ],
        "line-width": ["match", ["get", "state"], WaterState.NORMAL, 1, WaterState.BAD, 5, /* other */ 3],
        "line-opacity": 0.8,
      },
    });

    this.map.addLayer({
      id: "supply_point",
      type: "circle",
      source: "supply_point",
      paint: {
        "circle-radius": {
          base: 2,
          stops: [
            [12, 2],
            [22, 180],
          ],
        },
        "circle-color": "#2F80ED",
      },
    });
    const allWaterPlayer = new AllSupplyPlayer(this.map, this.data);
    allWaterPlayer.init();
    this.waterPlayer = allWaterPlayer;

    this.player.attachSubCrowdPlayer(`supply_${this.index}`, allWaterPlayer);

    console.log("SupplyPlayer initialized.");
    this.initialized = true;
    this.setLayerVisibility(false);
  }

  toggleBreakDown(toggle: boolean) {
    this.nodeFilterComponent!.filters.stateFilter = ['boolean', !toggle];
    if (toggle) {
      this.filterComponent!.filters.stateFilter = ['==', ['get', 'state'], WaterState.BAD];
    } else {
      this.filterComponent!.filters.stateFilter = ['boolean', true];
    }
    this.filterComponent!.applyFilters();
    this.nodeFilterComponent!.applyFilters();
  }
}
