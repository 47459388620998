import mapboxgl, {Map, SymbolLayer} from "mapbox-gl";
import { PlayerManager } from "../../core/player/PlayerManager";
import { AllDemandPlayer } from "../../core/player/Demand";

import { BaseCustomLayer } from "./BaseCustomLayer";
import { RoadKeyFrameFactory } from "./FrameFactories";
import { AoiReader } from "./GeoJsonReader";

export interface IAoiReader {
  get(): Promise<any>;
}

type IconProps = { imageSatisfied: string, imageUnsatisfied: string };
type DemandLogoLayerDef = {
  layerName: string,
  demandType: string,
  icons: IconProps
};

const imageWidth = 40;
const iconSize = 0.5;
const zoomMin = 15;
const zoomSolid = 16;
const minArea = 1000;

const demandLogoLayer = (layerName: string, demandType: string, icon: IconProps, index: number) => ({
  id: layerName,
  type: 'symbol',
  source: `${demandType}_demand_point`,
  filter: ['all',
    ['==', ['get', 'inside_micro'], 1],
    ['>', ['get', 'area'], minArea],
  ],
  minzoom: zoomMin,
  layout: {
    "icon-allow-overlap": true,
    "icon-image": [
      "case",
      [">", ["coalesce", ["get", "unsatisfiedLevel"], 0], 0],
      icon.imageUnsatisfied,  // 不满足
      icon.imageSatisfied   // 满足
    ],
    // "icon-image": icon.imageUnsatisfied,
    "icon-offset": [(index - 1) * imageWidth / iconSize, 0],
    "icon-size": iconSize,
  },
  paint: {
    "icon-opacity": ["interpolate",
      ["linear"],
      ["zoom"],
      zoomMin,
      0,
      zoomSolid,
      1,
    ]
  }
});

export class DemandLogoLayer extends BaseCustomLayer {
  map?: mapboxgl.Map;

  data: { lineData: any; pointData: any } = { lineData: [], pointData: [] };
  widthScale: number = 1.0;
  initialized: boolean = false;
  layerDefs: DemandLogoLayerDef[];

  constructor(
    id: string,
    props: {
      typeDefs: DemandLogoLayerDef[];
    }
  ) {
    super(id, "2d", props);
    this.type = "symbol";
    this.layerDefs = props.typeDefs;
    this.layerDefs.forEach(ld => {
      this.subLayerIdToLocalVisibility[ld.layerName] = true;
    });

  }

  async init(map: Map) {
    this.map = map;

    this.layerDefs.forEach((ld, index) => {
      console.log(ld);
      this.map!.addLayer(demandLogoLayer(ld.layerName, ld.demandType, ld.icons, index) as SymbolLayer);
    });

    console.log("DemandLogoLayer initialized.");
    this.initialized = true;
    this.setLayerVisibility(false);
  }

  setLayerVisibility(visible: boolean) {
    super.setLayerVisibility(visible);
    console.log(this.map, visible);
  }
}
