import { multiRequest, MultiRequestTarget, MultiResponse } from "./RequestHub";
import { message } from "antd";
import {
    FrameInfoFrameConstructor,
    StatefulCarFrameConstructor,
    StatefulPedsimFrameConstructor,
    StatefulPeopleFrameConstructor,
    StatisticsFrameConstructor,
    TrafficLightFrameConstructor,
} from "./FrameConstructor";
import { FrameFetcher } from "../player/PlayerManager";
import { LngLatBounds } from "mapbox-gl";
import { BaseStationState, CommState, ElecNodeState, ElecState, WaterState } from "../GeoPropDefs";
import { IKeyFrame } from "../player/KeyFrame";
import { GeoStateChangeFrameFactory } from "../../Components/mapbox/FrameFactories";
import { number } from "prop-types";
import { GetRainBaseByJob } from "../../utils/job";
// import {
//   CopyDataFrameFactory,
//   GeoStateChangeFrameFactory,
//   RoadKeyFrameFactory
// } from "../../Components/mapbox/FrameFactories";

interface IFrameConstructor {
    car: StatefulCarFrameConstructor;
    people: StatefulPeopleFrameConstructor;
    trafficLight: TrafficLightFrameConstructor;
    pedsim: StatefulPedsimFrameConstructor;
    statistics: StatisticsFrameConstructor;
    frameInfo: FrameInfoFrameConstructor;
}
interface choseObj {
  currentId: number;

}

export class AllDataConvertor {
  frameConstructors: IFrameConstructor;
  maxFrame: number;
  frameLength: number;
  keyframeFactories: {
    [key: string]: any;
  };
  elecData: any[];
  commData: any[];
  elecDemandData: any[];
  commDemandData: any[];
  waterDemandData: any[];
  pid: any;
  chooseState: {
    "aoi-detail"?: number,
    "road-detail"?: number,
  }[];
  constructor(
    frameConstructors: IFrameConstructor,
    maxFrame: number,
    keyframeFactories: {
      [key: string]: any;
    },
    frameLength = 1
  ) {
    this.frameConstructors = frameConstructors;
    this.maxFrame = maxFrame;
    this.keyframeFactories = keyframeFactories;
    this.frameLength = frameLength;
    this.elecData = [[], []];
    this.commData = [[], []];
    this.elecDemandData = [[], []];
    this.commDemandData = [[], []];
    this.waterDemandData = [[], []];
    this.chooseState = [{}, {}]
    this.pid = PubSub.subscribe('choseType', this.myToplevelSubscriber);//myToplevelSubscriber是一个函数

    }

  myToplevelSubscriber = (msg: any, data: { type: "aoi-detail" | "road-detail", currentID: number, currentIndex: number }): void => {
    this.chooseState[data.currentIndex][data.type] = data.currentID;
  }

    async onFetch(
        name: string[],
        offset: number,
        count: number,
        elements: string[],
        fetcher: FrameFetcher,
        zoom: number[],
        bounds: LngLatBounds[],
        speed: number = 1
    ) {
        console.debug("App: AllLayerOnFetchRequest", offset, count, fetcher);
        if (offset > this.maxFrame) {
            fetcher.finalize();
            message.info("播放已结束");
        }
        if (offset + count > this.maxFrame) {
            count = this.maxFrame - offset;
        }
        const excludeMicro = zoom[0] < 16 && zoom[1] < 16;
        // const excludeAll = false;
        if (excludeMicro) {
            let idx = elements.indexOf("cars");
            if (idx !== -1) {
                elements.splice(idx, 1);
            }
            idx = elements.indexOf("people");
            if (idx !== -1) {
                elements.splice(idx, 1);
            }
            idx = elements.indexOf("traffic-lights");
            if (idx !== -1) {
                elements.splice(idx, 1);
            }
        }

        // construct query strings
        const targets: MultiRequestTarget[] = [];
        const begin = offset, end = offset + count;
        const mathArr = ["road-state", "elec-nodes", "water-drainage-link", "water-supply-link",
            "water-road", "comm-node", "road-level", "comm-demand", "water-road-metric", "water-drainage-link-metric", "water-supply-link-metric",
            "aoi", "heatmap", "comm-signal", "elec-aois", "comm-aoi", "water-aoi", "event", "elec-metric", "city-metric", "demand-metric", "comm-metric", "traffic-metric",
            "elec-stat", "traffic-stat", "comm-stat", "city-overview", "city-runtime", "water-stat"]
        for (let e of elements) {
            if (e === "cars" || e === "people" || e === "traffic-lights") {
                for (let i = 0; i < name.length; i++) {
                    const [lng1, lng2] = [
                        bounds[i].getWest(),
                        bounds[i].getEast(),
                    ].sort();
                    const [lat1, lat2] = [
                        bounds[i].getNorth(),
                        bounds[i].getSouth(),
                    ].sort();
                    if (zoom[i] < 16) {
                        targets.push({
                            url: `/${e}/${name[i]}?begin=${begin}&end=${end}&lat1=${0}&lat2=${0.0000000001}&lng1=${0}&lng2=${0.0000000001}`,
                            type: e,
                            index: i
                        });
                    } else {
                        targets.push({
                            url: `/${e}/${name[i]}?begin=${begin}&end=${end}&lat1=${lat1}&lat2=${lat2}&lng1=${lng1}&lng2=${lng2}`,
                            type: e,
                            index: i
                        });
                    }
                    // urls.push(
                    //   `/${e}/${name[i]}?begin=${begin}&end=${end}&lat1=${lat1}&lat2=${lat2}&lng1=${lng1}&lng2=${lng2}`
                    // );
                }
            } else if (
                mathArr.includes(e)
            ) {
                for (let i = 0; i < name.length; i++) {
                    let realName = name[i];
                    if (e.includes("water")) {
                        realName = GetRainBaseByJob(name[i])
                    }
                    targets.push({
                        url: `/${e}/${realName}?begin=${offset}&end=${end}&interval=${Math.ceil(speed / 2)}&noIncrement=true`,
                        type: e,
                        index: i
                    });
                }
            } else if ("special-car-trajectory" == e) {
                //抢修车，end取无穷大
                for (let i = 0; i < name.length; i++) {
                    targets.push({
                        url: `/${e}/${name[i]}?begin=${offset}&end=${1000000}&interval=${Math.ceil(speed / 2)}`,
                        type: e,
                        index: i
                    });
                }
            } else if (
                e === "comm-signal-micro"
            ) {
                for (let i = 0; i < name.length; i++) {
                    targets.push({
                        url: `/comm-signal/${name[i]}?begin=${offset}&end=${end}&interval=${Math.ceil(speed / 2)}&microscopic=true`,
                        type: e,
                        index: i
                    });
                }
            } else if (
                ["aoi-detail", "road-detail"].includes(e)
            ) {
                for (let i = 0; i < name.length; i++) {
                    // @ts-ignore
                    let currentId = this.chooseState[i][e];
                    let num_points = speed * 15 * 60, point_interval = 1
                    if (e == "aoi-detail") {
                        num_points = speed * 20
                    }
                    if (currentId) {
                        targets.push({
                            url: `/${e}/${name[i]}?begin=${offset}&end=${end}&interval=${Math.ceil(speed / 2)}&id=${currentId}&num_points=${num_points}&point_interval=${point_interval}`,
                            type: e,
                            index: i
                        });
                    }
                }
            }
        }

        if (elements.length === 0) {
            fetcher.start();
            let itemsByFrame: any[] = [];
            for (let i = 0; i < count; i++) {
                itemsByFrame.push({});
            }
            // write data to fetcher
            for (let i in itemsByFrame) {
                fetcher.write(offset + parseInt(i), itemsByFrame[parseInt(i)]);
            }
            fetcher.end();
            return;
        }
        try {
            fetcher.start();
            multiRequest(targets, (resp) => {
                // convert data struct and send them to frameConstructors
                const frameByTypeArray = this.res2frame(resp, elements, offset, count);
                // write data to fetcher
                for (let i in frameByTypeArray) {
                    fetcher.write(
                        frameByTypeArray[parseInt(i)].offset,
                        frameByTypeArray[parseInt(i)].frame
                    );
                }
                fetcher.end();
            });
        } catch (err) {
            console.log(err);
            fetcher.finalize();
            return;
        }
    }

    res2frame(responses: MultiResponse[], types: string[], offset: number, cnt: number) {
        let frameByTypeArray: {
            offset: number;
            frame: {
                [key: string]: any;
                // car?: any;
                // people?: any;
                // trafficLight?: any;
                // road?: any;
                // elec?: any;
                // drainage?: any;
                // flooded?: any;
                // comm?: any;
                // roadMetric?: any;
                // waterRoadMetric?: any;
                // waterDrainageLinkMetric?: any;
                // aoi?: any;
                // demand?: any;
            };
        }[] = [];
        for (let i = 0; i < cnt; i++) {
            frameByTypeArray[i] = { offset: offset + i, frame: {} };
        }

    const car2frame = (items: any[], n: number) => {
      let itemsByFrame = items.reduce((res, item) => {
        let idx = item.step - offset;
        if (!res[idx]) {
          res[idx] = [];
        }
        res[idx].push({
          carId: item.id.toString(),
          lng: item.lng,
          lat: item.lat,
          direction: item.direction,
          laneId: item.laneId.toString(),
        });
        return res;
      }, []);
      for (let i in itemsByFrame) {
        const constructedFrame = this.frameConstructors[
          "car"
        ].constructFrameFromItem(itemsByFrame[parseInt(i)], {
          frameLength: this.frameLength,
          offset: offset + parseInt(i),
        });
        frameByTypeArray[parseInt(i)].frame[`car_${n}`] = constructedFrame;
      }
    };

    const people2frame = (items: any[], n: number) => {
      let itemsByFrame = items.reduce((res, item) => {
        let idx = item.step - offset;
        if (!res[idx]) {
          res[idx] = [];
        }
        res[idx].push({
          peopleId: item.id.toString(),
          lng: item.lng,
          lat: item.lat,
          direction: item.direction,
          laneId: item.parentId.toString(),
        });
        return res;
      }, []);
      for (let i in itemsByFrame) {
        const constructedFrame = this.frameConstructors[
          "people"
        ].constructFrameFromItem(itemsByFrame[parseInt(i)], {
          frameLength: this.frameLength,
          offset: offset + parseInt(i),
        });
        frameByTypeArray[parseInt(i)].frame[`people_${n}`] = constructedFrame;
      }
    };

        const tl2frame = (items: any[], n: number) => {
            let itemsByFrame = items.reduce((res, item) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = [];
                }
                res[idx].push({
                    laneId: item.id.toString(),
                    state: item.state,
                });
                return res;
            }, []);
            for (let i in itemsByFrame) {
                const constructedFrame = this.frameConstructors[
                    "trafficLight"
                ].constructFrameFromItem(itemsByFrame[parseInt(i)], {
                    frameLength: this.frameLength,
                    offset: offset + parseInt(i),
                });
                frameByTypeArray[parseInt(i)].frame[`trafficLight_${n}`] =
                    constructedFrame;
            }
        };

        const sct2frame = (item: any, n: number) => {
            console.log(item)
            frameByTypeArray[0].frame[`special_car_${n}`] = {
                line: this.keyframeFactories.copyDataFactory.newCustomDataKeyFrame(
                    (item[0].step) * this.frameLength,
                    this.frameLength,
                    item[0].data
                )
            };
        };

    const roadDetail2frame = (items: any[], n: number) => {
      items.forEach(item => {
        const index = item.step - offset;
        frameByTypeArray[index].frame[`road_stat_${n}`] = {
          info: this.keyframeFactories.copyDataFactory.newTypedCustomDataKeyFrame(
            item.step * this.frameLength,
            Infinity,
            item
          )
        };
      });
    }

    const aoiDetail2frame = (items: any[], n: number) => {
      if (Array.isArray(items)) {
        items.forEach(item => {
          const index = item.step - offset;
          frameByTypeArray[index].frame[`aoi_stat_${n}`] = {
            info: this.keyframeFactories.copyDataFactory.newTypedCustomDataKeyFrame(
              item.step * this.frameLength,
              Infinity,
              item
            )
          };
        });
      }
    }

        const road2frame = (items: any[], n: number) => {
            const itemsByFrameEquiv: { [roadId: string]: IKeyFrame }[] = [];
            const level6Roads: number[][] = [];
            items.forEach(item => {
                const index = item.step - offset;
                itemsByFrameEquiv[index] = itemsByFrameEquiv[index] ?? {};
                itemsByFrameEquiv[index][item.id.toString()] = this.keyframeFactories[
                    "road"
                ].newKeyFrame(
                    item.step * this.frameLength,
                    this.frameLength,
                    item.level
                );
                if (item.level >= 6) {
                    level6Roads[index] = level6Roads[index] ?? [];
                    level6Roads[index].push(item.id);
                }
            });

            // write data to fetcher
            itemsByFrameEquiv.forEach((frames, index) => {
                frameByTypeArray[index].frame[`road_${n}`] = frames;
            });
            level6Roads.forEach((data, index) => {
                frameByTypeArray[index].frame[`stopped_road_${n}`] = {
                    macro: this.keyframeFactories.copyDataFactory.newCustomDataKeyFrame(
                        (index + offset) * this.frameLength,
                        this.frameLength,
                        data
                    )
                };
            });
        };

    const elec2frame = (items: { step: number, stoppedIds: number[], ruinedIds: number[] }[], n: number) => {
      items.forEach((item, index) => {
        const { stoppedIds, ruinedIds } = item;
        frameByTypeArray[index].frame[`elec_${n}`] = {
          geo: GeoStateChangeFrameFactory.newStateChangeKeyFrame<ElecNodeState>(
            item.step * this.frameLength,
            this.frameLength,
            [
              ...stoppedIds.map(id => ({ id, states: { state: ElecState.STOPPED } })),
              ...ruinedIds.map(id => ({ id, states: { state: ElecState.RUINED } }))
            ],
            true
          )
        };
      });
    }

    const elecMertic2Frame = (items: any[], n: number) => {
      let itemsByFrame_metric = items.reduce((res: any, item: any) => {
        let idx = item.step - offset;
        if (!res[idx]) {
          res[idx] = {};
        }
        res[idx]["0"] =
          this.keyframeFactories.waterDrainageLinkMetric.newCustomDataKeyFrame(
            item.step * this.frameLength,
            this.frameLength,
            {
              stoppedRatio: item.stoppedRatio,
              ruinedRatio: item.ruinedRatio,
            }
          );
        return res;
      }, []);
      // write data to fetcher
      for (let i in itemsByFrame_metric) {
        frameByTypeArray[parseInt(i)].frame[`elecNodeMetric_${n}`] =
          itemsByFrame_metric[parseInt(i)];
      }
    };

        const water2frame = (items: any[], n: number) => {
            let itemsByFrame = items.reduce((res: any, item: any) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = {};
                }
                res[idx][item.id] = this.keyframeFactories.water.newKeyFrame(
                    item.step * this.frameLength,
                    this.frameLength,
                    item.ok ? WaterState.NORMAL : WaterState.BAD
                );
                return res;
            }, []);

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`drainage_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

        const supply2frame = (items: any[], n: number) => {
            let itemsByFrame = items.reduce((res: any, item: any) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = {};
                }
                res[idx][item.id] = this.keyframeFactories.water.newKeyFrame(
                    item.step * this.frameLength,
                    this.frameLength,
                    item.ok ? WaterState.NORMAL : WaterState.BAD
                );
                return res;
            }, []);

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`supply_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

        const flooded2frame = (items: any[], n: number) => {
            let itemsByFrame = items.reduce((res: any, item: any) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = {};
                }
                res[idx][item.id.toString()] =
                    this.keyframeFactories.flooded.newKeyFrame(
                        item.step * this.frameLength,
                        this.frameLength,
                        item.level
                    );
                return res;
            }, []);

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`flooded_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

    const comm2frame = (items: { step: number, failureIds: number[], overloadIds: number[] }[], n: number) => {
      items.forEach((item, index) => {
        const { failureIds, overloadIds } = item;
        frameByTypeArray[index].frame[`comm_${n}`] = {
          geo: GeoStateChangeFrameFactory.newStateChangeKeyFrame<BaseStationState>(
            item.step * this.frameLength,
            this.frameLength,
            [
              ...failureIds.map(id => ({ id, states: { state: CommState.DIRECT } })),
              ...overloadIds.map(id => ({ id, states: { state: CommState.BROKEN } }))
            ],
            true
          )
        };
      });
    }

        const commMertic2Frame = (items: any[], n: number) => {
            let itemsByFrame_metric = items.reduce((res: any, item: any) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = {};
                }
                res[idx]["0"] =
                    this.keyframeFactories.waterDrainageLinkMetric.newCustomDataKeyFrame(
                        item.step * this.frameLength,
                        this.frameLength,
                        {
                            battery_ratio: item.battery_ratio,
                            failure_ratio: item.failure_ratio,
                        }
                    );
                return res;
            }, []);
            // write data to fetcher
            for (let i in itemsByFrame_metric) {
                frameByTypeArray[parseInt(i)].frame[`commNodeMetric_${n}`] =
                    itemsByFrame_metric[parseInt(i)];
            }
        };

        const roadMetric2frame = (items: any[], n: number) => {
            let itemsByFrame = items.reduce((res: any, item: any) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = {};
                }
                res[idx]["0"] = this.keyframeFactories.roadMetric.newCustomDataKeyFrame(
                    item.step * this.frameLength,
                    this.frameLength,
                    item
                );
                return res;
            }, []);

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`roadMetric_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

        const commDemandMetric2frame = (items: any[], n: number) => {
            let itemsByFrame = items.reduce((res: any, item: any) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = {};
                }
                res[idx]["0"] = this.keyframeFactories.roadMetric.newCustomDataKeyFrame(
                    item.step * this.frameLength,
                    this.frameLength,
                    item
                );
                return res;
            }, []);

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`commDemandMetric_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

        const waterRoadMetric2frame = (items: any[], n: number) => {
            let itemsByFrame = items.reduce((res: any, item: any) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = {};
                }
                res[idx]["0"] =
                    this.keyframeFactories.waterRoadMetric.newCustomDataKeyFrame(
                        item.step * this.frameLength,
                        this.frameLength,
                        item
                    );
                return res;
            }, []);

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`waterRoadMetric_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

        const waterDrainageLinkMetric2frame = (items: any[], n: number) => {
            let itemsByFrame = items.reduce((res: any, item: any) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = {};
                }
                res[idx]["0"] =
                    this.keyframeFactories.waterDrainageLinkMetric.newCustomDataKeyFrame(
                        item.step * this.frameLength,
                        this.frameLength,
                        item
                    );
                return res;
            }, []);

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`waterDrainageLinkMetric_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

        const waterSupplyLinkMetric2frame = (items: any[], n: number) => {
            let itemsByFrame = items.reduce((res: any, item: any) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = {};
                }
                res[idx]["0"] =
                    this.keyframeFactories.waterDrainageLinkMetric.newCustomDataKeyFrame(
                        item.step * this.frameLength,
                        this.frameLength,
                        item
                    );
                return res;
            }, []);

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`waterSupplyLinkMetric_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

        const commMetric2frame = (items: any[], n: number) => {
            let itemsByFrame = items.reduce((res: any, item: any) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = {};
                }
                res[idx]["0"] =
                    this.keyframeFactories.waterDrainageLinkMetric.newCustomDataKeyFrame(
                        item.step * this.frameLength,
                        this.frameLength,
                        item
                    );
                return res;
            }, []);

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`commMetric_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

        const trafficMetric2frame = (items: any[], n: number) => {
            let itemsByFrame = items.reduce((res: any, item: any) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = {};
                }
                res[idx]["0"] =
                    this.keyframeFactories.waterDrainageLinkMetric.newCustomDataKeyFrame(
                        item.step * this.frameLength,
                        this.frameLength,
                        item
                    );
                return res;
            }, []);

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`trafficMetric_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

        const cityMetric2frame = (items: any[], n: number) => {
            let itemsByFrame = items.reduce((res: any, item: any) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = {};
                }
                res[idx]["0"] =
                    this.keyframeFactories.waterDrainageLinkMetric.newCustomDataKeyFrame(
                        item.step * this.frameLength,
                        this.frameLength,
                        item
                    );
                return res;
            }, []);

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`cityMetric_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

        const demandMetric2frame = (items: any[], n: number) => {
            let itemsByFrame = items.reduce((res: any, item: any) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = {};
                }
                res[idx]["0"] =
                    this.keyframeFactories.waterDrainageLinkMetric.newCustomDataKeyFrame(
                        item.step * this.frameLength,
                        this.frameLength,
                        item
                    );
                return res;
            }, []);

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`demandMetric_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

        const event2frame = (items: any[], n: number) => {
            let itemsByFrame = items.reduce((res: any, item: any) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = {};
                }
                res[idx]["0"] =
                    this.keyframeFactories.waterDrainageLinkMetric.newCustomDataKeyFrame(
                        item.step * this.frameLength,
                        this.frameLength,
                        item
                    );
                return res;
            }, []);

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`event_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

        const elecDemandMetric2frame = (items: any[], n: number) => {
            let itemsByFrame = items.reduce((res: any, item: any) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = {};
                }
                res[idx]["0"] =
                    this.keyframeFactories.waterDrainageLinkMetric.newCustomDataKeyFrame(
                        item.step * this.frameLength,
                        this.frameLength,
                        item
                    );
                return res;
            }, []);

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`elecDemandMetric_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

        const waterState2frame = (items: any[], n: number) => {
            let itemsByFrame = items.reduce((res: any, item: any) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = {};
                }
                res[idx]["0"] =
                    this.keyframeFactories.waterDrainageLinkMetric.newCustomDataKeyFrame(
                        item.step * this.frameLength,
                        this.frameLength,
                        item
                    );
                return res;
            }, []);

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`waterState_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

        const trafficState2frame = (items: any[], n: number) => {
            let itemsByFrame = items.reduce((res: any, item: any) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = {};
                }
                res[idx]["0"] =
                    this.keyframeFactories.waterDrainageLinkMetric.newCustomDataKeyFrame(
                        item.step * this.frameLength,
                        this.frameLength,
                        item
                    );
                return res;
            }, []);

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`trafficState_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

        const commState2frame = (items: any[], n: number) => {
            let itemsByFrame = items.reduce((res: any, item: any) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = {};
                }
                res[idx]["0"] =
                    this.keyframeFactories.waterDrainageLinkMetric.newCustomDataKeyFrame(
                        item.step * this.frameLength,
                        this.frameLength,
                        item
                    );
                return res;
            }, []);

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`commState_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

        const elecState2frame = (items: any[], n: number) => {
            let itemsByFrame = items.reduce((res: any, item: any) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = {};
                }
                res[idx]["0"] =
                    this.keyframeFactories.waterDrainageLinkMetric.newCustomDataKeyFrame(
                        item.step * this.frameLength,
                        this.frameLength,
                        item
                    );
                return res;
            }, []);

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`elecState_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

        const cityOverview2frame = (items: any[], n: number) => {
            let itemsByFrame = items.reduce((res: any, item: any) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = {};
                }
                res[idx]["0"] =
                    this.keyframeFactories.waterDrainageLinkMetric.newCustomDataKeyFrame(
                        item.step * this.frameLength,
                        this.frameLength,
                        item
                    );
                return res;
            }, []);

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`cityOverview_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

        const cityRuntime2frame = (items: any[], n: number) => {
            let itemsByFrame = items.reduce((res: any, item: any) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = {};
                }
                res[idx]["0"] =
                    this.keyframeFactories.waterDrainageLinkMetric.newCustomDataKeyFrame(
                        item.step * this.frameLength,
                        this.frameLength,
                        item
                    );
                return res;
            }, []);

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`cityRuntime_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

        const aoi2frame = (items: any[], n: number) => {
            let itemsByFrame = items.reduce((res: any, item: any) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = {};
                }
                res[idx][item.id.toString()] = this.keyframeFactories.water.newKeyFrame(
                    item.step * this.frameLength,
                    this.frameLength,
                    item.density
                );
                return res;
            }, []);

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`aoi_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

        const heatmap2frame = (items: any[], n: number) => {
            items.forEach(item => {
                const index = item.step - offset;
                frameByTypeArray[index].frame[`heatmap_${n}`] = {
                    macro: this.keyframeFactories.copyDataFactory.newCustomDataKeyFrame(
                        (index + offset) * this.frameLength,
                        this.frameLength,
                        item.positions ?? []
                    )
                };
            });
        }

        const commSignal2frame = (items: any[], n: number, micro = false) => {
            let itemsByFrame = items.reduce((res: any, item: any) => {
                let idx = item.step - offset;
                if (!res[idx]) {
                    res[idx] = {};
                }
                (item.strength as any[]).forEach((d: any, i) => {
                    res[idx][`${i}`] = this.keyframeFactories.water.newKeyFrame(
                        item.step * this.frameLength,
                        this.frameLength,
                        d
                    );
                });

                return res;
            }, []);

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[micro ? `commDemandMicro_${n}` : `commDemand_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

    const elecDemand2frame = (items: any[], offset: number, n: number) => {
      let record: any = [];
      let itemsByFrame = items.reduce((res: any, item: any) => {
        let idx = item.step - offset;
        if (!res[idx]) {
          res[idx] = {};
        }
        if (!record[idx]) {
          record[idx] = [];
        }
        res[idx][item.id.toString()] =
          this.keyframeFactories.demand.newKeyFrame(
            item.step * this.frameLength,
            this.frameLength,
            item.unsatisfiedLevel
          );
        record[idx].push(item.id.toString());
        return res;
      }, []);
      if (itemsByFrame.length === 0) {
        itemsByFrame[0] = {};
        if (this.elecDemandData[n]) {
          for (let id of this.elecDemandData[n]) {
            itemsByFrame[0][id] = this.keyframeFactories.demand.newKeyFrame(
              offset * this.frameLength,
              this.frameLength,
              0
            );
          }
        }
        this.elecDemandData[n] = [];
      } else {
        for (let i in itemsByFrame) {
          if (this.elecDemandData[n]) {
            for (let id of this.elecDemandData[n]) {
              if (!record[parseInt(i)].includes(id)) {
                const timing =
                  itemsByFrame[parseInt(i)][record[parseInt(i)][0]].timing;
                itemsByFrame[parseInt(i)][id] =
                  this.keyframeFactories.demand.newKeyFrame(
                    timing,
                    this.frameLength,
                    0
                  );
              }
            }
          }
          this.elecDemandData[n] = record[parseInt(i)];
        }
      }

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`elec_demand_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

    const commDemand2frame = (items: any[], offset: number, n: number) => {
      let record: any = [];
      let itemsByFrame = items.reduce((res: any, item: any) => {
        let idx = item.step - offset;
        if (!res[idx]) {
          res[idx] = {};
        }
        if (!record[idx]) {
          record[idx] = [];
        }
        res[idx][item.id.toString()] =
          this.keyframeFactories.demand.newKeyFrame(
            item.step * this.frameLength,
            this.frameLength,
            item.unsatisfiedLevel
          );
        record[idx].push(item.id.toString());
        return res;
      }, []);
      if (itemsByFrame.length === 0) {
        itemsByFrame[0] = {};
        if (this.commDemandData[n]) {
          for (let id of this.commDemandData[n]) {
            itemsByFrame[0][id] = this.keyframeFactories.demand.newKeyFrame(
              offset * this.frameLength,
              this.frameLength,
              0
            );
          }
        }
        this.commDemandData[n] = [];
      } else {
        for (let i in itemsByFrame) {
          if (this.commDemandData[n]) {
            for (let id of this.commDemandData[n]) {
              if (!record[parseInt(i)].includes(id)) {
                const timing =
                  itemsByFrame[parseInt(i)][record[parseInt(i)][0]].timing;
                itemsByFrame[parseInt(i)][id] =
                  this.keyframeFactories.demand.newKeyFrame(
                    timing,
                    this.frameLength,
                    0
                  );
              }
            }
          }
          this.commDemandData[n] = record[parseInt(i)];
        }
      }

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`comm_demand_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

    const waterDemand2frame = (items: any[], offset: number, n: number) => {
      let record: any = [];
      let itemsByFrame = items.reduce((res: any, item: any) => {
        let idx = item.step - offset;
        if (!res[idx]) {
          res[idx] = {};
        }
        if (!record[idx]) {
          record[idx] = [];
        }
        res[idx][item.id.toString()] =
          this.keyframeFactories.demand.newKeyFrame(
            item.step * this.frameLength,
            this.frameLength,
            item.unsatisfiedLevel
          );
        record[idx].push(item.id.toString());
        return res;
      }, []);
      if (itemsByFrame.length === 0) {
        itemsByFrame[0] = {};
        if (this.waterDemandData[n]) {
          for (let id of this.waterDemandData[n]) {
            itemsByFrame[0][id] = this.keyframeFactories.demand.newKeyFrame(
              offset * this.frameLength,
              this.frameLength,
              0
            );
          }
        }
        this.waterDemandData[n] = [];
      } else {
        for (let i in itemsByFrame) {
          if (this.waterDemandData[n]) {
            for (let id of this.waterDemandData[n]) {
              if (!record[parseInt(i)].includes(id)) {
                const timing =
                  itemsByFrame[parseInt(i)][record[parseInt(i)][0]].timing;
                itemsByFrame[parseInt(i)][id] =
                  this.keyframeFactories.demand.newKeyFrame(
                    timing,
                    this.frameLength,
                    0
                  );
              }
            }
          }
          this.waterDemandData[n] = record[parseInt(i)];
        }
      }

            // write data to fetcher
            for (let i in itemsByFrame) {
                frameByTypeArray[parseInt(i)].frame[`water_demand_${n}`] =
                    itemsByFrame[parseInt(i)];
            }
        };

        responses.forEach(response => {
            switch (response.requestTarget.type) {
                case "cars":
                    car2frame(response.data, response.requestTarget.index);
                    break;
                case "people":
                    people2frame(response.data, response.requestTarget.index);
                    break;
                case "traffic-lights":
                    tl2frame(response.data, response.requestTarget.index);
                    break;
                case "road-state":
                    road2frame(response.data, response.requestTarget.index);
                    break;
                case "elec-nodes":
                    elec2frame(response.data, response.requestTarget.index);
                    elecMertic2Frame(response.data, response.requestTarget.index);
                    break;
                case "water-drainage-link":
                    water2frame(response.data, response.requestTarget.index);
                    break;
                case "water-supply-link":
                    supply2frame(response.data, response.requestTarget.index);
                    break;
                case "water-road":
                    flooded2frame(response.data, response.requestTarget.index);
                    break;
                case "comm-node":
                    comm2frame(response.data, response.requestTarget.index);
                    commMertic2Frame(response.data, response.requestTarget.index);
                    break;
                case "road-level":
                    roadMetric2frame(response.data, response.requestTarget.index);
                    break;
                case "water-road-metric":
                    waterRoadMetric2frame(response.data, response.requestTarget.index);
                    break;
                case "comm-demand":
                    commDemandMetric2frame(response.data, response.requestTarget.index);
                    break;
                case "water-drainage-link-metric":
                    waterDrainageLinkMetric2frame(response.data, response.requestTarget.index);
                    break;
                case "water-supply-link-metric":
                    waterSupplyLinkMetric2frame(response.data, response.requestTarget.index);
                    break;
                case "comm-metric":
                    commMetric2frame(response.data, response.requestTarget.index);
                    break;
                case "traffic-metric":
                    trafficMetric2frame(response.data, response.requestTarget.index);
                    break;
                case "city-metric":
                    cityMetric2frame(response.data, response.requestTarget.index);
                    break;
                case "demand-metric":
                    demandMetric2frame(response.data, response.requestTarget.index);
                    break;
                case "aoi":
                    aoi2frame(response.data, response.requestTarget.index);
                    break;
                case "heatmap":
                    heatmap2frame(response.data, response.requestTarget.index);
                    break;
                case "comm-signal":
                    commSignal2frame(response.data, response.requestTarget.index);
                    break;
                case "comm-signal-micro":
                    commSignal2frame(response.data, response.requestTarget.index, true);
                    break;
                case "elec-aois":
                    elecDemand2frame(response.data, offset, response.requestTarget.index);
                    break;
                case "comm-aoi":
                    commDemand2frame(response.data, offset, response.requestTarget.index);
                    break;
                case "water-aoi":
                    waterDemand2frame(response.data, offset, response.requestTarget.index);
                    break;
                case "event":
                    event2frame(response.data, response.requestTarget.index);
                    break;
                case "elec-metric":
                    elecDemandMetric2frame(response.data, response.requestTarget.index);
                    break;
                case "water-stat":
                    waterState2frame(response.data, response.requestTarget.index);
                    break;
                case "traffic-stat":
                    trafficState2frame(response.data, response.requestTarget.index);
                    break;
                case "comm-stat":
                    commState2frame(response.data, response.requestTarget.index);
                    break;
                case "elec-stat":
                    elecState2frame(response.data, response.requestTarget.index);
                    break;
                case "city-overview":
                    cityOverview2frame(response.data, response.requestTarget.index);
                    break;
                case "city-runtime":
                    cityRuntime2frame(response.data, response.requestTarget.index);
                    break;
                case "special-car-trajectory":
                    sct2frame(response.data, response.requestTarget.index);
                    break;
                case "road-detail":
                    roadDetail2frame(response.data, response.requestTarget.index);
                    break;
                case "aoi-detail":
                    aoiDetail2frame(response.data, response.requestTarget.index);
                    break
                default:
                    break;
            }
        });

        return frameByTypeArray;
    }
}
