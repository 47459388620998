import * as THREE from "three";

export enum KeyFrameType {
    movement,
    functional,
}

export interface IKeyFrame {
    frameType: KeyFrameType;
    timing: number;
    length: number;
    userData?: any;
}

export interface IMovementKeyFrame extends IKeyFrame {
    frameType: KeyFrameType.movement;
    position: THREE.Vector3;
    direction?: THREE.Euler;
}

export interface IStateChangeKeyFrame extends IKeyFrame {
    frameType: KeyFrameType.movement;
    state: number;
}

export interface ICustomDataKeyFrame extends IKeyFrame {
    frameType: KeyFrameType.movement;
    data: any;
}

export interface ITypedDataKeyFrame<T> extends IKeyFrame {
    frameType: KeyFrameType.movement;
    data: T;
    typeDef: string,
}

export interface IFunctionalKeyFrame extends IKeyFrame {
    frameType: KeyFrameType.functional;
    hide?: boolean;
    remove?: boolean;
}

export class KeyFrameTool {
    static isTimingBeforeKeyFrame(timing: number, kf: IKeyFrame, strict: boolean = true): boolean {
        return strict ? timing < kf.timing : timing <= kf.timing;
    }

    static isTimingAfterKeyFrame(timing: number, kf: IKeyFrame, strict: boolean = false): boolean {
        const endTiming = kf.timing + kf.length;
        return strict ? timing > endTiming : timing >= endTiming;
    }

    static isTimingInKeyFrame(timing: number, kf: IKeyFrame, strict: [boolean, boolean] = [false, true]): boolean {
        const notBefore = !this.isTimingBeforeKeyFrame(timing, kf, !strict[0]);
        const notAfter = !this.isTimingAfterKeyFrame(timing, kf, !strict[1]);

        return notBefore && notAfter;
    }

    static isKeyFrameBeforeKeyFrame(kf1: IKeyFrame, kf2: IKeyFrame, allowOverlap: boolean = true, strict: boolean = false): boolean {
        const timing = allowOverlap ? kf1.timing : kf1.timing + kf1.length;
        return this.isTimingBeforeKeyFrame(timing, kf2, strict);
    }

    static isKeyFrameAfterKeyFrame(kf1: IKeyFrame, kf2: IKeyFrame, allowOverlap: boolean = true, strict: boolean = false): boolean {
        return this.isKeyFrameBeforeKeyFrame(kf2, kf1, allowOverlap, strict);
    }

    static isKeyFrameOverlapped(kf1: IKeyFrame, kf2: IKeyFrame): boolean {
        return (
            this.isKeyFrameBeforeKeyFrame(kf1, kf2, true) &&
            !this.isKeyFrameBeforeKeyFrame(kf1, kf2, false, false)
        ) || (
            this.isKeyFrameAfterKeyFrame(kf1, kf2, true) &&
            !this.isKeyFrameAfterKeyFrame(kf1, kf2, false, false)
        )
    }

    static endTiming(kf: IKeyFrame): number {
        return kf.timing + kf.length;
    }

    static setLengthByEndTiming(kf: IKeyFrame, endTiming: number): void {
        if (endTiming <= kf.timing)
            throw Error("endTiming must be greater than start timing of key frame.");
        kf.length = endTiming - kf.timing;
    }

    static isFunctionalFrame(kf: IKeyFrame): boolean {
        return kf.frameType === KeyFrameType.functional;
    }

    static isMovementFrame(kf: IKeyFrame): boolean {
        return kf.frameType === KeyFrameType.movement;
    }
}