import { Map } from "mapbox-gl";
import { PlayerManager } from "../../core/player/PlayerManager";
import { AllDemandPlayer } from "../../core/player/Demand";

import { BaseCustomLayer } from "./BaseCustomLayer";
import { RoadKeyFrameFactory } from "./FrameFactories";
import { AoiReader } from "./GeoJsonReader";

export interface IAoiReader {
  get(): Promise<any>;
}

const demandCircleLayer = (type: string) => ({
  id: `${type}_demand`,
  type: "circle",
  source: `${type}_demand_point`,
  filter: [">", "unsatisfiedLevel", 0],
  paint: {
    // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
    // with three steps to implement three types of circles:
    //   * Blue, 20px circles when point count is less than 100
    //   * Yellow, 30px circles when point count is between 100 and 750
    //   * Pink, 40px circles when point count is greater than or equal to 750
    "circle-color": [
      "match",
      ["get", "unsatisfiedLevel"],
      1,
      "#FFE901",
      2,
      "#FFAB01",
      3,
      "#FF7801",
      4,
      "#FF3601",
      5,
      "#FF0000",
      /* other */ "#ccc",
    ],
    "circle-stroke-color": [
      "match",
      ["get", "unsatisfiedLevel"],
      1,
      "#FFE901",
      2,
      "#FFAB01",
      3,
      "#FF7801",
      4,
      "#FF3601",
      5,
      "#FF0000",
      /* other */ "#ccc",
    ],
    "circle-stroke-width": 1,
    "circle-opacity": 0.6,
    "circle-radius": [
      "match",
      ["get", "unsatisfiedLevel"],
      1,
      5,
      2,
      10,
      3,
      15,
      4,
      20,
      5,
      25,
      5,
    ],
  },
})

export class DemandLayer extends BaseCustomLayer {
  map: any;
  index: number;
  type: string;

  aoiReader: IAoiReader;
  demandPlayer: AllDemandPlayer | undefined;

  data: { lineData: any; pointData: any } = { lineData: [], pointData: [] };

  widthScale: number = 1.0;

  initialized: boolean = false;

  player: PlayerManager;
  keyFrameFactory = new RoadKeyFrameFactory();

  constructor(
    id: string,
    index: number,
    type: string,
    props: {
      playerManager: PlayerManager;
      aoiReader: AoiReader;
    }
  ) {
    super(id, "3d", props);
    this.index = index;
    this.player = props.playerManager;
    this.type = type;
    this.subLayerIdToLocalVisibility[`${this.type}_demand`] = true;

    this.aoiReader = props.aoiReader;
  }

  async init(map: Map) {
    this.map = map;

    this.data = await this.aoiReader.get();

    this.map.addSource(`${this.type}_demand_point`, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: this.data.pointData,
      },
    });

    this.map.addLayer(demandCircleLayer(this.type));

    const allDemandPlayer = new AllDemandPlayer(this.map, this.data, this.type);
    allDemandPlayer.init();
    this.demandPlayer = allDemandPlayer;

    this.player.attachSubCrowdPlayer(
      `${this.type}_demand_${this.index}`,
      allDemandPlayer
    );

    console.log("DemandPlayer initialized.");
    this.initialized = true;
    this.setLayerVisibility(false);
  }
}
