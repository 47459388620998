import { Map } from "mapbox-gl";
import { PlayerManager } from "../../core/player/PlayerManager";
import { AllCommDemandPlayer } from "../../core/player/CommDemand";

import { BaseCustomLayer } from "./BaseCustomLayer";
import { JsonReader } from "./GeoJsonReader";
import { RoadKeyFrameFactory } from "./FrameFactories";


export interface IAoiReader {
  get(): Promise<any>;
}

export class CommMicroHeatmapLayer extends BaseCustomLayer {
  map: any;
  source = 'comm_station_point_micro';
  index: number;

  jsonReader: IAoiReader;
  aoiPlayer: AllCommDemandPlayer | undefined;

  data: any;

  widthScale: number = 1.0;

  initialized: boolean = false;

  player: PlayerManager;
  keyFrameFactory = new RoadKeyFrameFactory();
  subLayerIdToLocalVisibility = {
    comm_heatmap_micro: true,
  }

  constructor(
    id: string,
    index: number,
    props: {
      playerManager: PlayerManager;
      jsonReader: JsonReader;
    }
  ) {
    super(id, "3d", props);
    this.index = index;
    this.player = props.playerManager;

    this.jsonReader = props.jsonReader;
  }

  async init(map: Map) {
    this.map = map;

    this.data = await this.jsonReader.get();
    let geoJsonPoints = [];
    for (let p of this.data) {
      geoJsonPoints.push({
        type: "Feature",
        properties: { intensity: Math.floor(Math.random() * 50 - 130) },
        geometry: { type: "Point", coordinates: p },
      });
    }

    this.map.addSource(this.source, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: geoJsonPoints,
      },
    });

    this.map.addLayer(
      {
        id: "comm_heatmap_micro",
        type: "heatmap",
        maxzoom: 19,
        source: this.source,
        paint: {
          // Increase the heatmap weight based on frequency and property magnitude
          "heatmap-weight": [
            "interpolate",
            ["linear"],
            ["get", "intensity"],
            -130,
            0,
            -40,
            1,
          ],
          // Increase the heatmap color weight weight by zoom level
          // heatmap-intensity is a multiplier on top of heatmap-weight
          "heatmap-intensity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            13,
            1,
            17,
            2,
          ],
          // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
          // Begin color ramp at 0-stop with a 0-transparancy color
          // to create a blur-like effect.
          "heatmap-color": [
            "interpolate",
            ["linear"],
            ["heatmap-density"],
            0,
            "rgba(33,102,172,0)",
            0.2,
            "rgb(103,169,207)",
            0.4,
            "rgb(209,229,240)",
            0.6,
            "rgb(253,219,199)",
            0.8,
            "rgb(239,138,98)",
            1,
            "rgb(178,24,43)",
          ],
          // Adjust the heatmap radius by zoom level
          "heatmap-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],
            11,
            1,
            12,
            7,
            13,
            10,
            14,
            25,
            15,
            40,
            18,
            200
          ],
          // Transition from heatmap to circle layer by zoom level
          "heatmap-opacity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            12,
            0.7,
            19,
            0,
          ],
        },
      },
      "comm"
    );

    const allAoiPlayer = new AllCommDemandPlayer(this.map, this.source, geoJsonPoints);
    allAoiPlayer.init();
    this.aoiPlayer = allAoiPlayer;

    this.player.attachSubCrowdPlayer(`commDemandMicro_${this.index}`, allAoiPlayer);

    console.log("CommDemandMicroPlayer initialized.");
    this.initialized = true;
    this.setLayerVisibility(false);
  }
}
