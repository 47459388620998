import * as THREE from 'three';
import mapboxgl, {LngLatLike} from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

interface ICoordinateTransformer {
    readonly translationMatrix: THREE.Matrix4;
    readonly scale: number;

    posToLngLat(position: THREE.Vector3) : mapboxgl.LngLatLike;
    lngLatToPos(coordinate: mapboxgl.LngLatLike, altitude: number) : THREE.Vector3;
}


export class CoordinateTransformer implements ICoordinateTransformer {

    referencePoint: THREE.Vector3 = new THREE.Vector3(0, 0, 0);
    scale: number = 1;
    readonly translationMatrix: THREE.Matrix4;

    static fromLngLat(coordinate: LngLatLike): CoordinateTransformer {
        const mercator = mapboxgl.MercatorCoordinate.fromLngLat(coordinate, 0);
        return new this(
            new THREE.Vector3(mercator.x, mercator.y, mercator.z),
            mercator.meterInMercatorCoordinateUnits()
        );
    }

    constructor(referencePoint?: THREE.Vector3, scale?: number) {
        if (referencePoint)
            this.referencePoint = referencePoint;

        this.translationMatrix = new THREE.Matrix4().makeTranslation(
            this.referencePoint.x,
            this.referencePoint.y,
            this.referencePoint.z
        );

        if (scale)
            this.scale = scale;
    }

    lngLatToPos(coordinate: mapboxgl.LngLatLike, altitude: number = 0): THREE.Vector3 {
        const posMc = mapboxgl.MercatorCoordinate.fromLngLat(coordinate, altitude);
        return new THREE.Vector3(posMc.x, posMc.y, posMc.z).sub(this.referencePoint);
    }

    posToLngLat(position: THREE.Vector3): mapboxgl.LngLat {
        const posMc = position.clone().add(this.referencePoint);
        return new mapboxgl.MercatorCoordinate(posMc.x, posMc.y, posMc.z).toLngLat();
    }
}

export function normAngle(angle: number): number {
    const t = 2 * Math.PI;
    return ((angle % t) + t) % t
}