import mapboxgl from "mapbox-gl";

import {BaseCustomLayer} from "../BaseCustomLayer";
import {PlayerManager} from "../../../core/player/PlayerManager";
import {IStatefulGeoSource, StatefulGeoSource} from "../../../core/utils/StatefulGeoSource";
import {GeoSourceStateChangePlayer} from "../../../core/player/GeoSourceStateChangePlayer";


export abstract class StatefulGeoLayer<StateType> extends BaseCustomLayer {
    map?: mapboxgl.Map;
    index: number;

    initialized: boolean = false;

    player?: GeoSourceStateChangePlayer<StateType>;
    playerManager: PlayerManager;

    statefulSource?: IStatefulGeoSource<StateType>;

    constructor(
        id: string,
        index: number,
        props: {
            playerManager: PlayerManager;
        }
    ) {
        super(id, "2d", props);
        this.index = index;
        this.playerManager = props.playerManager;
        this.subLayerIdToLocalVisibility[id] = true;
        this.clickableLayers = [this.id];
    }

    abstract getLayerDef(): mapboxgl.AnyLayer;

    abstract getStatefulSource(): Promise<IStatefulGeoSource<StateType>>;

    async init(map: mapboxgl.Map) {
        this.map = map;

        this.statefulSource = await this.getStatefulSource();
        this.statefulSource.setFeaturesSync([]);

        this.map.addLayer({...this.getLayerDef(), id: this.id, source: this.statefulSource.sourceName} as mapboxgl.AnyLayer);

        this.player = new GeoSourceStateChangePlayer(this.statefulSource);
        this.playerManager.attachSubCrowdPlayer(`${this.id}_${this.index}`, this.player);

        this.initialized = true;
        this.setLayerVisibility(false);
    }

}


export abstract class SourceChangingGeoLayer extends StatefulGeoLayer<{}> {

    async getStatefulSource(): Promise<IStatefulGeoSource<{}>> {
        return new StatefulGeoSource<{}>(this.map!, this.id, 'id', {});
    }
}