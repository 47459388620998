import { Map } from "mapbox-gl";
import { PlayerManager } from "../../core/player/PlayerManager";
import { AllCommDemandPlayer } from "../../core/player/CommDemand";
import { PopulationHeatmap } from "../../core/player/PopulationHeatmap";
import { randFromTo } from "../../core/utils/math";
import { BaseCustomLayer } from "./BaseCustomLayer";

export interface IAoiReader {
  get(): Promise<any>;
}


export class MicroPopulationHeatmapLayer extends BaseCustomLayer {
  map: any;
  source = 'micro_population';
  index: number;

  sourcePlayer: AllCommDemandPlayer | undefined;

  initialized: boolean = false;

  player: PlayerManager;

  constructor(
    id: string,
    index: number,
    props: {
      playerManager: PlayerManager;
    }
  ) {
    super(id, "2d", props);
    this.index = index;
    this.player = props.playerManager;
    this.subLayerIdToLocalVisibility[id] = true;
  }

  randomGeoPoints(count: number) {
    const min_longitude = 116.45466845743775;
    const min_latitude = 39.90635904559525;
    const max_longitude = 116.4727503404667;
    const max_latitude = 39.92235997926467;
    const points: {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [number, number],
      },
    }[] = [];
    for (let i = 0; i < count; i++) {
      points.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
              randFromTo(min_longitude, max_longitude),
              randFromTo(min_latitude, max_latitude),
          ]
        }
      });
    }
    return points;
  }

  async init(map: Map) {
    this.map = map;

    this.map.addSource(this.source, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: this.randomGeoPoints(5000),
      },
    });

    this.map.addLayer(
      {
        id: this.id,
        type: "heatmap",
        maxzoom: 19,
        source: this.source,
        paint: {
          // Increase the heatmap weight based on frequency and property magnitude
          "heatmap-weight": 0.1,
          // Increase the heatmap color weight weight by zoom level
          // heatmap-intensity is a multiplier on top of heatmap-weight
          "heatmap-intensity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            13,
            1,
            17,
            2,
          ],
          // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
          // Begin color ramp at 0-stop with a 0-transparancy color
          // to create a blur-like effect.
          "heatmap-color": [
            "interpolate",
            ["linear"],
            ["heatmap-density"],
            0,
            "rgba(33,102,172,0)",
            0.2,
            "rgb(103,169,207)",
            0.4,
            "rgb(209,229,240)",
            0.6,
            "rgb(253,219,199)",
            0.8,
            "rgb(239,138,98)",
            1,
            "rgb(178,24,43)",
          ],
          // Adjust the heatmap radius by zoom level
          "heatmap-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],
            12,
            7,
            13,
            10,
            14,
            20,
            15,
            50,
            18,
            200,
          ],
          // Transition from heatmap to circle layer by zoom level
          "heatmap-opacity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            12,
            0.7,
            19,
            0,
          ],
        },
      },
    );

    const heatmapPlayer = new PopulationHeatmap(this.map, this.source);
    heatmapPlayer.init();

    this.player.attachSubCrowdPlayer(`heatmap_${this.index}`, heatmapPlayer);

    console.log("PopulationHeatmapPlayer initialized.");
    this.initialized = true;
    this.setLayerVisibility(false);
  }
}
