import { BaseCustomLayer, FilterComponent } from "./BaseCustomLayer";
import { PlayerManager } from "../../core/player/PlayerManager";
import { Map } from "mapbox-gl";
import { SpecialCarCrowdPlayer } from "../../core/player/SpecialCar";
import { WaterState } from "../../core/GeoPropDefs";

export interface IReader {
  get(): Promise<any>;
}

export class SpecialCarLayer extends BaseCustomLayer {
  map: any;
  index: number;

  specialCarPlayer?: SpecialCarCrowdPlayer;
  data: any;
  initialized: boolean = false;

  player: PlayerManager;

  constructor(
    id: string,
    index: number,
    props: {
      playerManager: PlayerManager;
    }
  ) {
    super(id, "3d", props);
    this.index = index;
    this.player = props.playerManager;
    this.subLayerIdToLocalVisibility[id] = true;
  }

  async init(map: Map, filter: number[] = []) {
    this.map = map;

    this.map.addSource("special_car", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    this.map.addLayer({
      id: this.id,
      type: "line",
      source: "special_car",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": [
          "match",
          ["get", "type"],
          "comm",
          "#ba9dc9",
          "traffic",
          "#3b6daa",
          "water",
          "#6aa5c7",
          "elec",
          "#d76da4",
          "#ccc",
        ],
        "line-width": 4,
        "line-opacity": 0.9,
      },
    });

    const player = new SpecialCarCrowdPlayer(this.map, this.id);
    player.init();
    this.specialCarPlayer = player;

    this.player.attachSubCrowdPlayer(`special_car_${this.index}`, player);

    this.initialized = true;
    this.setLayerVisibility(false);
  }
}
