import { AbstractPlayer, ICrowdPlayer } from "./AbstractPlayer";
import { IKeyFrame, IStateChangeKeyFrame, KeyFrameTool } from "./KeyFrame";
import { Map, GeoJSONSource } from "mapbox-gl";

interface ISuperPlayerMessage {
  shouldUpdate?: boolean;
}

class DemandPlayer extends AbstractPlayer {
  id: string;
  lastState: number | undefined = 0;
  geojson: any;
  type: string;

  constructor(id: string, geojson: any, type: string) {
    super();
    this.id = id;
    this.geojson = geojson;
    this.type = type;
  }

  play(timing: number): ISuperPlayerMessage {
    // this.maybeRollback(timing);

    const currentFrame = this.getCurrentKeyFrame(timing);
    if (currentFrame && currentFrame.timing > timing - 1) {
      if (KeyFrameTool.isMovementFrame(currentFrame)) {
        const frame = currentFrame as IStateChangeKeyFrame;
        if (frame.state !== this.lastState) {
          this.lastState = frame.state;
          return { shouldUpdate: true };
        } else {
          // 状态相同，无需更新
          return {};
        }
      } else {
        console.error("未能识别帧类型", currentFrame);
        throw Error("未能识别帧类型");
      }
    } else {
      return {};
    }
  }

  get_geojson() {
    this.geojson.properties.unsatisfiedLevel = this.lastState;
    return this.geojson;
  }

  clear() {
    super.clear();
    this.lastState = undefined;
  }
}

export class AllDemandPlayer implements ICrowdPlayer {
  isInitialized: boolean = false;
  minTimingInterval: number;
  private lastTiming = -Infinity;
  data: { lineData: any; pointData: any };
  map: Map;
  aoiPoints: { [id: string]: DemandPlayer } = {};
  filter: string[] = [];
  type: string;

  constructor(map: Map, data: any, type: string, minTimingInterval = 1) {
    this.data = data;
    this.map = map;
    this.type = type;
    this.minTimingInterval = minTimingInterval;
  }
  public appendKeyFrames(keyFrames: { [id: string]: IKeyFrame }) {
    if (!this.isInitialized) {
      throw Error("Call init first before append key frames.");
    }
    Object.entries(keyFrames).forEach(([id, keyFrame]) => {
      if (this.aoiPoints.hasOwnProperty(id)) {
        this.aoiPoints[id].enqueueKeyFrame(keyFrame);
      }
    });
  }

  play(timing: number) {
    if (!this.isInitialized) {
      throw Error(
        "Make sure init is called and finished before calling play()"
      );
    }
    if (timing - this.lastTiming >= this.minTimingInterval) {
      this.lastTiming = timing;
      let shouldUpdate = false;
      Object.values(this.aoiPoints).forEach((player) => {
        const message = player.play(timing);
        if (message.shouldUpdate) {
          shouldUpdate = true;
        }
      });
      if (shouldUpdate) {
        this.update();
      }
    }
  }

  init() {
    if (this.isInitialized) {
      console.warn("Duplicate call of init takes no effect.");
      return;
    }
    this.data.pointData.forEach((point: any) => {
      this.aoiPoints[`${point.properties.id}`] = new DemandPlayer(
        `${point.properties.id}`,
        point,
        this.type
      );
    });
    this.isInitialized = true;
  }

  update() {
    let new_data: any[] = [];
    for (let p of Object.values(this.aoiPoints)) {
      new_data.push(p.get_geojson());
    }
    (this.map.getSource(`${this.type}_demand_point`) as GeoJSONSource).setData({
      type: "FeatureCollection",
      features: new_data,
    });
  }

  clear() {
    this.lastTiming = -Infinity;
    if (!this.isInitialized) {
      throw Error(
        "Make sure init is called and finished before calling clear()"
      );
    }
  }
}
