export interface IDeque<T> {
    enqueueEnd(item: T): void;

    dequeueEnd(): T | undefined;

    enqueueFront(item: T): void;

    dequeueFront(): T | undefined;

    peekFirst(): T | undefined;

    peekLast(): T | undefined;

    size(): number;

    clear(): void;
}

export class Deque<T> implements IDeque<T> {
    private storage: T[] = [];

    constructor(private capacity: number = Infinity, public autoClearWhenOverflow: boolean = false) {
    }

    enqueueEnd(item: T): void {
        if (this.size() === this.capacity) {
            if (this.autoClearWhenOverflow)
                this.dequeueFront();
            else
                throw Error("Queue has reached max capacity, you cannot add more items");
        }
        this.storage.push(item);
    }

    dequeueFront(): T | undefined {
        return this.storage.shift();
    }

    enqueueFront(item: T) {
        if (this.size() === this.capacity) {
            if (this.autoClearWhenOverflow)
                this.dequeueEnd();
            else
                throw Error("Queue has reached max capacity, you cannot add more items");
        }
        this.storage.unshift(item);
    }

    dequeueEnd(): T | undefined {
        return this.storage.pop();
    }

    size(): number {
        return this.storage.length;
    }

    peekFirst(): T | undefined {
        return this.storage[0];
    }

    peekLast(): T | undefined {
        return this.storage[this.storage.length - 1];
    }

    clear() {
        this.storage = [];
    }
}